<template>
  <div class="mail-reset">
    <LoginHeader />

    <!-- Actual component to handle the email reset logic -->
    <div class="mail-reset-section">
      <MailResetCard />
    </div>
  </div>
</template>

<script>
  import LoginHeader from '@/components/view-members/auth/LoginHeader.vue';
  import MailResetCard from '@/components/view-members/auth/MailResetCard.vue';

  /**
   * Wrapper compontent to request an email for password reset.
   * The actual backend logic is done in the MailResetCard component.
   */
  export default {
    name: 'MailReset',
    components: {
      LoginHeader,
      MailResetCard,
    },
  };
</script>

<style scoped>
  .mail-reset {
    background-image: url('~@/assets/bg-inp-building.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
  }

  .mail-reset-section {
    margin: auto;
    height: 80vh;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
