<template>
  <div class="password-reset-card">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />

    <h2 class="password-reset-heading">Passwort zurücksetzen</h2>
    <div class="group" v-if="!is_password_reset">
      <p class="requirements">
        Das Passwort muss mindestens 8 Zeichen lang sein und mindestens 1 Zahl, 1 Kleinbuchstaben und einen
        Großbuchstaben entalten.
      </p>

      <!-- New password inputs -->
      <IconInputBox name="Neues Passwort" icon_id="bx:bxs-lock-alt" type="password" v-model="new_password" />
      <IconInputBox name="Bestätigung" icon_id="bx:bxs-lock-alt" type="password" v-model="new_password_confirm" />

      <!-- Quality error display -->
      <div class="problems" v-if="password_problems.length > 0 && (new_password !== '' || new_password_confirm !== '')">
        <p class="problem" v-for="problem in password_problems" :key="problem">* {{ problem }}</p>
      </div>

      <div class="actions-group">
        <Button width="21em" @click="change_password">Bestätigen</Button>
      </div>
    </div>

    <div class="group" v-if="is_password_reset">
      <p class="confirm-prompt">Das Passwort wurde erfolgreich geändert</p>
      <router-link class="back-to-login" :to="{ name: 'Login' }">Zum Login</router-link>
    </div>
  </div>
</template>

<script>
  import IconInputBox from '@/components/general/IconInputBox.vue';
  import Button from '@/components/general/Button.vue';
  import { useRoute } from 'vue-router';
  import { use_toast } from '@/composables/composables';
  import Toast from '@/components/general/Toast.vue';
  import { computed, ref } from '@vue/reactivity';
  import axios from 'axios';
  import { api_routes } from '@/config';
  import { Logger } from '@/util';

  /**
   * After a password reset link was received via email, the user can change his password here.
   */
  export default {
    name: 'PasswordResetCard',
    components: {
      IconInputBox,
      Button,
      Toast,
    },
    setup() {
      const route = useRoute();
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      const is_password_reset = ref(false);

      const token = route.query?.token;

      const new_password = ref('');
      const new_password_confirm = ref('');

      if (!token) show_toast('error', 'Der Link ist ungültig.');

      /**
       * Quality fronend checks if the password is an acceptable input.
       * Checks:
       *   - length > 8
       *   - contains at least 1 upper case letter
       *   - contains at least 1 lower case letter
       *   - contains at least 1 number
       * @returns {string[]} Array of problems
       */
      const password_problems = computed(() => {
        const problem_list = [];

        // quality checks
        if (new_password.value !== new_password_confirm.value) {
          problem_list.push('Die Passwörter stimmen nicht überein!');
        }

        if (new_password.value.length < 8) {
          problem_list.push('Das neue Passwort muss mindestens 8 Zeichen lang sein!');
        }

        if (!new_password.value.match(/[A-Z]/)) {
          problem_list.push('Das Passwort muss mindestens 1 Großbuchstaben enthalten');
        }

        if (!new_password.value.match(/[a-z]/)) {
          problem_list.push('Das Passwort muss mindestens 1 Kleinbuchstaben enthalten');
        }

        if (!new_password.value.match(/[0-9]/)) {
          problem_list.push('Das Passwort muss mindestens 1 Zahl enthalten');
        }

        return problem_list;
      });

      /**
       * Sends password change request to the backend.
       * @param {Event} event - Default form event. Is just used to prevent default form behaviour.
       */
      const change_password = async (event) => {
        event.preventDefault();

        const problems = password_problems.value;

        if (problems.length > 0) {
          show_toast('error', problems.join('\n'));
          return;
        }

        // send change_password post request
        try {
          await axios.post(api_routes.password_reset_confirm, {
            password: new_password.value,
            token: token,
          });

          is_password_reset.value = true;
          show_toast('success', 'Das Passwort wurde erfolgreich geändert.');
        } catch (error) {
          Logger.log(error);
          show_toast('error', 'Fehler beim Ändern des Passworts');
        }
      };

      return {
        is_toast_shown,
        toast_type,
        toast_msg,
        new_password,
        new_password_confirm,
        change_password,
        password_problems,
        is_password_reset,
      };
    },
  };
</script>

<style scoped>
  .password-reset-card {
    font-size: 1.1rem;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.8rem;
    padding: 1rem;
    width: 95%;

    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 2rem;
    max-width: 600px;
  }

  .group {
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 2rem;
  }

  .password-reset-card .password-reset-heading {
    font-size: 1.4rem;
    font-weight: 400;
    color: #ea4c08;
  }

  .actions-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8em;
  }

  .back-to-login {
    text-decoration: underline;
    color: #ff783d;
    font-size: 0.7em;
  }

  .back-to-login:hover {
    color: #ff4d00;
  }

  .requirements {
    font-size: 0.7em;
    color: #9d9d9d;
    text-align: center;
  }

  .problems .problem {
    color: #ff0062;
    font-size: 0.7em;
  }

  .confirm-prompt {
    font-size: 0.7em;
    /* color: #0ab674; */
    color: #8f8f8f;
  }

  @media (min-width: 1400px) {
    .password-reset-card {
      font-size: 1.3rem;
      gap: 2.5rem;
    }
  }
</style>
