<template>
  <Dropdown class="dropdown-root" @show-content="on_show_content">
    <!-- Header text -->
    <template v-slot:header>
      <div class="header">
        <Icon icon="bx:bx-bell" class="icon" />
        <p>{{ num_unread }}</p>
      </div>
    </template>

    <!-- Content section -->
    <template v-slot:content>
      <div class="box-arrow" />
      <div class="card">
        <!-- Link to notifications page -->
        <header class="header">
          <div />
          <router-link :to="{ name: 'Notifications' }" class="notification-page-link">Mitteilungen</router-link>
          <p>{{ num_unread }}</p>
        </header>

        <!-- Notification cards -->
        <div class="notification-items">
          <NotificationCard
            v-for="(notification, index) in notifications"
            :key="index"
            :notification="notification"
            @remove-notification="on_remove_notification"
          />
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
  import { Icon } from '@iconify/vue';
  import Dropdown from '@/components/general/Dropdown.vue';
  import { use_notifications } from '@/composables/composables';

  import NotificationCard from '@/components/notifications/NotificationCard.vue';

  /**
   * Notification dropdown.
   * It contains the number of unread notifications and a list of notifications.
   */
  export default {
    name: 'NotificationDropdown',
    components: {
      Icon,
      Dropdown,
      NotificationCard,
    },
    setup() {
      const { notifications, num_unread, on_show_content, on_remove_notification } = use_notifications();

      return {
        notifications,
        num_unread,
        on_show_content,
        on_remove_notification,
      };
    },
  };
</script>

<style scoped>
  .dropdown-root {
    --icon-font-size: 1.3em;
    --card-width: 20em;
  }

  .dropdown-root .header {
    display: flex;
    align-items: center;
    gap: 0.05em;
    margin: 0;
    padding: 0;
  }

  .dropdown-root .header .icon {
    cursor: pointer;
    font-size: var(--icon-font-size);
    color: #555;
  }

  .dropdown-root .header .icon:hover {
    color: #858585;
  }

  .dropdown-root .header p {
    font-size: 0.7em;
    color: #858585;
    margin: 0;
    padding: 0;
  }

  .dropdown-root .card {
    width: var(--card-width);
    transform: translateX(calc(var(--icon-font-size) + calc(-1 * var(--card-width))));
    background-color: #f6f6f6;
    border-radius: 0.3em;
    padding: 0.5em 0.5em 1em 0.5em;
    max-height: 25rem;
    overflow-y: auto;
  }

  .dropdown-root .box-arrow {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: #f6f6f6;
    width: 2em;
    height: 1em;
    transform: translate(-0.8em, 0.2em);
  }

  .dropdown-root .card .header {
    width: 90%;
    margin: 1em auto;

    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    justify-items: center;
    align-items: center;
    gap: 1em;
    border-bottom: 1px solid #cecece;
  }

  .dropdown-root .card .header .notification-page-link {
    color: #ff8a58;
    font-weight: 400;
    font-size: 0.8em;
    cursor: pointer;
    text-decoration: none;
  }

  .dropdown-root .card .header .notification-page-link:hover {
    color: #ff6320;
  }

  .dropdown-root .card .header p {
    font-size: 0.75em;
    justify-self: end;
    padding-right: 1em;
    color: #939393;
  }

  .dropdown-root .card .notification-items {
    width: 95%;
    margin: 0 auto;
    font-size: 0.75em;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4em;
  }

  @media (min-width: 430px) {
    .dropdown-root {
      --card-width: 25em;
    }
  }

  @media (min-width: 650px) {
    .dropdown-root {
      --card-width: 36em;
    }

    .dropdown-root .card .header .notification-page-link {
      font-size: 1em;
    }

    .dropdown-root .card .header p {
      font-size: 0.9em;
    }

    .dropdown-root .card .notification-items {
      font-size: 1em;
    }
  }
</style>
