<template>
  <div class="seat-plan-container">
    <!-- Show the name of the currently selected seat -->
    <h3 class="current-seat" v-if="current_seat">
      Ausgewählt: <span>{{ current_seat.full_name }}</span>
    </h3>

    <!-- Seat plan image -->
    <svg class="svg-seat-plan" :width="data_.level.level_plan_width" :height="data_.level.level_plan_height">
      <g id="building-plan-image">
        <image
          :xlink:href="get_level_plan_url(data_.level.level_plan_url)"
          :width="data_.level.level_plan_width"
          :height="data_.level.level_plan_height"
          @load="on_image_load"
        />
      </g>

      <!-- Seat locations to be marked if the corresponding seat is free -->
      <g id="seat-location-frame" v-if="image_loaded">
        <rect
          :key="itm.seat_id"
          v-for="itm in data_.level.seats"
          class="seat-location"
          :x="itm.x_pos"
          :y="itm.y_pos"
          :width="itm.width"
          :height="itm.height"
          @click="current_seat = itm"
        />
      </g>
    </svg>
  </div>
</template>

<script>
  import axios from 'axios';
  import { ref } from '@vue/reactivity';

  import { DEBUG } from '@/config';
  import { useStore } from 'vuex';

  /**
   * Show the seat plan for a single level with free seats marked.
   * Also on seat selection on the level plan the name of the seat will be displayed.
   */
  export default {
    name: 'SeatPreview',
    props: {
      data_: {
        type: Object,
        required: true,
        /*
          { 
            date: Date,
            occupation_percentage: number,
            free_seats: number,
            occupied_seats: number,
            total_seats: number,
            level: {pk: number,
            full_name: string,
            level_plan_url: string,
            level_plan_width: number,
            level_plan_height: number,
            seats: {
              seat_id: number,
              full_name: string,
              description: string,
              x_pos: number,
              y_pos: number,
              width: number,
              height: number,
              owner: {
                pk: number,
                username: string,
                first_name: string,
                last_name: string
              }
            }[]
          }
         */
      },
    },
    emits: ['loaded'],
    setup(props, { emit }) {
      const image_loaded = ref(false); // flag if the image has been loaded
      const current_seat = ref(null); // currently selected seat

      const store = useStore();

      /**
       * @deprecated
       * Alter image url from the backend for dev functionality to work.
       * @param {string} url - url of the image supplied by the backend
       */
      const get_level_plan_url = (url) => {
        if (!url || url === '') {
          return '';
        }

        if (DEBUG) {
          const base_url =
            url[0] === '/'
              ? axios.defaults.baseURL.substring(0, axios.defaults.baseURL.length - 1)
              : axios.defaults.baseURL;
          url = `${base_url}${url}`;
        }

        return `${url}?token=${store.getters.auth_token}`;
      };

      /**
       * On image load event emitter to remove spinner in the parent component.
       * As well remove local spinner with the image_loaded flag.
       */
      const on_image_load = () => {
        image_loaded.value = true;
        emit('loaded');
      };

      return { on_image_load, image_loaded, get_level_plan_url, current_seat };
    },
  };
</script>

<style scoped>
  .seat-plan-container {
    padding: 0.5em;
    width: 100%;
    height: 100%;
  }

  .svg-seat-plan {
    display: block;
    margin: 0 auto;
  }

  .seat-location {
    fill: #7bd3ff;
    opacity: 0.4;
    cursor: pointer;
  }

  .seat-location:hover {
    fill: #1bb3ff;
  }

  .current-seat {
    text-align: center;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
    color: #555;
  }

  .current-seat span {
    margin-left: 0.2rem;
  }
</style>
