<template>
  <form class="login-card" method="POST">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />
    <h2 class="login-heading">Login</h2>
    
    <IconInputBox name="Benutzername" :icon_id="'ant-design:user-outlined'" v-model="username" />
    <IconInputBox
      name="Passwort"
      :icon_id="'bx:bxs-lock-alt'"
      type="password"
      v-model="password"
      :footer_link="{ name: 'MailReset', text: 'Passwort vergessen?' }"
    />

    <Button width="21em" @btn-click="authenticate">Einloggen</Button>
  </form>
</template>

<script>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import { use_toast } from '@/composables/composables';

  import IconInputBox from '@/components/general/IconInputBox.vue';
  import Button from '@/components/general/Button.vue';
  import Toast from '@/components/general/Toast.vue';

  /**
   * Login form component.
   * Login with username and password and then authentication with the backend.
   */
  export default {
    name: 'LoginCard',
    components: {
      IconInputBox,
      Button,
      Toast,
    },
    setup() {
      const username = ref(null); // v-model value for username input
      const password = ref(null); // v-model value for password input

      const store = useStore();
      const router = useRouter();

      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      /**
       * Authenticate with the backend and then redirect to the booking page.
       * Also check for access to statistics and fill store with the results.
       * @param {Event} event - the event is just for preventing the default behavior of the form submit.
       */
      const authenticate = async (event) => {
        event.preventDefault();

        await store.dispatch('authenticate', { username: username.value, password: password.value });

        // check access to statistics site
        await store.dispatch('fetch_can_see_statistics');

        if (store.getters.is_logged_in) {
          router.push({ name: 'Booking' });
        } else {
          show_toast('error', 'Login fehlgeschlagen');
        }
      };

      return { username, password, authenticate, is_toast_shown, toast_msg, toast_type };
    },
  };
</script>

<style scoped>
  .login-card {
    font-size: 1.1rem;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.8rem;
    padding: 1rem;
    width: 95%;

    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 2rem;
    max-width: 600px;
  }

  .login-card .login-heading {
    font-size: 1.4rem;
    font-weight: 400;
    color: #ea4c08;
  }

  @media (min-width: 1400px) {
    .login-card {
      font-size: 1.3rem;
      gap: 2.5rem;
    }
  }
</style>
