<template>
  <Overlay>
    <!-- Title for the current card -->
    <template v-slot:header>
      <h2 class="seat-name">Belegung am {{ date.format('DD.MM.YYYY') }} | {{ current.level.full_name }}</h2>
    </template>

    <template v-slot:content>
      <div class="content" v-if="!is_loading">
        <p class="descr">
          INFO: Im Zuge der letzten Änderung können Sie nun die Karten direkt anklicken um den Gebäudeplan zu wechseln.
        </p>

        <div class="occupation-info-wrapper">
          <!-- Information and selection card for each level to display occupation -->
          <div
            class="occupation-card"
            v-for="(itm, index) in data"
            :key="index"
            :style="{ backgroundColor: current.level.pk === itm.level.pk ? '#fff' : '#ddd' }"
            @click="show_seatplan(index)"
          >
            <h3 class="title">{{ itm.level.full_name }}</h3>
            <label>Frei:</label>
            <p>{{ itm.free_seats }} / {{ itm.total_seats }}</p>
            <label>Belegt:</label>
            <p>{{ itm.occupied_seats }} / {{ itm.total_seats }}</p>
            <label>Belegt in %:</label>
            <p>{{ Math.round(itm.occupation_percentage) }} %</p>
          </div>
        </div>

        <!-- Floor/Level plan picture which displays free seats -->
        <StatisticsSeatPreview @loaded="seat_preview_loading = false" :data_="current" />
        <p class="descr">Die blauen Makierungen stellen die frei verbleibenden Plätze da.</p>

        <Spinner v-if="seat_preview_loading" />
      </div>
      <Spinner v-else :is_loading="is_loading" />
    </template>
  </Overlay>
</template>

<script>
  import Overlay from '@/components/general/Overlay.vue';
  import Spinner from '@/components/general/Spinner.vue';
  import { computed, ref } from '@vue/reactivity';
  import StatisticsSeatPreview from './StatisticsSeatPreview.vue';
  import { api_routes } from '@/config';
  import axios from 'axios';
  import { Logger } from '@/util';

  /**
   * Overlay for a single day for the statistics occupation view.
   * It displays information about the occupation for each level.
   * It also displays the seat plan with marked free seats as well as allows to switch the level plan.
   */
  export default {
    components: {
      Overlay,
      Spinner,
      StatisticsSeatPreview,
    },
    props: {
      date: {
        type: Object, // dayjs object
        required: true,
      },
    },
    setup(props) {
      const is_loading = ref(true); // loading flag
      const data = ref(null); // occupation data for each level. data: {date: Date, occupation_percentage: number, free_seats: number, occupied_seats: number, total_seats: number, level: {pk: number, full_name: string, level_plan_url: string, level_plan_width: number, level_plan_height: number, seats: SeatLocations[]}}[]
      const data_index = ref(0); // currently selected level index for showing the seat plan
      const seat_preview_loading = ref(true); // loading flag for the seat plan

      /**
       * Load backend occupation data for each level for the current date.
       */
      const fetch_data = async () => {
        try {
          is_loading.value = true;
          const res = await axios.get(api_routes.overlay_statistics, {
            params: {
              date_: props.date.format('YYYY-MM-DD'),
            },
          });

          data.value = await res.data;
        } catch (error) {
          // TODO: show toast
          Logger.log(error);
        } finally {
          is_loading.value = false;
        }
      };

      fetch_data();

      /**
       * Computed value to get the data to the currently selected level.
       * @returns {object} data for the currently selected level.
       */
      const current = computed(() => {
        if (data.value === null) {
          return null;
        }

        return data.value[data_index.value];
      });

      /**
       * Show the seat plan for the currently selected level.
       * @param {number} index - index in data array corresponding to the selected level
       */
      const show_seatplan = (index) => {
        data_index.value = index;
        seat_preview_loading.value = true;
      };

      /**
       * Computed value to get all seat ids for the currently selected level.
       * @return {number[]} - array of seat ids
       */
      const get_seat_ids = computed(() => {
        if (current.value === null) {
          return [];
        }

        const seat_ids = current.value.level.seats.map((seat) => seat.id);

        return seat_ids;
      });

      return { is_loading, data, current, show_seatplan, get_seat_ids, seat_preview_loading };
    },
  };
</script>

<style scoped>
  .seat-name {
    color: #555;
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    padding: 0.5rem;
  }

  .overlay-menu {
    font-size: 0.9em;
    margin: 0 auto;
  }

  .header {
    background-color: #e0e0e0;
    border-radius: 0.3em;
    padding: 1em;
    width: 100%;
    font-size: 1rem;

    display: flex;
    align-items: center;
    gap: 0.8em;
    justify-content: center;
  }
  .header .building-level {
    font-size: 0.9em;
    font-weight: 500;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .header .building-level:hover {
    color: #555;
  }

  .occupation-info-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-evenly;
    padding: 1rem;
  }

  .occupation-card {
    background-color: #fff;
    border-radius: 0.3em;
    padding: 1em;
    font-size: 0.8rem;
    font-weight: 500;
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #555;
    cursor: pointer;
  }

  .occupation-card .title {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    grid-column: span 2;
    color: black;
  }

  .occupation-card p {
    justify-self: end;
  }

  .occupation-card label {
    color: #ff8d5c;
  }

  .descr {
    color: #555;
    font-size: 0.9rem;
  }

  @media (min-width: 650px) {
    .overlay-menu {
      font-size: 1em;
    }
  }
</style>
