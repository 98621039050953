<template>
  <div class="password-reset">
    <LoginHeader />

    <!-- Actual password reset card to handle the functionality with the backend -->
    <div class="password-reset-section">
      <PasswordResetCard />
    </div>
  </div>
</template>

<script>
  import LoginHeader from '@/components/view-members/auth/LoginHeader.vue';
  import PasswordResetCard from '@/components/view-members/auth/PasswordResetCard.vue';

  /**
   * Wrapper component for the password reset component.
   * This page is only accessible after an email with a reset token got sent to the user.
   */
  export default {
    name: 'passwordReset',
    components: {
      LoginHeader,
      PasswordResetCard,
    },
  };
</script>

<style scoped>
  .password-reset {
    background-image: url('~@/assets/bg-inp-building.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
  }

  .password-reset-section {
    margin: auto;
    height: 80vh;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
