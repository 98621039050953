<template>
  <div class="root-el">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />

    <!-- Favorite Toggle for desktop users -->
    <div class="occupation-card">
      <FavoriteIcon
        :is_favorite="item.is_favorite"
        @toggle="on_toggle_favorite(item.seat_pk)"
        class="desktop icon-favorite"
      />

      <div class="header-group">
        <div />

        <!-- Seat information -->
        <div class="seat-info">
          <h3>{{ item.user_name }}</h3>
          <h3>{{ item.seat_full_name }}</h3>
          <p>{{ item.seat_description }}</p>
        </div>

        <!-- Favorite Toggle for mobile users -->
        <FavoriteIcon
          :is_favorite="item.is_favorite"
          @toggle="on_toggle_favorite(item.seat_pk)"
          class="mobile icon-favorite"
        />
      </div>

      <!-- Timeframe information for the reservation -->
      <div class="offertimes">
        <label for="Start" class="descr-label">Start</label>
        <label for="End" class="descr-label">Ende</label>
        <p>{{ date_to_str(item.start).split(' ')[0] }}</p>
        <p>{{ date_to_str(item.start).split(' ')[1] }}</p>
        <p>{{ date_to_str(item.end).split(' ')[0] }}</p>
        <p>{{ date_to_str(item.end).split(' ')[1] }}</p>
      </div>

      <!-- Overlay functionality -->
      <div class="actions-group">
        <button class="action-link" @click="show_overlay = true">Sitzplan ></button>
      </div>
    </div>
    <div class="overlay-wrapper" v-if="show_overlay">
      <OccupationSeatPreviewOverlay :item="item" @close-overlay="show_overlay = false" />
    </div>
  </div>
</template>

<script>
  import FavoriteIcon from '@/components/general/FavoriteIcon.vue';
  import Toast from '@/components/general/Toast.vue';
  import OccupationSeatPreviewOverlay from '@/components/view-members/seat-lists/OccupationSeatPreviewOverlay.vue';

  import { date_to_str, Logger } from '@/util';
  import { ref } from '@vue/reactivity';

  import { use_toggle_favorite, use_toast } from '@/composables/composables';

  /**
   * List item to display the occupation/reservation information.
   * This also includes another overlay component to show the reserved seat on the seat plan.
   *
   * Emits:
   *   - toggle-favorite - toggle the favorite status of the seat
   *   - next - the right arrow was clicked to go to the next occupation list point
   *   - previous - the left arrow was clicked to go to the previous occupation list point
   */
  export default {
    name: 'OccupationCard',
    components: { FavoriteIcon, Toast, OccupationSeatPreviewOverlay },
    props: {
      item: {
        type: Object,
        required: true,
      },
      overlay_show: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['toggle-favorite', 'next', 'prev'],
    setup(props, { emit }) {
      const show_overlay = ref(false); // flag to indicate whether the overlay is shown or not

      const { toggle_favorite } = use_toggle_favorite();
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      /**
       * Toggle and post the favorite status to the backend.
       * After that emit the toggle-favorite event.
       * @param {number} pk - primary key of teh seat
       */
      const on_toggle_favorite = (pk) => {
        toggle_favorite(
          pk,
          (server_data) => {
            // update seat data according to seat list or favorite list mode
            if (server_data.state === 'false') {
              emit('toggle-favorite', { seat_pk: props.item.seat_pk, state: false });
            } else {
              emit('toggle-favorite', { seat_pk: props.item.seat_pk, state: true });
            }
          },
          (error) => {
            show_toast('error', 'Verbindungsfehler bei der Favorisierung des Platzes');
            Logger.log(error);
          }
        );
      };

      return { date_to_str, show_overlay, is_toast_shown, toast_type, toast_msg, on_toggle_favorite };
    },
  };
</script>

<style scoped>
  /* TODO: fix mobile style */

  .root-el {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .root-el .overlay-wrapper {
    font-size: 1rem;
  }

  .occupation-card {
    font-size: 0.7em;
    background-color: #fff;
    padding: 0.6em;
    border-radius: 0.4em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);

    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 2.2em;
    height: 100%;
    max-height: 14.706em;
    width: clamp(21.324em, 100%, 25em);
    max-width: 1200px;
  }

  .occupation-card .icon-favorite {
    font-size: 1.3em;
  }

  .occupation-card .header-group {
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.2fr;
    width: 100%;
    justify-items: center;
  }

  .occupation-card .header-group .icon {
    color: #9d9d9d;
    font-size: 1.1em;
    cursor: pointer;
  }

  .occupation-card .desktop {
    display: none;
  }

  .occupation-card .header-group .icon:hover,
  .occupation-card .icon.icon.desktop:hover {
    color: #5d5d5d;
  }

  .occupation-card .header-group .seat-info {
    display: flex;
    flex-direction: column;
    place-items: center;
  }

  .occupation-card .header-group .seat-info h3 {
    font-weight: 500;
  }

  .occupation-card .header-group .seat-info p {
    font-size: 0.8em;
    color: #9d9d9d;
  }

  .occupation-card .offertimes {
    font-size: 0.9em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;

    padding: 0.2em 0.4em;
    background-color: #aaa;
    border-radius: 0.3em;
    width: 55%;
    color: white;
    cursor: pointer;
  }

  .occupation-card .offertimes .descr-label {
    display: none;
  }

  .occupation-card .offertimes:hover {
    background-color: #c5c5c5;
  }

  .occupation-card .actions-group {
    display: flex;
    width: 90%;
    justify-content: center;
  }

  .occupation-card .actions-group .action-link {
    font-size: 1.1em;
    color: #ff7f48;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .occupation-card .actions-group .action-link:hover {
    color: #ff4d00;
  }

  .occupation-card .actions-group .btn-info {
    font-size: 0.9em;
  }

  @media (min-width: 960px) {
    .occupation-card {
      font-size: 0.8em;
      width: 100%;
      height: unset;
      padding: 0.6em 1.2em;

      display: grid;
      grid-template-columns: repeat(4, auto);
      font-size: 0.7em;
    }

    .occupation-card .header-group .mobile {
      display: none;
    }

    .occupation-card .desktop {
      display: block;
      /* color: #9d9d9d; */
      cursor: pointer;
      font-size: 1.2em;
    }

    .occupation-card .header-group {
      justify-content: start;
    }

    .occupation-card .offertimes .descr-label {
      display: block;
      color: #9d9d9d;
      grid-column: span 2;
    }

    .occupation-card .offertimes {
      font-size: 1.1em;
      display: grid;
      grid-template-columns: auto 1fr auto 1fr;
      place-items: start;
      column-gap: 0.4em;

      padding: unset;
      border-radius: unset;
      width: 100%;
      color: #555;
      background-color: transparent;
      cursor: unset;
    }

    .occupation-card .offertimes:hover {
      background-color: transparent;
    }
  }

  /* @media (min-width: 730px) {
    .occupation-card {
      font-size: 0.8em;
    }
  } */
</style>
