<template>
  <div class="occupation">
    <!-- TODO: add toast for notifications -->

    <div class="content">
      <!-- Search input -->
      <div class="filter-container">
        <div class="filter-card">
          <Icon icon="ci:filter-off" class="filter-icon" @click="clear_filter" />
          <DateTimeInput name="Start" class="dt-input-start" v-model="start_str" :default_value="default_start_str" />
          <SearchInput
            class="search-input"
            v-model="current_search"
            @add-tag="on_add_tag"
            :completion_items="completion"
          />
          <div class="search-tags" v-if="search_tags.length > 0">
            <label class="search-for">
              <span class="reset-filter-icon"
                ><Icon icon="ci:filter-off" class="tags-filter-icon" @click="search_tags = []"
              /></span>
              <span class="descr">Suche nach:</span>
            </label>
            <SearchTag v-for="tag in search_tags" :key="tag" :tag="tag" @remove-tag="on_remove_tag(tag)" />
          </div>
        </div>
      </div>

      <div class="spinner-wrapper" v-if="is_loading">
        <Spinner :is_loading="is_loading" />
      </div>

      <!-- Display for the occupation information cards -->
      <div class="occupation-card-container" v-if="!is_loading">
        <OccupationCard
          class="oocupation-card"
          v-for="(itm, index) in filtered_data"
          :key="index"
          :item="itm"
          @toggle-favorite="on_toggle_favorite"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from '@vue/runtime-core';

  import axios from 'axios';
  import dayjs from 'dayjs';

  import { Logger, date_to_str, str_to_date } from '@/util';
  import { api_routes } from '@/config';
  import { use_search } from '@/composables/composables';

  import { Icon } from '@iconify/vue';

  import DateTimeInput from '@/components/general/DateTimeInput.vue';
  import SearchInput from '@/components/general/SearchInput.vue';
  import SearchTag from '@/components/general/SearchTag.vue';
  import OccupationCard from '@/components/view-members/seat-lists/OccupationCard.vue';
  import Spinner from '@/components/general/Spinner.vue';

  /**
   * Component to display information for the occupation starting from the current date.
   * Also the occupation can be filtered.
   */
  export default {
    name: 'Occupation',
    components: {
      Icon,
      OccupationCard,
      DateTimeInput,
      SearchInput,
      SearchTag,
      Spinner,
    },
    setup() {
      const { current_search, search_tags, data, filtered_data, on_add_tag, on_remove_tag } = use_search();
      const completion = ref([]); // completion items for the search input

      const default_start_str = date_to_str(
        dayjs(new Date()).add(1, 'hour').minute(0).second(0).millisecond(0).toDate()
      ); // default start date to get occupation information for
      const start_str = ref(default_start_str); // changable start date to get occupation information for

      const is_loading = ref(true); // loading flag

      /**
       * Completion watcher handler to update the completion items depending on the current search.
       */
      watch(filtered_data, () => {
        completion.value = [];

        for (let item of filtered_data.value) {
          item.seat_description.split(', ').forEach((el) => {
            if (!completion.value.includes(el) && !search_tags.value.includes(el)) {
              completion.value.push(el);
            }
          });
        }
        completion.value.sort();
      });

      /**
       * Fetch the occupation data from the backend and set the data ref to it.
       * Occupation data: {
       * pk: number,
       * start: Date,
       * end: Date,
       * seat_full_name: string,
       * seat_d3escription: string,
       * user_name: string,
       * is_favorite: boolean,
       * }
       * @param {Date} dt
       */
      const fetch_occupation_list = async (dt) => {
        try {
          is_loading.value = true;

          const res = await axios.get(api_routes.occupation_list, {
            params: {
              start: dt,
            },
          });

          data.value = res.data;

          is_loading.value = false;
        } catch (error) {
          // TODO: show Toast
          Logger.log(error);
        }
      };

      fetch_occupation_list(str_to_date(default_start_str));

      /**
       * Reset the filter for the current search.
       */
      const clear_filter = () => {
        current_search.value = '';
        search_tags.value = [];
        start_str.value = default_start_str;
      };

      /**
       * Dummy function.
       * TODO: implement
       */
      const to_seat_plans_route = () => {
        console.log('to_seat_plans_route');
      };

      watch(start_str, () => {
        try {
          const start_dt = dayjs(str_to_date(start_str.value));

          const now = dayjs(new Date());

          if (start_dt.isBefore(now)) {
            start_str.value = default_start_str;
            return;
          }

          fetch_occupation_list(str_to_date(start_str.value));
        } catch (error) {
          Logger.log(error);
          return;
        }
      });

      /**
       * Toggle the favorite status of the with the occupation item associated seat. The actual api call is done in a sub-component.
       * @param {number} seat_pk - the seat pk to toggle the favorite status for
       * @param {boolean} is_favorite - the new favorite status
       */
      const on_toggle_favorite = ({ seat_pk, state }) => {
        data.value = data.value.map((item) => {
          if (item.seat_pk === seat_pk) {
            item.is_favorite = state;
          }

          return item;
        });
      };

      return {
        current_search,
        search_tags,
        data,
        filtered_data,
        on_add_tag,
        on_remove_tag,
        completion,
        clear_filter,
        to_seat_plans_route,
        default_start_str,
        start_str,
        on_toggle_favorite,
        is_loading,
      };
    },
  };
</script>

<style scoped>
  .occupation .content {
    width: 90%;
    min-height: 80vh;
    margin: 0 auto;
    background-color: #ebebeb;
    border-radius: 0.3em;
    padding: 0 0 1.8em 0;

    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .occupation .content .filter-container {
    background-color: #e1e1e1;
    padding: 0.8em;
    border-radius: 0.3em 0.3em 0 0;
    display: flex;
    place-content: center;
    margin-bottom: 0.5em;
  }

  .occupation .content .filter-card {
    padding: 1em;
    background-color: #fff;
    border-radius: 0.3em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    width: 90%;
    max-width: 31.25rem;
    min-width: 16.3125rem;

    font-size: 1.1em;
  }

  .occupation-card-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .filter-icon {
    font-size: 1.2em;
    color: #afafaf;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
  }

  .filter-icon:hover {
    color: #838383;
  }

  .filter-container .filter-card .filter-icon {
    font-size: 1em;
  }

  .filter-container .filter-card .search-input {
    margin-top: 1.3em;
  }

  .filter-container .filter-card .btn-seatplans {
    margin: 1em 0.8em;
    width: 70%;
    max-width: 18em;
  }

  .filter-container .filter-card .search-tags {
    display: flex;
    flex-wrap: wrap;
    width: 79%;

    place-content: flex-start;
    gap: 0.2em 0.4em;
  }

  .filter-container .filter-card .search-tags .search-for {
    color: #9d9d9d;
    font-size: 0.7em;
    margin-right: 0.8em;
    display: flex;
    align-items: center;
    gap: 0.25em;
  }

  .filter-container .filter-card .search-tags .search-for .reset-filter-icon {
    color: #727272;
    cursor: pointer;
  }

  .filter-container .filter-card .search-tags .search-for .reset-filter-icon:hover {
    color: #a3a3a3;
  }

  .filter-container .filter-card .search-tags .search-for .descr {
    color: #9d9d9d;
  }

  .filter-container .filter-card .search-tags .search-tag {
    font-size: 0.65em;
  }

  .oocupation-card {
    max-width: 54.375rem;
  }
</style>
