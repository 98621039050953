<template>
  <Overlay @close-overlay="$emit('close-overlay')">
    <!-- Header information for the opened reservation -->
    <template v-slot:header>
      <header class="header-group">
        <div class="seat-info">
          <h3>{{ reservation.seat_name }}</h3>
          <p>{{ reservation.seat_description }}</p>
        </div>

        <div class="reservation-timeframe">
          <p>{{ date_to_str(reservation.start).split(' ')[0] }}</p>
          <p>{{ date_to_str(reservation.start).split(' ')[1] }}</p>
          <p>-</p>
          <p>{{ date_to_str(reservation.end).split(' ')[0] }}</p>
          <p>{{ date_to_str(reservation.end).split(' ')[1] }}</p>
        </div>
      </header>
    </template>

    <!-- Menu of sub-pages -->
    <template v-slot:menu>
      <div class="reservation-slider-menu">
        <SliderMenu
          :menu_items="['Info', 'Belegung', 'Sitzplan']"
          class="overlay-menu"
          :active_start="active_menu_item"
          @menu-click="on_menu_click"
        />
      </div>
    </template>

    <!-- Sub-pages components -->
    <template v-slot:content>
      <ReservationSummary
        :reservation="reservation"
        @reservation-canceled="on_reservation_cancled"
        v-if="active_menu_item === 'Info'"
      />
      <SeatOccupation v-if="active_menu_item === 'Belegung'" :seat_id="reservation.seat_id" />
      <div class="spinner-wrapper" v-if="active_menu_item === 'Sitzplan' && seat_preview_loading">
        <Spinner :is_loading="seat_preview_loading" />
      </div>
      <SeatPreview
        v-if="active_menu_item === 'Sitzplan'"
        :seat_ids="[reservation.seat_id]"
        @loaded="seat_preview_loading = false"
      />
    </template>
  </Overlay>
</template>

<script>
  import { ref } from '@vue/reactivity';
  import { date_to_str } from '@/util';

  import Overlay from '@/components/general/Overlay.vue';
  import ReservationSummary from '@/components/view-members/reservation/ReservationSummary.vue';
  import SliderMenu from '@/components/general/SliderMenu.vue';
  import SeatOccupation from '@/components/view-members/booking/SeatOccupation.vue';
  import SeatPreview from '@/components/general/SeatPreview.vue';
  import Spinner from '@/components/general/Spinner.vue';

  /**
   * Wrapper overlay component for all reservation related functionality.
   *
   * This includes:
   *   - Info: All sub-reservations items as list view which can also be canceled.
   *   - Belegung: Seat occupation view.
   *   - Sitzplan: Seat preview view on seat plan image.
   *
   * Emits:
   *   - reservation-canceled: When a reservation is canceled.
   *   - close-overlay: Emitted when the overlay is closed.
   */
  export default {
    name: 'ReservationOverlay',
    components: {
      Overlay,
      ReservationSummary,
      SliderMenu,
      SeatOccupation,
      SeatPreview,
      Spinner,
    },
    props: {
      reservation: {
        // reservation data
        type: Object,
        required: true,
        /*
          reservations format:
          [{
            pk,
            seat_name,
            seat_description,
            comment,
            start,
            end,
            reservation_items: [{
              pk, start, end }]
          }]
        */
      },
      menu_item: {
        // Menu item to be opened initially
        type: String,
        required: true,
      },
    },
    emits: ['reservation-canceled', 'close-overlay'],
    setup(props, { emit }) {
      const active_menu_item = ref(props.menu_item); // active menu item
      const seat_preview_loading = ref(true); // flag to indicate if seat plan is loading

      /**
       * Open other sub-page of the overlay.
       * @param {string} item - the menu item to be opened
       */
      const on_menu_click = (item) => {
        active_menu_item.value = item;

        if (item === 'Sitzplan') {
          seat_preview_loading.value = true;
        }
      };

      /**
       * Emits the reservation-canceled event and closes the overlay.
       * @param {object} reservation - the reservation to be canceled
       */
      const on_reservation_cancled = (reservation) => {
        emit('reservation-canceled', reservation);
        emit('close-overlay');
      };

      return { date_to_str, active_menu_item, on_reservation_cancled, on_menu_click, seat_preview_loading };
    },
  };
</script>

<style scoped>
  .header-group {
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 0.7em;
  }

  .header-group .seat-info {
    display: flex;
    flex-direction: column;
    place-items: center;
  }

  .header-group .seat-info h3 {
    font-weight: 500;
  }

  .header-group .seat-info p {
    font-size: 0.8em;
    color: #9d9d9d;
  }

  .header-group .reservation-timeframe {
    font-size: 0.85em;
    color: #555;

    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 0.35em;
  }

  .reservation-slider-menu {
    width: 100%;
    display: flex;
    justify-content: center;
  }
</style>
