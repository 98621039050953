<template>
  <div class="icon-input-box">
    <label :for="name">{{ name }}</label>

    <!-- Input field -->
    <div class="input-box">
      <Icon :icon="icon_id" class="icon" />
      <input
        :type="type"
        :name="name"
        :value="modelValue"
        :placeholder="`${name} eingeben...`"
        @input="this.$emit('update:modelValue', $event.target.value)"
      />
    </div>

    <!-- Router link to be shown if it is supplied -->
    <router-link :to="{ name: footer_link.name }" v-if="footer_link !== null" class="footer-text">{{
      footer_link.text
    }}</router-link>
  </div>
</template>

<script>
  import { Icon } from '@iconify/vue';

  /**
   * Input field with an icon and a label.
   * If footer link is supplied, a router link is shown at the bottom of the input field.
   */
  export default {
    name: 'IconInputBox',
    components: {
      Icon,
    },
    props: {
      name: {
        // label of the input field
        type: String,
        required: true,
      },
      icon_id: {
        // name of the icon to show
        type: String,
        required: true,
      },
      footer_link: {
        type: Object, // { name: '', text: '' }
        required: false,
        default: null,
      },
      type: {
        // input type according to HTML5
        type: String,
        required: false,
        default: 'text',
      },
      modelValue: {
        // v-model value
        type: String,
        required: false,
        default: '',
      },
    },
  };
</script>

<style scoped>
  .icon-input-box {
    --font-size: 0.8em;
    --color: #afafaf;

    font-size: 0.8em;

    width: 17em;
  }

  .icon-input-box label {
    color: #727272;
    font-size: 0.8em;
    margin-bottom: 0.2em;
  }

  .icon-input-box .input-box {
    display: flex;
    place-items: center;
    gap: 0.4em;

    border-bottom: 1px solid var(--color);
  }

  .icon-input-box .input-box .icon {
    color: var(--color);
  }

  .icon-input-box .input-box input {
    border: none;
    width: 100%;
    font-size: 0.9em;
  }

  .icon-input-box .input-box input::placeholder {
    color: var(--color);
  }

  .icon-input-box .input-box input:focus {
    outline: none;
  }

  .footer-text {
    color: #727272;
    font-size: 0.7em;
    float: right;
    margin-top: 0.2em;
    cursor: pointer;
    text-decoration: none;
  }

  .footer-text:hover {
    text-decoration: underline;
  }
</style>
