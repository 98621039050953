<template>
  <div class="seat-list">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />

    <div class="content">
      <!-- Search functionality -->
      <div class="search-group">
        <SearchInput
          class="search-input"
          v-model="current_search"
          @add-tag="on_add_tag"
          :completion_items="completion"
        />
        <button class="no-owner-filter" @click="no_user_filter = !no_user_filter">
          <span>Kein Besitzer</span>
          <Icon icon="akar-icons:circle-check" v-if="no_user_filter" />
        </button>
        <div class="search-tags" v-if="search_tags.length > 0">
          <label class="search-for">
            <span class="reset-filter-icon"
              ><Icon icon="ci:filter-off" class="filter-icon" @click="search_tags = []"
            /></span>
            <span class="descr">Suche nach:</span>
          </label>
          <SearchTag
            v-for="tag in search_tags"
            :key="tag"
            class="search-tag"
            :tag="tag"
            @remove-tag="on_remove_tag(tag)"
          />
        </div>
      </div>

      <!-- Seat cards with information -->
      <div class="seat-cards" v-if="!loading">
        <SeatCard
          v-for="seat in filtered_data_user_filter"
          :key="seat.pk"
          :seat="seat"
          @toggle-favorite="on_toggle_favorite(seat.pk)"
        />
      </div>

      <div class="spinner-wrapper" v-else>
        <Spinner :is_loading="loading" />
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  import { use_toast, use_search, use_toggle_favorite } from '@/composables/composables';
  import { api_routes } from '@/config';
  import { Logger } from '@/util';

  import { Icon } from '@iconify/vue';

  import SearchInput from '@/components/general/SearchInput.vue';
  import SearchTag from '@/components/general/SearchTag.vue';
  import SeatCard from '@/components/view-members/seat-lists/SeatCard.vue';
  import Toast from '@/components/general/Toast.vue';
  import { computed, ref } from '@vue/reactivity';
  import { watch } from '@vue/runtime-core';
  import Spinner from '@/components/general/Spinner.vue';

  /**
   * Component to show either all seats or only favorite seats.
   * Infomation will be then displayed for the applied filter.
   */
  export default {
    name: 'SeatList',
    components: {
      Icon,
      SearchInput,
      SearchTag,
      SeatCard,
      Toast,
      Spinner,
    },
    props: {
      is_favorite: {
        // whether the seat list is for favorites or all seats
        type: Boolean,
        required: true,
      },
    },
    setup(props) {
      const completion = ref([]); // completion items for search input
      const loading = ref(false); // loading flag

      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      // search functionality
      const { current_search, search_tags, data, filtered_data, on_add_tag, on_remove_tag } = use_search();

      const no_user_filter = ref(false);

      /*
      inital fetch for seat data
      data format: {
        pk: number,
        full_name: string,
        owner: string,
        description: string,
        is_favorite: boolean,
      }
      */
      (async () => {
        try {
          loading.value = true;
          const res = await axios.get(props.is_favorite ? api_routes.favorite_seats : api_routes.seat_list);

          data.value = await res.data;
        } catch (error) {
          show_toast('error', 'Verbindungsfehler!');
          Logger.log(error);
        } finally {
          loading.value = false;
        }
      })();

      const { toggle_favorite } = use_toggle_favorite();

      /**
       * Toggle favorite status of a seat with success and error callbacks.
       * Success All Seats: seat favorite flag is set.
       * Success Favorites: seat is removed from favorites.
       * @param {number} pk - primary key of the seat to be toggled
       */
      const on_toggle_favorite = (pk) => {
        toggle_favorite(
          pk,
          (server_data) => {
            // update seat data according to seat list or favorite list mode
            if (server_data.state === 'false' && props.is_favorite) {
              data.value = data.value.filter((seat) => {
                return seat.pk !== pk;
              });
            } else {
              data.value.forEach((seat) => {
                if (seat.pk === pk) {
                  seat.is_favorite = server_data.state === 'true' ? true : false;
                }
              });
            }
          },
          (error) => {
            show_toast('error', 'Verbindungsfehler!');
            Logger.log(error);
          }
        );
      };

      /**
       * Add or remove completion strings for search input depending on the current selection.
       */
      watch(filtered_data, () => {
        completion.value = [];
        for (let item of filtered_data.value) {
          item.description.split(', ').forEach((el) => {
            if (!completion.value.includes(el) && !search_tags.value.includes(el)) {
              completion.value.push(el);
            }
          });
          completion.value.sort();
        }
      });

      /**
       * Toggle filter for either showing all seats or only these who have no owner.
       */
      const filtered_data_user_filter = computed(() => {
        return filtered_data.value.filter((seat) => {
          if (no_user_filter.value === true) {
            return seat?.owner === undefined;
          } else {
            return true;
          }
        });
      });

      return {
        is_toast_shown,
        toast_type,
        toast_msg,
        show_toast,
        data,
        on_toggle_favorite,
        filtered_data,
        current_search,
        search_tags,
        on_add_tag,
        on_remove_tag,
        completion,
        no_user_filter,
        filtered_data_user_filter,
        loading,
      };
    },
  };
</script>

<style scoped>
  .seat-list .content {
    width: 90%;
    min-height: 80vh;
    margin: 0 auto;
    background-color: #ebebeb;
    border-radius: 0.3em;
    padding: 1.8em 0;
  }

  .seat-list .content .search-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
  }

  .seat-list .content .search-group .no-owner-filter {
    color: #ff8e5d;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.25em;
    font-size: 0.8em;
  }

  .seat-list .content .search-group .no-owner-filter:hover {
    color: #ff783d;
    text-decoration: underline;
  }

  .seat-list .content .search-group .search-input {
    font-size: 1.2em;
  }

  .seat-list .content .search-group .search-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0.156em;
    width: 90%;
  }

  .seat-list .content .search-group .search-tags .search-for {
    display: flex;
    align-items: center;
    gap: 0.25em;
    margin-right: 0.3em;
  }

  .seat-list .content .search-group .search-tags .search-for .reset-filter-icon {
    color: #727272;
    font-size: 0.75em;
    cursor: pointer;
  }

  .seat-list .content .search-group .search-tags .search-for .reset-filter-icon:hover {
    color: #a3a3a3;
  }

  .seat-list .content .search-group .search-tags .search-for .descr {
    color: #9d9d9d;
    font-size: 0.75em;
  }

  .seat-list .content .search-group .search-tags .search-tag {
    font-size: 0.65em;
  }

  .seat-list .content .seat-cards {
    width: 90%;
    margin: 2em auto 1.5em auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5em;
    font-size: 1.05em;
  }

  @media (min-width: 800px) {
    .seat-list .content .search-group .search-tags {
      width: 70%;
    }
  }
</style>
