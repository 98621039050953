<template>
  <div class="search-tag" @click="$emit('remove-tag', tag)">
    <span class="query">{{ tag }}</span>
    <Icon icon="akar-icons:circle-minus" class="icon" />
  </div>
</template>

<script>
  import { Icon } from '@iconify/vue';

  /**
   * Search tag component for displaying a search tag and a remove icon.
   * Emits remove-tag event when clicked.
   */
  export default {
    name: 'SearchTag',
    components: {
      Icon,
    },
    props: {
      tag: {
        type: String,
        required: true,
      },
    },
    emits: ['remove-tag'],
  };
</script>

<style scoped>
  .search-tag {
    font-size: 0.65em;
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    border-radius: 7%;
    padding: 0.08em 0.5em;
    gap: 0.4em;
    cursor: pointer;
  }

  .search-tag:hover {
    background-color: #e0e0e0;
  }

  .search-tag .query {
    color: #8d8d8d;
  }

  .search-tag .icon {
    color: #ffa57d;
  }
</style>
