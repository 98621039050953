<template>
  <div class="reservation-card-root">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />
    <div class="reservation-card">

      <FavoriteIcon
        :is_favorite="reservation.is_favorite"
        @toggle="on_toggle_favorite(reservation.seat_id)"
        class="desktop icon-favorite"
        v-if="!in_edit_mode"
      />

      <!-- Edit mode icon -->
      <Icon
        :icon="`akar-icons:circle${edit_active ? '-check' : ''}`"
        class="icon desktop"
        v-if="in_edit_mode"
        @click="$emit('edit-toggle', reservation.pk)"
      />
      <div class="header-group">
        <Icon
          :icon="`akar-icons:circle${edit_active ? '-check' : ''}`"
          class="icon mobile"
          v-if="in_edit_mode"
          @click="$emit('edit-toggle', reservation.pk)"
        />
        <div v-else></div>

        <div class="seat-info">
          <h3>{{ reservation.seat_name }}</h3>
          <p>{{ reservation.seat_description }}</p>
        </div>

        <FavoriteIcon
          :is_favorite="reservation.is_favorite"
          @toggle="on_toggle_favorite(reservation.seat_id)"
          class="mobile icon-favorite"
        />
      </div>

      <!-- Reservation information -->
      <div class="offertimes">
        <label for="Start" class="descr-label">Start</label>
        <label for="End" class="descr-label">Ende</label>
        <p>{{ date_to_str(reservation.start).split(' ')[0] }}</p>
        <p>{{ date_to_str(reservation.start).split(' ')[1] }}</p>
        <p>{{ date_to_str(reservation.end).split(' ')[0] }}</p>
        <p>{{ date_to_str(reservation.end).split(' ')[1] }}</p>
      </div>

      <!-- Buttons to open the overlays -->
      <div class="actions-group">
        <button class="action-link" @click="on_action_click('Belegung')">Belegung ></button>
        <button class="action-link" @click="on_action_click('Sitzplan')">Sitzplan ></button>
        <Button class="btn-info" @click="on_action_click('Info')">Info</Button>
      </div>
    </div>

    <!-- Actual overlay component -->
    <div class="overlay-wrapper" v-if="show_overlay">
      <ReservationOverlay
        :reservation="reservation"
        :menu_item="overlay_menu_item"
        @reservation-canceled="on_reservation_cancled"
        @close-overlay="show_overlay = false"
      />
    </div>
  </div>
</template>

<script>
  import { ref, toRef } from 'vue';

  import { date_to_str, Logger } from '@/util';
  import { use_toggle_favorite, use_toast } from '@/composables/composables';

  import { Icon } from '@iconify/vue';

  import ReservationOverlay from '@/components/view-members/reservation/ReservationOverlay.vue';
  import Button from '@/components/general/Button.vue';
  import FavoriteIcon from '@/components/general/FavoriteIcon.vue';
  import Toast from '@/components/general/Toast.vue';

  /**
   * Card type style component to display information about a reservation.
   * This component is used in the reservations list view.
   * It also provides the functionality to edit the reservation and open a overlay to view & edit the reservation.
   * 
   * Emits:
   *   - reservation-cancled: When the reservation is canceled.
   *   - edit-toggle: When the edit mode is toggled.
   *   - toggle-favorite: When the favorite status is toggled.
   */
  export default {
    name: 'ReservationCard',
    components: { Icon, ReservationOverlay, Button, FavoriteIcon, Toast },
    props: {
      edit_mode: {
        // Flag to enable edit mode
        type: Boolean,
        required: true,
      },
      edit_active: {
        // Flag to inicate if the item is selected in edit mode
        type: Boolean,
        required: true,
      },
      reservation: {
        // reservation data
        type: Object,
        required: true,
        /*
          reservations format:
          [{ 
            pk,
            seat_name,
            seat_description,
            comment,
            start,
            end,
            reservation_items: [{ 
              pk, start, end }]
          }]
        */
      },
    },
    emits: ['reservation-canceled', 'edit-toggle', 'toggle-favorite'],
    setup(props, { emit }) {
      const in_edit_mode = toRef(props, 'edit_mode'); // Edit mode flag

      const show_overlay = ref(false); // Flag to show the overlay
      const overlay_menu_item = ref('Info'); // Menu item to open the overlay
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      /**
       * Emit the reservation-canceled event.
       * @param {object} reservation reservation to emit cancel event for
       */
      const on_reservation_cancled = (reservation) => {
        emit('reservation-canceled', reservation);
      };

      /**
       * Open the overlay with the given menu item. 
       * @param {string} menu_start_item - menu item to open the overlay
       */
      const on_action_click = (menu_start_item) => {
        overlay_menu_item.value = menu_start_item;
        show_overlay.value = true;
      };

      const { toggle_favorite } = use_toggle_favorite();

      /**
       *  toggle_favorite function with callback to emit the toggle-favorite event.
       * @param {number} pk - seat id
       */
      const on_toggle_favorite = (pk) => {
        toggle_favorite(
          pk,
          (server_data) => {
            // update seat data according to seat list or favorite list mode
            if (server_data.state === 'false') {
              emit('toggle-favorite', { seat_id: props.reservation.seat_id, state: false });
            } else {
              emit('toggle-favorite', { seat_id: props.reservation.seat_id, state: true });
            }
          },
          (error) => {
            show_toast('error', 'Verbindungsfehler bei der Favorisierung des Platzes');
            Logger.log(error);
          }
        );
      };

      return {
        in_edit_mode,
        show_overlay,
        date_to_str,
        on_reservation_cancled,
        overlay_menu_item,
        on_action_click,
        on_toggle_favorite,
        is_toast_shown,
        toast_type,
        toast_msg,
      };
    },
  };
</script>

<style scoped>
  /* TODO: fix mobile style */

  .reservation-card-root {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .reservation-card-root .overlay-wrapper {
    font-size: 1rem;
  }

  .reservation-card {
    font-size: 0.7em;
    background-color: #fff;
    padding: 0.6em;
    border-radius: 0.4em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);

    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 2.2em;
    height: 100%;
    max-height: 14.706em;
    width: clamp(21.324em, 100%, 25em);
    max-width: 1200px;
  }

  .reservation-card .icon-favorite {
    font-size: 1.3em;
  }

  .reservation-card .header-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .reservation-card .header-group .icon {
    color: #9d9d9d;
    font-size: 1.1em;
    cursor: pointer;
  }

  .reservation-card .desktop {
    display: none;
  }

  .reservation-card .header-group .icon:hover,
  .reservation-card .icon.icon.desktop:hover {
    color: #5d5d5d;
  }

  .reservation-card .header-group .seat-info {
    display: flex;
    flex-direction: column;
    place-items: center;
  }

  .reservation-card .header-group .seat-info h3 {
    font-weight: 500;
  }

  .reservation-card .header-group .seat-info p {
    font-size: 0.8em;
    color: #9d9d9d;
  }

  .reservation-card .offertimes {
    font-size: 0.9em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;

    padding: 0.2em 0.4em;
    background-color: #aaa;
    border-radius: 0.3em;
    width: 55%;
    color: white;
    cursor: pointer;
  }

  .reservation-card .offertimes .descr-label {
    display: none;
  }

  .reservation-card .offertimes:hover {
    background-color: #c5c5c5;
  }

  .reservation-card .actions-group {
    display: flex;
    width: 90%;
    justify-content: space-between;
  }

  .reservation-card .actions-group .action-link {
    font-size: 0.9em;
    color: #ff7f48;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .reservation-card .actions-group .action-link:hover {
    color: #ff4d00;
  }

  .reservation-card .actions-group .btn-info {
    font-size: 0.9em;
  }

  @media (min-width: 960px) {
    .reservation-card {
      font-size: 0.8em;
      width: 100%;
      height: unset;
      padding: 0.6em 1.2em;

      display: grid;
      grid-template-columns: repeat(4, auto);
      font-size: 0.7em;
    }

    .reservation-card .header-group .mobile {
      display: none;
    }

    .reservation-card .desktop {
      display: block;
      color: #9d9d9d;
      cursor: pointer;
      font-size: 1.2em;
    }

    .reservation-card .header-group {
      justify-content: start;
    }

    .reservation-card .offertimes .descr-label {
      display: block;
      color: #9d9d9d;
      grid-column: span 2;
    }

    .reservation-card .offertimes {
      font-size: 0.9em;
      display: grid;
      grid-template-columns: auto 1fr auto 1fr;
      place-items: start;
      column-gap: 0.4em;

      padding: unset;
      border-radius: unset;
      width: 100%;
      color: #555;
      background-color: transparent;
      cursor: unset;
    }

    .reservation-card .offertimes:hover {
      background-color: transparent;
    }
  }

  /* @media (min-width: 730px) {
    .reservation-card {
      font-size: 0.8em;
    }
  } */
</style>
