<template>
  <div class="timeframe">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />

    <!-- Inputs for dynamically changing start and end time -->
    <div class="timeframe-inputs">
      <DateTimeInput name="Start" class="dt-input" v-model="start_str" :default_value="default_start_str" />
      <DateTimeInput name="Ende" class="dt-input" v-model="end_str" :default_value="default_end_str" />

      <div class="btn-wrapper">
        <Button @click="$emit('to-booking')">Zu Buchen</Button>
      </div>
    </div>

    <!-- Calendar for showing the seat occupation -->
    <div class="calendar-group">
      <Spinner :is_loading="is_loading" />
      <Calendar :items="get_calendar_data" v-if="!calendar_data_loading" />
    </div>
  </div>
</template>

<script>
  import DateTimeInput from '@/components/general/DateTimeInput.vue';
  import Button from '@/components/general/Button.vue';
  import Calendar from '@/components/general/calendar/Calendar.vue';
  import Spinner from '@/components/general/Spinner.vue';
  import { computed, ref, toRef } from '@vue/reactivity';
  import { Logger, fetch_occupation, str_to_date, occupation_to_calendar_events } from '@/util';
  import dayjs from 'dayjs';
  import { use_start_end_dtinputs, use_toast } from '@/composables/composables';
  import Toast from '@/components/general/Toast.vue';

  /**
   * Component used in overlay to show information about the current booking offer timeframe & occupation.
   * Also provide functionality to change the timeframe.
   * Emits:
   *   - to-booking: emitted when the user wants to make a reservation for the current timeframe
   *   - timeframe-change: emitted when the timeframe has changed
   */
  export default {
    name: 'Timeframe',
    components: {
      DateTimeInput,
      Button,
      Calendar,
      Toast,
      Spinner,
    },
    props: {
      offer: {
        // the current booking offer from the main booking page
        type: Object,
        required: true,
        /*
          {
            offer_id: number,
            seat_name: string,
            description: string,
            is_favorite: boolean,
            start: Date,
            end: Date,
          }
        */
      },
    },
    emits: ['to-booking', 'timeframe-change'],
    setup(props, { emit }) {
      const calendar_data = ref([]); // the calendar data to show the occupation for the given seat
      const ref_offer = toRef(props, 'offer'); // the current booking offer from the main booking page
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();
      const calendar_data_loading = ref(true); // flag to indicate if the calendar data is loading

      /**
       * Callback function to use with start_end_dtinputs to check if the newly changed timeframe falls in timeframe in which the seat is available.
       * The callback also emits the timeframe-change event if the new timeframe is valid.
       * @param {string} start_str the new start time as string
       * @param {string} end_str the new end time as string
       * @param {string} default_start_str the default start time as string
       * @param {string} default_end_str the default end time as string
       */
      const start_end_callback = ({ start_str, end_str, default_start_str, default_end_str }) => {
        // FIXME: double trigger of function because start and end change

        // TODO: check with owner seat release times
        if (ref_offer.value?.is_owned && ref_offer.value.is_owned) {
          show_toast('error', 'Sie können den Zeitraum bei einem Sitz mit Besitzer nicht verändern');
          start_str.value = default_start_str.value;
          end_str.value = default_end_str.value;
          return;
        }

        const start_dt = dayjs(str_to_date(start_str.value));
        const end_dt = dayjs(str_to_date(end_str.value));

        // check if the new timeframe is in the timeframe in which the seat is occupied
        const occupied =
          calendar_data.value.length === 0
            ? false
            : calendar_data.value.some(
                (item) =>
                  start_dt.isBetween(item.start, item.end, null, '[]') ||
                  end_dt.isBetween(item.start, item.end, null, '[]')
              );

        if (occupied) {
          start_str.value = default_start_str.value;
          end_str.value = default_end_str.value;
        }

        emit('timeframe-change', {
          offer_id: ref_offer.value.offer_id,
          start: str_to_date(start_str.value),
          end: str_to_date(end_str.value),
        });
      };

      const { start_str, end_str, default_start_str, default_end_str } = use_start_end_dtinputs(
        ref_offer.value.start,
        ref_offer.value.end,
        start_end_callback,
        start_end_callback
      );

      /**
       * Fetches the seat occupation for the given timeframe from the backend and constructs the calendar data.
       */
      fetch_occupation(props.offer.seat_id)
        .then((res_data) => {
          calendar_data.value = occupation_to_calendar_events(res_data);
          calendar_data_loading.value = false;
        })
        .catch((error) => {
          calendar_data_loading.value = false;
          Logger.log(error);
        });

      /**
       * Returns the calendar data to show the occupation for the given seat.
       * Unnecessary as of right now. TODO: remove in refactoring
       */
      const get_calendar_data = computed(() => {
        return calendar_data.value;
      });

      return {
        calendar_data,
        get_calendar_data,
        start_str,
        end_str,
        default_start_str,
        default_end_str,
        is_toast_shown,
        toast_type,
        toast_msg,
        calendar_data_loading,
      };
    },
  };
</script>

<style scoped>
  .timeframe {
    font-size: 1.1em;
    display: grid;
    height: 100%;
    place-items: center;
    gap: 1.6em;
    width: 100%;
    padding: 1.5em 0;
  }

  .timeframe .timeframe-inputs {
    display: flex;
    flex-direction: column;
    gap: 1em;
    font-size: 0.95em;
  }

  .timeframe .calendar-group {
    position: relative;
    width: 70%;
  }

  .btn-wrapper {
    display: flex;
    align-items: end;
    justify-content: center;
  }

  @media (min-width: 900px) {
    .timeframe .timeframe-inputs {
      flex-direction: row;
      gap: 3em;
    }
    .timeframe .occupied-times {
      max-height: 13.3em;
      min-width: 13em;
    }

    .timeframe {
      font-size: 1.4em;
      margin: 0;
      gap: 0.3em;
      gap: 1em;
    }
  }
</style>
