<template>
  <div class="seat-plan">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />
    <div class="content" v-if="!is_loading">
      <header class="header" v-if="current !== null">
        <select class="building-level" @change="on_select">
          <option :value="option.full_name" v-for="(option, index) in data" :key="index">{{ option.full_name }}</option>
        </select>
      </header>

      <div class="card timeframe">
        <h3>Zeitraum auswählen</h3>
        <DateTimeInput name="Start" class="dt-input" v-model="start_str" :default_value="default_start_str" />
        <DateTimeInput name="Ende" class="dt-input" v-model="end_str" :default_value="default_end_str" />
      </div>

      <div class="card img-container" v-if="current !== null && current.level_plan_url !== null">
        <div class="spinner-wrapper" v-if="!svg_loaded">
          <Spinner :is_loading="!svg_loaded" />
        </div>
        <svg
          class="svg-seat-plan"
          :width="current.level_plan_width"
          :height="current.level_plan_height"
          :style="{ visibility: svg_loaded ? 'visible' : 'hidden' }"
        >
          <g id="building-plan-image">
            <image
              :xlink:href="current.level_plan_url"
              :width="current.level_plan_width"
              :height="current.level_plan_height"
              @load="svg_loaded = true"
            />
          </g>
          <g class="occupied-frame" v-if="svg_loaded">
            <rect
              v-for="(seat, index) in get_seats"
              :key="index"
              :class="seat.bookable ? 'available ' : 'occupied'"
              :x="seat.x_pos"
              :y="seat.y_pos"
              :width="seat.width"
              :height="seat.height"
              @click="selected_seat = seat"
            />
          </g>
        </svg>
      </div>
      <h2 v-else class="no-data">Es konnte kein Sitzplan gefunden werden...</h2>

      <div class="card seat-info" v-if="selected_seat !== null">
        <div class="seat-info-header">
          <h3 class="to-seat">{{ selected_seat.full_name }}</h3>
          <p>{{ selected_seat.description }}</p>
          <p>
            {{ selected_seat.owner.username !== '' ? 'Besitzer: ' : '' }}{{ selected_seat.owner.first_name }}
            {{ selected_seat.owner.last_name }}
          </p>
        </div>
        <div class="actions">
          <button
            class="btn-booking"
            @click="$emit('to-booking', { search: selected_seat.full_name, start: start_str, end: end_str })"
          >
            Zu Buchen >
          </button>
        </div>

        <Spinner :is_loading="calendar_loading" />
        <Calendar :items="calendar_data" v-if="!calendar_loading" />
      </div>
    </div>
    <div class="spinner-wrapper" v-else>
      <Spinner :is_loading="is_loading" />
    </div>
  </div>
</template>

<script>
  import { use_start_end_dtinputs, use_toast } from '@/composables/composables';
  import dayjs from 'dayjs';
  import { api_routes } from '@/config';
  import { Logger, fetch_occupation, occupation_to_calendar_events } from '@/util';

  import DateTimeInput from '@/components/general/DateTimeInput.vue';
  import Calendar from '@/components/general/calendar/Calendar.vue';
  import { computed, ref } from '@vue/reactivity';
  import axios from 'axios';
  import { watch } from '@vue/runtime-core';
  import { useRoute } from 'vue-router';
  import Spinner from '@/components/general/Spinner.vue';
  import Toast from '@/components/general/Toast.vue';
  import { use_seat_plan_data } from '@/composables/composables';

  export default {
    name: 'SeatPlanPage',
    components: {
      DateTimeInput,
      Calendar,
      Spinner,
      Toast,
    },
    emits: ['to-booking'],
    setup() {
      const route = useRoute();
      const svg_loaded = ref(false);
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();
      const calendar_loading = ref(true);

      const start_dt = route.query?.start
        ? dayjs(route.query.start)
        : dayjs().add(1, 'days').set('hour', 7).set('minute', 0).set('second', 0).set('millisecond', 0);
      const end_dt = route.query?.end ? dayjs(route.query.end) : start_dt.add(8, 'hours').add(45, 'minutes');

      const { start_str, end_str, default_start_str, default_end_str } = use_start_end_dtinputs(
        start_dt.toDate(),
        end_dt.toDate()
      );

      const data_index = ref(0);
      const booking_data = ref(null);
      const selected_seat = ref(null);

      const { data, is_loading, fetch_seat_plan_data } = use_seat_plan_data();
      fetch_seat_plan_data();

      const current = computed(() => {
        if (data.value === null || data.value?.length === 0) {
          return null;
        }

        return data.value[data_index.value];
      });

      const on_select = (event) => {
        svg_loaded.value = false;
        const value = event.target.value;
        data_index.value = data.value.findIndex((item) => item.full_name === value);
      };

      const fetch_booking_data = async () => {
        try {
          const res = await axios.get(api_routes.booking_list, {
            params: {
              start: start_dt.toISOString(),
              end: end_dt.toISOString(),
            },
          });

          booking_data.value = (await res.data)['fitting'];
        } catch (error) {
          Logger.log(error);
          show_toast('error', 'Fehler beim Laden der Buchungen');
        }
      };

      fetch_booking_data();

      const last_fetches = ref([]);
      watch([start_str, end_str], () => {
        if (last_fetches.value.length > 0) {
          last_fetches.value.forEach((fetch_id) => clearTimeout(fetch_id));
        }

        last_fetches.value.push(
          setTimeout(async () => {
            await fetch_booking_data();
          }, 1000)
        );
      });

      const get_seats = computed(() => {
        if (data.value === null || booking_data.value === null) {
          return [];
        }

        return current.value.seats.map((seat) => {
          const bookable = booking_data.value.findIndex((booking) => booking.seat_id === seat.seat_id);

          return {
            ...seat,
            bookable: bookable !== -1,
          };
        });
      });

      const calendar_data = ref([]);
      if (selected_seat.value !== null) {
        calendar_loading.value = true;
        fetch_occupation(selected_seat.value.seat_id)
          .then((res_data) => {
            calendar_data.value = occupation_to_calendar_events(res_data);
            calendar_loading.value = false;
          })
          .catch((error) => {
            show_toast('error', 'Fehler beim Laden des Kalenders');
            Logger.log(error);
            calendar_loading.value = false;
          });
      }

      watch([start_str, end_str], () => {
        fetch_booking_data();
      });

      watch(selected_seat, () => {
        if (selected_seat.value !== null) {
          calendar_loading.value = true;
          fetch_occupation(selected_seat.value.seat_id)
            .then((res_data) => {
              calendar_data.value = occupation_to_calendar_events(res_data);
              calendar_loading.value = false;
            })
            .catch((error) => {
              show_toast('error', 'Fehler beim Laden des Kalenders');
              Logger.log(error);
              calendar_loading.value = false;
            });
        }
      });

      return {
        start_str,
        end_str,
        default_start_str,
        default_end_str,
        current,
        on_select,
        selected_seat,
        get_seats,
        calendar_data,
        is_loading,
        svg_loaded,
        data,
        is_toast_shown,
        toast_type,
        toast_msg,
        calendar_loading,
      };
    },
  };
</script>

<style scoped>
  .seat-plan {
    width: 90%;
    min-height: 80vh;
    margin: 0 auto;
    background-color: #ebebeb;
    border-radius: 0.3em;
    font-size: 0.9em;
  }

  .no-data {
    text-align: center;
    font-size: 1.3em;
    font-weight: 500;
    color: #616161;
    margin: 2em auto;
  }

  .seat-plan .content {
    display: grid;
    justify-items: center;
    gap: 1em;
    padding-bottom: 1em;
  }

  .available {
    fill: #7bd3ff;
    opacity: 0.4;
    cursor: pointer;
  }

  .available:hover {
    fill: #1bb3ff;
  }

  .occupied {
    fill: transparent;
  }

  .occupied:hover {
    fill: #ff8e5d;
    opacity: 0.4;
    cursor: pointer;
  }

  .seat-plan .content .header {
    background-color: #e0e0e0;
    border-radius: 0.3em;
    padding: 1em;
    width: 100%;

    display: flex;
    align-items: center;
    gap: 0.8em;
    justify-content: center;
  }

  .seat-plan .content .header .building-level {
    font-size: 0.9em;
    font-weight: 500;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .seat-plan .content .header .building-level:hover {
    color: #555;
  }

  .seat-plan .content .header .icon {
    color: #555;
    font-size: 0.95em;
    cursor: pointer;
  }

  .seat-plan .content .header .icon:hover {
    color: #9d9d9d;
  }

  .seat-plan .content .card {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 0.3em;
    padding: 1em 1em 1.5em 1em;
    width: 90%;
  }

  .seat-plan .content .card.timeframe {
    display: grid;
    justify-items: center;
    gap: 0.5em;

    font-size: 1.2em;
  }

  .seat-plan .content .card.timeframe h3 {
    font-size: 0.8em;
    font-weight: 500;

    /* grid-column: span 2; */
  }

  .seat-plan .content .card.img-container {
    overflow: auto;
  }

  .seat-plan .content .card.img-container img {
    max-width: 100%;
  }

  /* .seat-plan .content .card.seat-info {
    display: none;
  } */

  .seat-plan .content .card.seat-info .actions .btn-booking {
    font-size: 0.82em;
    color: #ff7f48;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 0 1.3em;
    text-decoration: none;
  }

  .seat-plan .content .card.seat-info .actions .btn-booking:hover {
    color: #ff4d00;
  }

  .seat-plan .content .card.seat-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .seat-plan .content .card.seat-info .seat-info-header {
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* .seat-plan .content .card.seat-info .seat-info-header:hover {
    text-decoration: underline;
  } */

  .seat-plan .content .card.seat-info .seat-info-header .to-seat {
    font-weight: 500;
    border: none;
    background-color: transparent;
    font-size: 1em;
  }

  .seat-plan .content .card.seat-info .seat-info-header p {
    color: #858585;
    font-size: 0.75em;
  }

  .seat-plan .content .card.seat-info .seat-calendar {
    font-size: 0.9em;
    width: 80%;
  }

  .seat-plan .content .card.seat-info .actions {
    width: 100%;

    display: flex;
    justify-content: space-evenly;
  }

  @media (min-width: 950px) {
    .seat-plan .content .card.seat-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2.5em;
    }

    .seat-plan .content .card.seat-info .seat-info-header {
      text-decoration: none;

      display: flex;
      flex-direction: column;
      align-items: center;
    }

    /* .seat-plan .content .card.seat-info .seat-info-header:hover {
      text-decoration: underline;
    } */

    .seat-plan .content .card.seat-info .seat-info-header .to-seat {
      font-weight: 500;
      border: none;
      background-color: transparent;
      font-size: 1em;
    }

    .seat-plan .content .card.seat-info .seat-info-header p {
      color: #858585;
      font-size: 0.75em;
    }

    .seat-plan .content .card.seat-info .seat-calendar {
      font-size: 0.9em;
      width: 80%;
    }

    .seat-plan .content .card.seat-info .actions {
      width: 100%;

      display: flex;
      justify-content: space-evenly;
    }
  }

  @media (min-width: 1275px) {
    .seat-plan .content .card.timeframe {
      grid-template-columns: 1fr 1fr;
    }

    .seat-plan .content .card.timeframe h3 {
      grid-column: span 2;
    }
  }

  @media (min-width: 1450px) {
    .seat-plan .content {
      grid-template-columns: 1fr 1fr;
    }

    .seat-plan .content .header {
      grid-column: span 2;
    }

    .seat-plan .content .card.img-container {
      grid-column: 1;
      grid-row: 2 / 4;
    }
  }
</style>
