<template>
  <div class="own-seat">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />

    <header class="header" v-if="!loading">
      <div />
      <div class="seat">
        <h2 class="name">{{ data.full_name }}</h2>
        <p class="descr">{{ data.description }}</p>
      </div>
      <button class="occupation desktop" @click="on_show_overlay('Belegung')" v-if="!loading">Belegung ></button>
    </header>

    <div class="content" v-if="!loading">
      <div class="release-seat">
        <div class="actions-group">
          <button class="occupation mobile" @click="on_show_overlay('Belegung')">Belegung ></button>
          <Button class="btn-release-seat" @click="on_show_overlay('Freigabe')">Freigeben</Button>
        </div>

        <table class="release-seat-table">
          <thead v-if="data.released_times.length !== 0">
            <tr>
              <th></th>
              <th>Start</th>
              <th>Ende</th>
              <th>Belegt</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data.released_times" :key="index">
              <td><Icon class="icon remove" icon="gg:remove" @click="on_delete_release(item.pk)" /></td>
              <td>{{ date_to_str(item.start) }}</td>
              <td>{{ date_to_str(item.end) }}</td>
              <td><Icon class="icon yes" icon="line-md:confirm-circle" /></td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Seat Plan image and Spinner loader -->
      <div class="seat-plan" v-if="data?.pk && data.pk !== ''">
        <div class="spinner-wrapper" v-if="seat_plan_loading">
          <Spinner :is_loading="seat_plan_loading" />
        </div>
        <SeatPreview :seat_ids="[data.pk]" @loaded="seat_plan_loading = false" />
      </div>
    </div>

    <div class="spinner-wrapper" v-if="loading">
      <Spinner :is_loading="loading" />
    </div>

    <!-- Realease seat overlay. Not shown initially -->
    <div class="overlay-wrapper">
      <OwnSeatOverlay
        v-if="show_overlay"
        :start_page="menu_item"
        :seat="data"
        @close-overlay="show_overlay = false"
        @release="on_release"
      />
    </div>
  </div>
</template>

<script>
  import { Logger, date_to_str, are_some_between, fetch_occupation } from '@/util';
  import { api_routes } from '@/config';

  import { Icon } from '@iconify/vue';

  import Button from '@/components/general/Button.vue';
  import axios from 'axios';
  import { ref } from '@vue/reactivity';
  import OwnSeatOverlay from '@/components/view-members/reservation/OwnSeatOverlay.vue';
  import SeatPreview from '@/components/general/SeatPreview.vue';
  import Spinner from '@/components/general/Spinner.vue';
  import Toast from '@/components/general/Toast.vue';
  import { use_toast } from '@/composables/composables';
  import dayjs from 'dayjs';

  /**
   * This component is the view for a single owned seat.
   * It shows where the seat is in the seat plan and you can see the occupation.
   * As well as the possiblilty to release the seat and cancel releases.
   */
  export default {
    name: 'OwnSeat',
    components: {
      Icon,
      Button,
      OwnSeatOverlay,
      SeatPreview,
      Spinner,
      Toast,
    },
    setup() {
      // own seat data
      const data = ref({
        pk: '',
        full_name: '',
        description: '',
        is_favorite: '',
        released_times: [],
      });

      const loading = ref(false);
      const seat_plan_loading = ref(true);

      const show_overlay = ref(false);
      const menu_item = ref('Freigabe');
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      /**
       * Show overlay with selected menu item.
       */
      const on_show_overlay = (item) => {
        menu_item.value = item;
        show_overlay.value = true;
      };

      /**
       * Fetch own seat data from backend and fill the data Ref
       */
      const fetch_own_seat_data = async () => {
        try {
          loading.value = true;
          const res = await axios.get(api_routes.own_seat);

          data.value = await res.data;
        } catch (error) {
          Logger.log(error);
          show_toast('error', 'Fehler beim Laden der Belegung');
        } finally {
          loading.value = false;
        }
      };

      fetch_own_seat_data();

      /**
       * Event when seat release request was successful and show toast message.
       * After that refetch own seat data.
       */
      const on_release = () => {
        show_overlay.value = false;
        show_toast('success', 'Platz wurde freigegeben');
        fetch_own_seat_data();
      };

      /**
       * Delete own seat release item.
       * @param {number} release_id - id of the own seat release item
       */
      const on_delete_release = async (release_id) => {
        try {
          const occupation = await fetch_occupation(data.value.pk);
          const find_index = data.value.released_times.findIndex((item) => item.pk === release_id); // check if item exists in server data
          Logger.log('find-index', find_index);

          if (find_index === -1) {
            Logger.log('Freigabe Ereignis konnte nicht gefunden werden');
            show_toast('error', 'Freigabe Ereignis konnte nicht gefunden werden');
            return;
          }

          const { start, end } = data.value.released_times[find_index];

          // give user hint if there are reservations for that release item
          if (
            occupation.some((item) => {
              return are_some_between(
                dayjs(start),
                dayjs(end),
                item.reservation_items.map((item) => ({ start: item.start, end: item.end }))
              );
            })
          ) {
            const proceed = confirm(
              'Es fallen Reservierungen in den angegebenen Zeitraum. Diese werden storiert. Möchten Sie fortfahren?'
            );

            if (!proceed) return;
          }

          // request deletion from server
          const res = await axios.post(api_routes.delete_release, {
            release_id: release_id,
          });

          const res_data = await res.data;

          if (res_data.status === 'success') {
            Logger.log('Delete Release successful');
            data.value.released_times = data.value.released_times.filter((item) => item.pk !== release_id); // remove item from UI
          }
        } catch (error) {
          Logger.log(error);
          show_toast('error', 'Fehler beim Löschen der Freigabe');
        }
      };

      return {
        data,
        date_to_str,
        show_overlay,
        on_show_overlay,
        menu_item,
        on_release,
        on_delete_release,
        loading,
        seat_plan_loading,
        is_toast_shown,
        toast_type,
        toast_msg,
      };
    },
  };
</script>

<style scoped>
  .own-seat {
    width: 90%;
    margin: 0 auto;
    font-size: 1em;
    background-color: #ebebeb;
    width: 90%;
    border-radius: 0.3em;
    min-height: 80vh;
  }

  .own-seat .header {
    background-color: #e0e0e0;
    border-radius: 0.3em;
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    padding: 0.625em;
  }

  .own-seat .header .seat {
    text-align: center;
  }

  .own-seat .header .seat .name {
    font-size: 1.031em;
    font-weight: 500;
  }

  .own-seat .header .seat .descr {
    font-size: 0.714em;
    color: #858585;
  }

  .own-seat .occupation {
    color: #ff7f48;
    font-size: 0.739em;
    border: none;
    background-color: transparent;
    cursor: pointer;
    justify-self: end;
  }

  .own-seat .occupation.desktop {
    display: none;
  }

  .own-seat .occupation:hover {
    color: #ff6a2b;
  }

  .own-seat .content {
    display: grid;
    grid-auto-flow: row;
    align-items: center;
  }

  .overlay-wrapper {
    font-size: 1rem;
  }

  .own-seat .content .release-seat {
    align-self: start;
    width: 100%;
    padding: 1.5em;
  }

  .own-seat .content .release-seat .actions-group {
    display: flex;
    gap: 1.4em;
    justify-content: center;
    margin: 1em 0;
  }

  .own-seat .content .release-seat .actions-group .btn-release-seat {
    font-size: 0.75em;
  }

  .own-seat .content .release-seat .release-seat-table {
    width: 100%;
    font-size: 0.9em;
    border-collapse: separate;
    border-spacing: 0 0.6em;
  }

  .own-seat .content .release-seat .release-seat-table tbody {
    color: #b5b5b5;
  }

  .own-seat .content .release-seat .release-seat-table thead tr th {
    color: #9d9d9d;
    font-weight: normal;
    font-size: 0.8em;
    text-align: start;
  }

  .own-seat .content .release-seat .release-seat-table td {
    text-align: start;
    padding: 0.5em 0.7em;
  }

  .own-seat .content .release-seat .release-seat-table tbody tr {
    border-radius: 0.3em;
    font-size: 0.85em;
  }

  .own-seat .content .release-seat .release-seat-table tbody tr td {
    background-color: #fff;
  }

  .own-seat .content .release-seat .release-seat-table tbody tr td:first-child {
    padding-left: 0.5em;
    border-radius: 0.4em 0 0 0.4em;
    background-color: #fff;
  }

  .own-seat .content .release-seat .release-seat-table tbody tr td:last-child {
    border-radius: 0 0.4em 0.4em 0;
    background-color: #fff;
  }

  .own-seat .content .release-seat .release-seat-table tbody tr td .icon {
    position: relative;
    top: 0.2em;
  }

  .own-seat .content .release-seat .release-seat-table tbody tr td .icon.remove {
    color: #ffa57d;
    cursor: pointer;
  }

  .own-seat .content .release-seat .release-seat-table tbody tr td .icon.yes {
    color: #58cf88;
  }

  .own-seat .content .seat-plan {
    overflow: auto;
    width: 95%;
  }

  @media (min-width: 960px) {
    .own-seat .content {
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      align-items: center;
    }

    .own-seat .occupation.desktop {
      display: block;
    }

    .own-seat .occupation.mobile {
      display: none;
    }

    .own-seat .content .release-seat .btn-release-seat {
      margin: 3.5em auto 2em auto;
    }
  }
</style>
