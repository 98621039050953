<template>
  <div class="seat-card">

    <!-- Favorite toggle desktop -->
    <FavoriteIcon :is_favorite="seat.is_favorite" class="icon-desktop" @toggle="$emit('toggle-favorite', seat.pk)" />
    <div class="header-group">
      <div class="mobile" />

      <!-- Seat information -->
      <div class="seat-info">
        <h3>{{ seat.full_name }}</h3>
        <p>{{ seat.description }}</p>
        <p v-if="seat?.owner">Besitzer: {{ seat.owner }}</p>
      </div>

      <!-- Favorite toggle mobile -->
      <FavoriteIcon :is_favorite="seat.is_favorite" class="icon-mobile" @toggle="$emit('toggle-favorite', seat.pk)" />
    </div>

    <!-- Overlay actions & booking page link -->
    <div class="actions-group">
      <button class="action" @click="on_show_overlay('Belegung')">Belegung ></button>
      <button class="action" @click="on_show_overlay('Sitzplan')">Sitzplan ></button>
      <router-link :to="{ name: 'Booking', params: { search: seat.full_name } }" class="action"
        >Zu Buchen ></router-link
      >
    </div>

    <!-- Overlay -->
    <SeatListOverlay :start_page="start_page" :seat="seat" v-if="show_overlay" @close-overlay="show_overlay = false" />
  </div>
</template>

<script>
  import FavoriteIcon from '@/components/general/FavoriteIcon.vue';
  import SeatListOverlay from '@/components/view-members/seat-lists/SeatListOverlay.vue';
  import { ref } from '@vue/reactivity';

  /**
   * Component serving as list item point to display information about a seat.
   * Also an overlay is included to show:
   *   - Belegung - occupation of the seat
   *   - Sitzplan - seat preview image
   * To option to switch to the booking page with this seat as a filter is included too.
   * 
   * Emits:
   *   - toggle-favorite - toggle favorite status of the seat
   */
  export default {
    name: 'SeatCard',
    components: {
      FavoriteIcon,
      SeatListOverlay,
    },
    props: {
      seat: {
        // seat to display information for
        type: Object,
        required: true,
        /*
          {
            pk: number,
            full_name: string,
            description: string,
            is_favorite: boolean
          }
        */
      },
    },
    emits: ['toggle-favorite'],
    setup() {
      const show_overlay = ref(false); // flag to indicate whether the overlay should be shown
      const start_page = ref('Belegung'); // start page of the overlay

      /**
       * Show overlay.
       * @param {string} item - start page of the overlay to show
       */
      const on_show_overlay = (item) => {
        show_overlay.value = true;
        start_page.value = item;
      };

      return { show_overlay, start_page, on_show_overlay };
    },
  };
</script>

<style scoped>
  .seat-card {
    font-size: 0.85em;
    background-color: #fff;
    padding: 0.6em 0.6em 1.2em 0.6em;
    border-radius: 0.4em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);

    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 2.2em;
    height: 100%;
    max-height: 14.706em;
    width: clamp(21.324em, 100%, 25em);
  }

  .seat-card .header-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .icon-desktop {
    display: none;
    font-size: 2.2em;
  }

  .seat-card .header-group .seat-info {
    display: flex;
    flex-direction: column;
    place-items: center;
    font-size: 0.9em;
  }

  .seat-card .header-group .seat-info h3 {
    font-weight: 500;
    /* cursor: pointer; */
  }

  /* .seat-card .header-group .seat-info h3:hover {
    color: #ff7f48;
  } */

  .seat-card .header-group .seat-info p {
    font-size: 0.8em;
    color: #9d9d9d;
  }

  .seat-card .actions-group {
    width: 90%;
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
  }

  .seat-card .actions-group .action {
    font-size: 0.75em;
    color: #ff7f48;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
  }

  .seat-card .actions-group .action:hover {
    color: #ff4d00;
  }

  @media (min-width: 800px) {
    .seat-card {
      flex-direction: row;
      width: 100%;
      max-height: unset;
      height: unset;
      max-width: 50em;
      justify-content: space-between;
      padding: 0.8em 1.2em;
    }

    .icon-mobile {
      display: none;
    }

    .icon-desktop {
      display: block;
    }

    .seat-card .header-group {
      justify-content: center;
    }

    .seat-card .actions-group {
      justify-content: space-evenly;
    }
  }
</style>
