<template>
  <div class="seat-plan-container" ref="svg_container">
    <svg
      class="svg-seat-plan"
      :width="data[0].level_plan_width"
      :height="data[0].level_plan_height"
      v-if="data !== null && data.length > 0"
    >
      <!-- Actual image of the seat plan -->
      <g id="building-plan-image">
        <image
          :xlink:href="data[0].level_plan_url"
          :width="data[0].level_plan_width"
          :height="data[0].level_plan_height"
          @load="on_image_load"
        />
      </g>

      <!-- Rectangle to mark each seat location -->
      <g id="seat-location-frame" v-if="image_loaded">
        <rect
          :key="itm.seat_id"
          v-for="itm in data"
          class="seat-location"
          :x="itm.seat_location.x_pos"
          :y="itm.seat_location.y_pos"
          :width="itm.seat_location.width"
          :height="itm.seat_location.height"
        />
      </g>
    </svg>
  </div>
</template>

<script>
  import axios from 'axios';
  import { ref } from '@vue/reactivity';

  import { DEBUG, api_routes } from '@/config';
  import { Logger } from '@/util';
  import { useStore } from 'vuex';

  import { get_image_dimensions, get_new_seat_location } from '@/util';

  /**
   * Seat preview component only for displaying the actual seat plan image and the passed seat locations.
   */
  export default {
    name: 'SeatPreview',
    props: {
      seat_ids: {
        type: Array,
        required: true,
      },
    },
    emits: ['loaded'],
    setup(props, { emit }) {
      /*
      Create ref for seat plan data
      Data: {
        seat_id: number,
        level_full_name: string,
        level_plan_url: string,
        level_plan_width: number,
        level_plan_height: number,
        seat_location: {
          pk: number,
          x_pos: number,
          y_pos: number,
          width: number,
          height: number,
        },
      }
      */
      const data = ref(null);
      const image_loaded = ref(false); // Flag to indicate if image has been loaded
      const svg_container = ref(null); // Reference to the svg html seat plan container

      const store = useStore();

      /**
       * Load the seat plan data for a single seat and ajust the url in order to work with dev configuration.
       * @deprecated - the url change for dev config
       * @param {number} seat_id - The seat id to load the seat plan & location data for.
       * @returns {Promise} - Promise to return the seat plan data for the seat.
       */
      const fetch_preview_data = async (seat_id) => {
        try {
          const res = await axios.get(api_routes.seat_preview, {
            params: { seat_id: seat_id },
          });

          const res_data = await res.data;
          if (DEBUG && Object.keys(res_data).includes('level_plan_url')) {
            const base_url =
              res_data.level_plan_url[0] === '/'
                ? axios.defaults.baseURL.substring(0, axios.defaults.baseURL.length - 1)
                : axios.defaults.baseURL;
            res_data.level_plan_url = `${base_url}${res_data.level_plan_url}`;
          }

          res_data.level_plan_url = `${res_data.level_plan_url}?token=${store.getters.auth_token}`;

          return res_data;
        } catch (error) {
          Logger.log(error);
        }
      };

      /**
       * Load event handler for the seat plan image.
       * Emit the loaded event when the image has been loaded.
       */
      const on_image_load = () => {
        image_loaded.value = true;
        emit('loaded');
      };

      /**
       * Alter the data in order to change image dimensions to fit the current screen size & set the data ref to the new data.
       */
      (async () => {
        data.value = [];
        for (const itm of props.seat_ids) {
          const data_itm = await fetch_preview_data(itm);

          // calculate and modify seat location to fit webpage scale
          const location = get_new_seat_location(
            {
              x: data_itm.seat_location.x_pos,
              y: data_itm.seat_location.y_pos,
              width: data_itm.seat_location.width,
              height: data_itm.seat_location.height,
            },
            { width: data_itm.level_plan_width, height: data_itm.level_plan_height },
            svg_container.value
          );

          const image_dims = get_image_dimensions(
            { width: data_itm.level_plan_width, height: data_itm.level_plan_height },
            svg_container.value
          );

          data.value.push({
            ...data_itm,
            level_plan_width: image_dims.width,
            level_plan_height: image_dims.height,
            seat_location: {
              x_pos: location.x,
              y_pos: location.y,
              width: location.width,
              height: location.height,
            },
          });
        }
      })();

      return { data, on_image_load, image_loaded, svg_container };
    },
  };
</script>

<style scoped>
  .seat-plan-container {
    padding: 0.5em;
    width: 100%;
    height: 100%;
  }

  .svg-seat-plan {
    display: block;
    margin: 0 auto;
  }

  .seat-location {
    fill: #ff8e5d;
    opacity: 0.4;
  }
</style>
