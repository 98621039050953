<template>
  <div class="overlay">
    <!-- Header slot -->
    <div class="card" v-click-away="close_overlay">
      <header class="header">
        <slot name="header"></slot>
        <Icon icon="akar-icons:cross" class="close-icon" @click="close_overlay" />
      </header>

      <!-- Menu slot - optional use -->
      <div class="menu">
        <slot name="menu"></slot>
      </div>

      <!-- Content section -->
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import { Icon } from '@iconify/vue';

  /**
   * Base overlay component to create an slot structure to be used in the implementation of specific overlays.
   */
  export default {
    name: 'Overlay',
    components: {
      Icon,
    },
    emits: ['close-overlay'],
    setup(props, { emit }) {
      /**
       * Emit close overlay event.
       */
      const close_overlay = () => {
        emit('close-overlay');
      };

      return { close_overlay };
    },
  };
</script>

<style scoped>
  .overlay {
    --card-height: 85vh;
    --border-radius: 0.5em 0.5em 0 0;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }

  .overlay .card {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: var(--card-height);
    background-color: #fff;
    border-radius: var(--border-radius);
    display: grid;
    grid-template-rows: auto auto 1fr;
    gap: 1.5em;
    max-width: 1300px;
  }

  .overlay .card .header {
    background-color: #f6f6f6;
    border-radius: var(--border-radius);
    padding: 0.3em 0.5em;
    position: relative;
  }

  .overlay .card .header .close-icon {
    color: #555;
    position: absolute;
    right: 0.7em;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .overlay .card .header .close-icon:hover {
    color: #858585;
  }

  .overlay .card .content {
    background-color: #f6f6f6;
    overflow-y: auto;
    width: 95%;
    margin: 0 auto;
    padding: 0.3em 0.5em;
    border-radius: var(--border-radius);
  }

  @media (min-width: 650px) {
    .overlay .card {
      width: 90%;
      height: 95%;
      bottom: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
      border-radius: 0.5em;
    }

    .overlay .card .header .close-icon {
      right: 1em;
    }
  }
</style>
