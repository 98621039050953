<template>
  <div class="icon-button">
    <Icon :icon="icon_id" />
  </div>
</template>

<script>
  import { Icon } from '@iconify/vue';

  /**
   * Div to be used as icon button.
   * But click event handler must be set on the parent.
   */
  export default {
    name: 'IconButton',
    components: {
      Icon,
    },
    props: {
      icon_id: {
        // name of the icon to show according to @iconify/icons
        type: String,
        required: true,
      },
    },
  };
</script>

<style scoped>
  .icon-button {
    font-size: 0.7em;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    border: none;
    background-color: #ff8e5d;
    color: #f6f6f6;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04));
    cursor: pointer;
    width: fit-content;
  }

  .icon-button:hover {
    background-color: #ff783d;
  }
</style>
