<template>
  <div class="root">
    <Navbar />
    <div class="splitter-image" />

    <div class="container">
      <!-- Todays occupation -->
      <div class="card">
        <DayPieChart :date="today" title="Heutige Belegung" />
      </div>

      <!-- Tomorrows occupation -->
      <div class="card">
        <DayPieChart :date="tomorrow" title="Morgige Belegung" />
      </div>

      <!-- Calendar for month view -->
      <div class="card calendar">
        <h2 class="title">Belegungs Kalender</h2>
        <p class="descr">
          In diesem Kalendar wird die Platzbelegung in Prozent dargestellt. Es zählen keine Plätze mit Besitzer in diese
          Statistik ein, nur solche die buchbar sind (ohne Besitzer, freigegeben). Beim Klick auf einen Tag wird die
          eine genauere Statistik mit Gebäudeplan angezeigt.
        </p>

        <StatsCalendar />
      </div>
    </div>
  </div>
</template>

<script>
  import Navbar from '@/components/general/Navbar.vue';
  import StatsCalendar from './StatsCalendar.vue';
  import DayPieChart from './DayPieChart.vue';
  import dayjs from 'dayjs';

  /**
   * Main view for the statistics page.
   * This page is for a better overview of seat occupation over the entire location.
   * This contains pie charts for today and tommorow as well as a calendar view for the entire month.
   */
  export default {
    components: { Navbar, StatsCalendar, DayPieChart },
    setup() {
      return {
        today: dayjs(),
        tomorrow: dayjs().add(1, 'day'),
      };
    },
  };
</script>

<style scoped>
  .container {
    width: 95%;
    margin: 1rem auto 0 auto;
    display: grid;
    gap: 0.7rem;
    grid-template-columns: 1fr 1fr;
  }

  .card {
    font-size: 1.1em;
    background-color: #ebebeb;
    padding: 1em;
    border-radius: 0.3em;
  }

  .calendar {
    grid-column: span 2;
  }

  .title {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .descr {
    font-size: 0.8rem;
    color: #6b6b6b;
    margin-bottom: 1rem;
  }
</style>
