<template>
  <div class="notification-card">
    <Icon icon="feather:mail" class="notification-icon" />
    <div class="vertical-line" />

    <!-- Title of notification -->
    <div class="header-group">
      <h3>Mitteilung von {{ notification.nsender.first_name }} {{ notification.nsender.last_name }}</h3>
      <p>{{ date_to_str(notification.time_stamp) }}</p>
    </div>
    <Icon icon="dashicons:remove" class="remove-icon" @click="$emit('remove-notification', notification)" />

    <!-- Message content -->
    <p class="message-text">
      {{ notification.message }}
    </p>
  </div>
</template>

<script>
  import { Icon } from '@iconify/vue';
  import { date_to_str } from '@/util';

  /**
   * Display card of a single notification
   * Emits:
   *   - remove-notification: Emitted when the remove icon is clicked
   */
  export default {
    name: 'NotificationCard',
    components: {
      Icon,
    },
    props: {
      notification: {
        type: Object,
        required: true,
        /*
          {
            pk: number,
            message: string,
            is_read: boolean,
            time_stamp: Date,
            nsender: {
              pk: number,
              username: string,
              first_name: string,
              last_name: string,
            }
          }
        */
      },
    },
    emits: ['remove-notification'],
    setup() {
      return { date_to_str };
    },
  };
</script>

<style scoped>
  .notification-card {
    background-color: #fff;
    padding: 1em 1.5em 1em 2em;
    border-radius: 0.3em;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.07);

    position: relative;

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, auto);
    align-items: start;
    justify-content: start;
    gap: 1em;
  }

  .notification-card .notification-icon {
    color: #ffa57d;
    font-size: 1.8em;
  }

  .notification-card .vertical-line {
    height: 100%;
    border-right: 1px solid #cecece;
  }

  .notification-card .message-text {
    color: #939393;
    font-size: 0.7em;
  }

  .notification-card .remove-icon {
    color: #ffa57d;
    cursor: pointer;
    font-size: 0.9em;

    position: absolute;
    right: 0.3em;
    top: 0.3em;
  }

  .notification-card .remove-icon:hover {
    color: #ff7235;
  }

  .notification-card .header-group h3 {
    font-size: 0.85em;
    font-weight: 500;
    color: #575757;
  }

  .notification-card .header-group p {
    font-size: 0.6em;
    color: #a3a3a3;
  }

  .notification-card .confirm-group .confirm-icon {
    color: #58cf88;
    font-size: 1em;
    cursor: pointer;
  }

  .notification-card .confirm-group .confirm-icon:hover {
    color: #08a848;
  }

  .notification-card .confirm-group .reject-icon {
    color: #ff6c6c;
    font-size: 0.9em;
    cursor: pointer;
  }

  .notification-card .confirm-group .reject-icon:hover {
    color: #ff2222;
  }
</style>
