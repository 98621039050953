<template>
  <Overlay>
    <!-- Header information -->
    <template v-slot:header>
      <div class="booking-header">
        <div class="main-header">
          <Icon
            icon="akar-icons:arrow-left"
            class="icon"
            @click="$emit('prev-offer', { offer_id: offer.offer_id, page: active_menu_item })"
          />

          <h2 class="seat-name">{{ offer.seat_name }}</h2>

          <Icon
            icon="akar-icons:arrow-right"
            class="icon"
            @click="$emit('next-offer', { offer_id: offer.offer_id, page: active_menu_item })"
          />
        </div>

        <p class="descr">{{ offer.description }}</p>
      </div>
    </template>

    <!-- Menu items -->
    <template v-slot:menu>
      <SliderMenu
        :menu_items="['Zeitraum', 'Belegung', 'Sitzplan']"
        class="overlay-menu"
        :active_start="active_menu_item"
        @menu-click="on_menu_click"
      />
    </template>

    <!-- Overlay pages -->
    <template v-slot:content>
      <Timeframe
        v-if="active_menu_item === 'Zeitraum'"
        @to-booking="$emit('to-booking')"
        :offer="offer"
        @timeframe-change="(params) => $emit('timeframe-change', params)"
      />

      <SeatOccupation v-if="active_menu_item === 'Belegung'" :seat_id="offer.seat_id" />
      <div class="spinner-wrapper" v-if="active_menu_item === 'Sitzplan' && seat_preview_loading">
        <Spinner :is_loading="seat_preview_loading" />
      </div>

      <SeatPreview
        v-if="active_menu_item === 'Sitzplan'"
        :seat_ids="[offer.seat_id]"
        @loaded="seat_preview_loading = false"
      />
    </template>
  </Overlay>
</template>

<script>
  import { ref } from 'vue';
  import { Icon } from '@iconify/vue';

  import Overlay from '@/components/general/Overlay.vue';
  import SliderMenu from '@/components/general/SliderMenu.vue';
  import Timeframe from '@/components/view-members/booking/Timeframe.vue';
  import SeatOccupation from '@/components/view-members/booking/SeatOccupation.vue';
  import SeatPreview from '@/components/general/SeatPreview.vue';
  import Spinner from '@/components/general/Spinner.vue';

  /**
   * Booking overlay component.
   * This component is used to display information about a seat and offer.
   * Sub-pages:
   *   - Timeframe: Select timeframe to book seat.
   *   - SeatOccupation: Show seat occupation.
   *   - SeatPreview: Show seat preview image with the seat marked.
   * Emits:
   *   - to-booking: Go to booking form.
   *   - timeframe-change: Emitted when the timeframe is changed.
   *   - prev-offer: Emitted when the left arrow for the previous offer is clicked
   *   - next-offer: Emitted when the right arrow for the next offer is clicked
   */
  export default {
    name: 'BookingOverlay',
    components: {
      Icon,
      Overlay,
      SliderMenu,
      Timeframe,
      SeatOccupation,
      SeatPreview,
      Spinner,
    },
    props: {
      start_page: {
        // The start page of the overlay
        type: String,
        default: 'Zeitraum',
      },
      offer: {
        type: Object,
        required: true,
        /*
          {
            offer_id: number,
            seat_id: number,
            seat_name: string,
            description: string,
            is_favorite: boolean,
            start: Date,
            end: Date
          }
        */
      },
    },
    emits: ['to-booking', 'timeframe-change', 'prev-offer', 'next-offer'],
    setup(props) {
      const active_menu_item = ref(props.start_page); // The active menu item of the overlay
      const seat_preview_loading = ref(true); // loading flag for the seat preview

      /**
       * Select a menu item to open a sub-overlay page
       * @param {string} menu_item The menu item that was clicked
       */
      const on_menu_click = (item) => {
        active_menu_item.value = item;

        if (item === 'Sitzplan') {
          seat_preview_loading.value = true;
        }
      };

      return { active_menu_item, on_menu_click, seat_preview_loading };
    },
  };
</script>

<style scoped>
  .booking-header {
    text-align: center;
  }

  .booking-header .main-header {
    display: flex;
    gap: 0.7em;
    align-items: center;
    justify-content: center;
  }

  .booking-header .main-header .icon {
    color: #555;
    cursor: pointer;
  }

  .booking-header .main-header .icon:hover {
    color: #858585;
  }

  .booking-header .main-header .seat-name {
    font-size: 0.938em;
    font-weight: 500;
  }

  .booking-header .descr {
    color: #9d9d9d;
    font-size: 0.625em;
  }

  .overlay-menu {
    font-size: 0.9em;
    margin: 0 auto;
  }

  @media (min-width: 650px) {
    .booking-header {
      font-size: 1.2em;
    }

    .overlay-menu {
      font-size: 1em;
    }
  }
</style>
