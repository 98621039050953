import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';

import Login from '@/views/Login.vue';
import MailReset from '@/views/MailReset.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import Booking from '@/views/Booking.vue';
import Reservations from '@/views/reservations/Reservations.vue';
import SeatLists from '@/views/seat-lists/SeatListPage.vue';
import Profile from '@/views/Profile.vue';
import Statistics from '@/views/statistics/Statistics.vue';
import Notifications from '@/views/Notifications.vue';

/**
 * Routes for the application.
 * Mostly used in the navigation bar to reach the different pages.
 */
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/mail-reset/',
    name: 'MailReset',
    component: MailReset,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/passwort-reset/',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/buchen/',
    name: 'Booking',
    component: Booking,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reservierungen/',
    name: 'Reservations',
    component: Reservations,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/platzlisten/',
    name: 'SeatLists',
    component: SeatLists,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/statistik/',
    name: 'Statistics',
    component: Statistics,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profil/',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mitteilungen/',
    name: 'Notifications',
    component: Notifications,
    meta: {
      requiresAuth: true,
    },
  },
];

/**
 * Router for the application.
 */
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

/**
 * Router logic to handle before each page change. Here is check if the user has access to the pages where authentication is required.
 * Actions performed:
 *   - Check if the user is logged in. If not, redirect to the login page. Else, redirect to the booking page.
 *   - Check if the authorization token is valid. If not, redirect to the login page.
 */
router.beforeEach((to, from, next) => {
  if (!routes.some((route) => route.name === to.name)) {
    // If logged in, redirect to the booking page. Else, redirect to the login page.
    next({ name: store.getters.is_logged_in ? 'Booking' : 'Login' });
  } else if (to.meta.requiresAuth) {
    // verify token to protect against token corruption in localStorage
    store.dispatch('verify_token').then(() => {
      if (store.getters.is_logged_in) {
        next();
      } else {
        next({ name: 'Login' });
      }
    });
  } else if (!to.meta.requiresAuth && store.getters.is_logged_in) {
    next({ name: 'Booking' });
  } else {
    next();
  }
});

export default router;
