<template>
  <div class="reservations">
    <Navbar />
    <div class="splitter-image" />
    <div class="slider-menu-wrapper">
      <SliderMenu
        v-if="has_own_seat"
        :menu_items="['Reservierungen', 'Eigener Platz']"
        :active_start="menu_item"
        @menu-click="(item) => (menu_item = item)"
      />
    </div>
    <SeatReservations v-if="menu_item === 'Reservierungen'" />
    <OwnSeat v-if="menu_item === 'Eigener Platz'" />
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />
  </div>
</template>

<script>
  import { ref } from 'vue';

  import { api_routes } from '@/config';
  import { Logger } from '@/util';
  import axios from 'axios';
  import { use_toast } from '@/composables/composables';

  import Navbar from '@/components/general/Navbar.vue';
  import SliderMenu from '@/components/general/SliderMenu.vue';
  import SeatReservations from '@/views/reservations/SeatReservations.vue';
  import OwnSeat from '@/views/reservations/OwnSeat.vue';
  import Toast from '@/components/general/Toast.vue';

  /**
   * Parent component for all reservation releated categories.
   * This includes Reservierungen (reservations) and Eigener Platz (own seat).
   */
  export default {
    name: 'Reservations',
    components: {
      Navbar,
      SliderMenu,
      SeatReservations,
      OwnSeat,
      Toast,
    },
    setup() {
      const menu_item = ref('Reservierungen');

      const has_own_seat = ref(false);

      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      /**
       * Function to check if the user has an own seat, which will then set the flag to be able to show the own seat page.
       */
      const fetch_if_has_own_seat = async () => {
        try {
          const res = await axios.get(api_routes.has_own_seat);

          const data = await res.data;

          has_own_seat.value = data.has_own_seat;
        } catch (error) {
          Logger.error(error);
          show_toast('error', 'Verbindungsfehler');
        }
      };
      fetch_if_has_own_seat();

      return { menu_item, has_own_seat, is_toast_shown, toast_type, toast_msg };
    },
  };
</script>

<style scoped>
  .slider-menu-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.7em 0;
  }
</style>
