<template>
  <div class="booking">
    <Navbar />

    <!-- Filter View -->
    <div class="filter-container">
      <div class="filter-card">
        <Icon icon="ci:filter-off" class="filter-icon" @click="clear_filter" />

        <DateTimeInput name="Start" class="dt-input-start" v-model="start_str" :default_value="default_start_str" />
        <DateTimeInput name="Ende" class="dt-input-end" v-model="end_str" :default_value="default_end_str" />
        <Button class="btn-seatplans" @click="to_seat_plans_route">Sitzpläne</Button>

        <!-- Text input search bar -->
        <SearchInput
          class="search-input"
          v-model="current_search"
          @add-tag="on_add_tag"
          :completion_items="completion"
        />
        <!-- Searches added to the tags array to make room in the search input -->
        <div class="search-tags" v-if="search_tags.length > 0">
          <label class="search-for">
            <span class="reset-filter-icon"
              ><Icon icon="ci:filter-off" class="tags-filter-icon" @click="search_tags = []"
            /></span>
            <span class="descr">Suche nach:</span>
          </label>
          <SearchTag v-for="tag in search_tags" :key="tag" :tag="tag" @remove-tag="on_remove_tag(tag)" />
        </div>
      </div>
    </div>

    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />

    <!-- Change between single and multiple booking view -->
    <div class="slider-menu">
      <SliderMenu :menu_items="['Einfach', 'Mehrfach']" @menu-click="toggle_booking_mode" />
    </div>

    <!-- Booking Cards and Suggestions -->
    <div class="booking-container">
      <div class="booking-card-wrapper" v-if="!is_loading">
        <Icon icon="ci:filter-off" class="filter-icon" v-if="!is_single_booking" @click="booking_cart = []" />

        <!-- Exact Booking Timeframe -->
        <!-- <h3 class="timeframe-description fitting" v-if="has_fitting_data">Passender Zeitraum</h3> -->
        <div class="booking-items" v-if="has_fitting_data">
          <BookingCard
            v-for="offer in filtered_data.fitting"
            :key="offer.seat_id"
            :is_single_booking="is_single_booking"
            :offer="offer"
            :is_selected="booking_cart.includes(offer.offer_id)"
            :overlay_start="get_show_offer(offer.offer_id)"
            @toggle-favorite="on_toggle_favorite(offer.seat_id)"
            @toggle-offer="on_toggle_offer(offer.offer_id)"
            @booking-response="on_booking_response"
            @timeframe-change="on_timeframe_change"
            @next-offer="on_next_offer"
            @prev-offer="on_prev_offer"
          />
        </div>

        <!-- Suggestion for close Booking Timeframe -->
        <!-- <h3 class="timeframe-description" v-if="has_vague_data">Ungefährer Zeitraum</h3> -->
        <div class="booking-items" v-if="has_vague_data">
          <BookingCard
            v-for="offer in filtered_data.vague"
            :key="offer.seat_id"
            :offer="offer"
            :is_single_booking="is_single_booking"
            :is_selected="booking_cart.includes(offer.offer_id)"
            :overlay_start="get_show_offer(offer.offer_id)"
            @toggle-favorite="on_toggle_favorite(offer.seat_id)"
            @toggle-offer="on_toggle_offer(offer.offer_id)"
            @booking-response="on_booking_response"
            @timeframe-change="on_timeframe_change"
          />
        </div>

        <h2 class="no-booking-data" v-if="!(has_fitting_data || has_vague_data)">
          Es konnten keine passenden Angebote gefunden werden...
        </h2>
      </div>
      <div class="spinner-wrapper" v-else>
        <Spinner :is_loading="is_loading" />
      </div>

      <!-- Multiple Booking Cart -->
      <IconButton
        class="btn-cart"
        :icon_id="'la:cart-arrow-down'"
        v-if="!is_single_booking"
        @click="show_multiple_booking_form = true"
      />
    </div>

    <!-- Form for booking multiple seats. Component similar to a shopping cart on shopping sites. -->
    <MultipleBookingForm
      v-if="!is_single_booking && show_multiple_booking_form"
      :offers="get_cart_offers"
      @close-overlay="show_multiple_booking_form = false"
      @remove-offer="on_remove_offer"
      @booking-response="on_multiple_booking_response"
    />
  </div>
</template>

<script>
  import { Icon } from '@iconify/vue';
  import { computed, ref, watch } from 'vue';

  import axios from 'axios';
  import dayjs from 'dayjs';

  import { api_routes } from '@/config';
  import { Logger, str_to_date } from '@/util';
  import { use_search, use_toast, use_toggle_favorite, use_start_end_dtinputs } from '@/composables/composables';

  import Navbar from '@/components/general/Navbar.vue';
  import DateTimeInput from '@/components/general/DateTimeInput.vue';
  import Button from '@/components/general/Button.vue';
  import SearchInput from '@/components/general/SearchInput.vue';
  import SliderMenu from '@/components/general/SliderMenu.vue';
  import BookingCard from '@/components/view-members/booking/BookingCard.vue';
  import SearchTag from '@/components/general/SearchTag.vue';
  import IconButton from '@/components/general/IconButton.vue';
  import Toast from '@/components/general/Toast.vue';
  import MultipleBookingForm from '@/components/view-members/booking/MultipleBookingForm.vue';
  import Spinner from '@/components/general/Spinner.vue';
  import { useRoute, useRouter } from 'vue-router';

  /**
   * Main page for booking UI and logic.
   * This includes the timeframe selection, the search and the booking cards, as well as the single and multiple booking mode.
   * This view has various sub-components to handle the sub-functionalities, like overlays and booking forms.
   */
  export default {
    name: 'Booking',
    components: {
      Icon,
      Navbar,
      DateTimeInput,
      Button,
      SearchInput,
      SliderMenu,
      BookingCard,
      SearchTag,
      IconButton,
      Toast,
      MultipleBookingForm,
      Spinner,
    },
    params: {
      search: {
        type: String,
        default: '',
      },
    },
    setup() {
      const is_single_booking = ref(true); // booking mode - single or multiple
      const booking_cart = ref([]); // array of selected offers in the multiple booking mode
      const show_multiple_booking_form = ref(false); // show flag for the overlay "shopping cart" for multiple booking mode
      const completion = ref([]); // array of completion items for the search input
      const route = useRoute();
      const router = useRouter();
      const is_loading = ref(false); // flag for loading state

      /**
       * Redirect to the seat plan view in order for the user to select the seat on the actual image seat plan.
       * The current timeframe is passed as a parameter to the seat plan view.
       */
      const to_seat_plans_route = () => {
        router.push({
          name: 'SeatLists',
          query: {
            start_page: 'Sitzplan',
            start: str_to_date(start_str.value).toISOString(),
            end: str_to_date(end_str.value).toISOString(),
          },
        });
      };

      // composable timeframe logic
      const { start_str, end_str, default_start_str, default_end_str } = use_start_end_dtinputs(
        route.query?.start
          ? str_to_date(route.query.start)
          : dayjs(new Date())
              .add(1, 'day')
              .hour(7)
              .minute(0)
              .second(0)
              .millisecond(0)
              .toDate(),
        route.query?.end
          ? str_to_date(route.query.end)
          : dayjs(new Date())
              .add(1, 'day')
              .hour(15)
              .minute(30)
              .second(0)
              .millisecond(0)
              .toDate()
      );

      // composable search logic
      const { current_search, search_tags, data, filtered_data, on_add_tag, on_remove_tag } = use_search([
        'fitting',
        'vague',
      ]);

      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      /**
       * Fetch all booking offers from the backend, which fit the timeframe.
       */
      const fetch_booking_data = async () => {
        try {
          is_loading.value = true;
          const start_dt = dayjs(str_to_date(start_str.value));
          const end_dt = dayjs(str_to_date(end_str.value));

          // TODO: refactor
          const now = dayjs(new Date());

          // quality checks
          if (start_dt.isBefore(now)) {
            start_str.value = default_start_str;
            show_toast('error', 'Startzeit liegt in der Vergangenheit');
            return;
          }

          if (end_dt.isBefore(now)) {
            start_str.value = default_start_str;
            show_toast('error', 'Endzeit liegt in der Vergangenheit');
            return;
          }

          if (start_dt.isAfter(end_dt)) {
            start_str.value = default_start_str;
            end_str.value = default_end_str;
            show_toast('error', 'Startzeit liegt nach Endzeit');
            return;
          }

          const res = await axios.get(api_routes.booking_list, {
            params: {
              start: start_dt.toISOString(),
              end: end_dt.toISOString(),
            },
          });

          const res_data = await res.data;
          data.value = {};

          // modify the booking offer array to add a fronent assigned id to each offer
          let i = 1;
          for (let key of Object.keys(res_data)) {
            data.value[key] = res_data[key].map((offer) => {
              return { offer_id: i++, ...offer };
            });
          }
        } catch (error) {
          Logger.log(error);
          show_toast('error', 'Fehler beim Laden der Buchungen');
        } finally {
          is_loading.value = false;
        }
      };

      fetch_booking_data();

      // handle the search params if the user navigates to the booking page from the seat plan view
      if (route.query?.search && route.query.search !== '') {
        search_tags.value.push(route.query.search);
      }

      /**
       * Update completion to add/remove items which are (not) relevant in the search completion anymore.
       */
      watch(filtered_data, () => {
        completion.value = [];

        for (let key of Object.keys(filtered_data.value)) {
          for (let item of filtered_data.value[key]) {
            item.description.split(', ').forEach((el) => {
              if (!completion.value.includes(el) && !search_tags.value.includes(el)) {
                completion.value.push(el);
              }
            });
          }
        }
        completion.value.sort();
      });

      const last_fetches = ref([]);
      /**
       * Refetch data from backend on timeframe change after a short delay.
       */
      watch([start_str, end_str], () => {
        is_loading.value = true;

        if (last_fetches.value.length > 0) {
          last_fetches.value.forEach((fetch_id) => clearTimeout(fetch_id));
        }

        last_fetches.value.push(
          setTimeout(async () => {
            await fetch_booking_data();
            is_loading.value = false;
          }, 1000)
        );
      });

      const has_fitting_data = computed(() => filtered_data.value?.fitting?.length > 0);
      const has_vague_data = computed(() => filtered_data.value?.vague?.length > 0);

      // Filter logic

      /**
       * Reset all filter values to default.
       */
      const clear_filter = () => {
        current_search.value = '';
        search_tags.value = [];
        start_str.value = default_start_str.value;
        end_str.value = default_end_str.value;
      };

      /**
       * Toggle the booking mode between single and multiple booking.
       * @param {string} item - Booking mode string
       */
      const toggle_booking_mode = (item) => {
        is_single_booking.value = item === 'Einfach';
      };

      /**
       * Select/Deselect offer in multiple booking mode.
       * @param {number} offer_id - The frontend assigned id of the offer to be booked
       */
      const on_toggle_offer = (offer_id) => {
        if (is_single_booking.value) {
          return;
        }

        if (booking_cart.value.includes(offer_id)) {
          booking_cart.value = booking_cart.value.filter((id) => id !== offer_id);
        } else {
          booking_cart.value.push(offer_id);
        }
      };

      // composable for favorite toggle
      const { toggle_favorite } = use_toggle_favorite();

      /**
       * Toggle favorite function with callbacks for success and error.
       */
      const on_toggle_favorite = (pk) => {
        toggle_favorite(
          pk,
          (server_data) => {
            // update seat data according to seat list or favorite list mode
            Object.keys(data.value).forEach((key) => {
              data.value[key].forEach((seat) => {
                if (seat.seat_id === pk) {
                  seat.is_favorite = server_data.state === 'true' ? true : false;
                }
              });
            });
          },
          (error) => {
            show_toast('error', 'Verbindungsfehler!');
            Logger.log(error);
          }
        );
      };

      /**
       * Show toast message on successfull/unsuccessful booking.
       * Refetch booking offer data from server.
       */
      const on_booking_response = (response) => {
        if (response.status === 'success') {
          show_toast('success', 'Buchung erfolgreich');
        } else {
          show_toast('error', 'Buchung fehlgeschlagen');
        }
        fetch_booking_data();
      };

      /**
       * Function to handle if the booking timeframe for a single seat got changed in the sub-component overlay.
       * This will ajust the booking timeframe accordingly in this main view.
       * @param {number} offer_id - The frontend assigned id of the offer
       * @param {string} start - The new start Date object
       * @param {string} end - The new end Date object
       */
      const on_timeframe_change = ({ offer_id, start, end }) => {
        const start_dt = dayjs(start);
        const end_dt = dayjs(end);

        for (let key of Object.keys(data.value)) {
          const index = data.value[key].findIndex((offer) => {
            return offer.offer_id === offer_id;
          });
          if (index === -1) continue;
          data.value[key][index].start = start_dt;
          data.value[key][index].end = end_dt;
        }
      };

      /**
       * Remove an offer from the booking cart in multiple booking mode.
       * @param {number} offer_id - The frontend assigned id of the offer
       */
      const on_remove_offer = (offer_id) => {
        booking_cart.value = booking_cart.value.filter((item) => item !== offer_id);
      };

      const get_cart_offers = computed(() => {
        const cart_offers = [];
        for (let key of Object.keys(data.value)) {
          for (let item of data.value[key]) {
            if (booking_cart.value.includes(item.offer_id)) {
              cart_offers.push(item);
            }
          }
        }

        return cart_offers;
      });

      /**
       * Event handler if some offers are booked with the multiple booking form. Display a message if the booking was successful.
       * Aso clear the current booking cart.
       * @param {'error' | 'success'} status - The status of the multiple booking response
       * @param {string} message - The message to be displayed
       */
      const on_multiple_booking_response = ({ status, message }) => {
        show_multiple_booking_form.value = false;
        booking_cart.value = [];
        show_toast(status, message);
        fetch_booking_data();
      };

      // ref to handle the page shown in the usual booking overlay card
      const show_offer_id = ref({
        offer_id: null,
        page: 'Timeframe',
      });

      /**
       * Show the next offer in the booking overlay if this function is called.
       * @param {number} offer_id - The frontend assigned id of the current offer
       * @param {string} page - The subpage page to be shown in the booking overlay
       */
      const on_next_offer = ({ offer_id, page }) => {
        for (let key of Object.keys(filtered_data.value)) {
          const index = filtered_data.value[key].findIndex((offer) => {
            return offer.offer_id === offer_id;
          });
          if (index === -1) continue;

          if (index === filtered_data.value[key].length - 1) {
            show_offer_id.value = {
              offer_id: filtered_data.value[key][0].offer_id,
              page: page,
            };

            return;
          } else {
            show_offer_id.value = {
              offer_id: filtered_data.value[key][index + 1].offer_id,
              page: page,
            };
            return;
          }
        }
      };

      /**
       * Show the previous offer in the booking overlay if this function is called.
       * @param {number} offer_id - The frontend assigned id of the current offer
       * @param {string} page - The subpage page to be shown in the booking overlay
       */
      const on_prev_offer = ({ offer_id, page }) => {
        for (let key of Object.keys(filtered_data.value)) {
          const index = filtered_data.value[key].findIndex((offer) => {
            return offer.offer_id === offer_id;
          });
          if (index === -1) continue;

          if (index === 0) {
            show_offer_id.value = {
              offer_id: filtered_data.value[key][filtered_data.value[key].length - 1].offer_id,
              page: page,
            };
            return;
          } else {
            show_offer_id.value = {
              offer_id: filtered_data.value[key][index - 1].offer_id,
              page: page,
            };
            return;
          }
        }
      };

      /**
       * Logic for getting the currently selected page in the booking overlay and clearing the current offer id if a selection is made.
       * @return {number | null} The page associated with the currently selected offer id
       */
      const get_show_offer = (offer_id) => {
        const soid = show_offer_id.value.offer_id;

        if (soid === offer_id) {
          show_offer_id.value.offer_id = null;
          return show_offer_id.value.page;
        }

        return null;
      };

      return {
        is_single_booking,
        start_str,
        end_str,
        toggle_booking_mode,
        search_tags,
        filtered_data,
        current_search,
        on_add_tag,
        on_remove_tag,
        clear_filter,
        is_toast_shown,
        toast_type,
        toast_msg,
        on_toggle_favorite,
        booking_cart,
        on_toggle_offer,
        has_fitting_data,
        has_vague_data,
        default_start_str,
        default_end_str,
        show_multiple_booking_form,
        completion,
        on_booking_response,
        on_timeframe_change,
        on_remove_offer,
        get_cart_offers,
        on_multiple_booking_response,
        on_next_offer,
        on_prev_offer,
        get_show_offer,
        to_seat_plans_route,
        is_loading,
      };
    },
  };
</script>

<style scoped>
  .booking {
    font-size: 1rem;
  }

  .filter-container {
    font-size: 1.1em;
    background: url('~@/assets/bg-inp-building.png');
    background-size: cover;
    padding: 2em 0;
    background-position: 20%;
    background-repeat: no-repeat;

    /* clip-path: polygon(0 0, 100% 0%, 100% 97%, 0% 100%); */

    display: flex;
    place-content: center;
  }

  .filter-container .filter-card {
    padding: 1em;
    background-color: #fff;
    border-radius: 0.3em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    width: 90%;

    font-size: 1.1em;
  }

  .filter-icon {
    font-size: 1.2em;
    color: #afafaf;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
  }

  .filter-icon:hover {
    color: #838383;
  }

  .filter-container .filter-card .filter-icon {
    font-size: 1em;
  }

  .filter-container .filter-card .btn-seatplans {
    margin: 1em 0.8em;
    width: 70%;
    max-width: 18em;
  }

  .filter-container .filter-card .search-tags {
    display: flex;
    flex-wrap: wrap;
    width: 79%;

    place-content: flex-start;
    gap: 0.2em 0.4em;
  }

  .filter-container .filter-card .search-tags .search-for {
    color: #9d9d9d;
    font-size: 0.7em;
    margin-right: 0.8em;
    display: flex;
    align-items: center;
    gap: 0.25em;
  }

  .filter-container .filter-card .search-tags .search-for .reset-filter-icon {
    color: #727272;
    cursor: pointer;
  }

  .filter-container .filter-card .search-tags .search-for .reset-filter-icon:hover {
    color: #a3a3a3;
  }

  .filter-container .filter-card .search-tags .search-for .descr {
    color: #9d9d9d;
  }

  .filter-container .filter-card .search-tags .search-tag {
    font-size: 0.65em;
  }

  .slider-menu {
    margin: 2.1em auto 0 auto;
  }

  .btn-cart {
    position: fixed;
    font-size: 1.3em;
    bottom: 0.7em;
    right: 0.7em;
  }

  .booking-container {
    position: relative;
    background-color: #ebebeb;
    width: 90%;
    margin: 2em auto 0 auto;
    min-height: 60vh;
    border-radius: 0.3em;
    padding: 1em 1em 2em 1em;
  }

  .booking-container .timeframe-description {
    text-align: center;
    font-weight: normal;
    font-size: 0.75em;
    color: #898989;
    border-bottom: 1px solid #bababa;
    width: fit-content;
    padding: 0 5em 0.2em 5em;
    margin: 4em auto 2em auto;
  }

  .booking-container .timeframe-description.fitting {
    margin-top: 1em;
  }

  .booking-container .booking-items {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;
    place-content: center;
    width: 100%;
  }

  .no-booking-data {
    text-align: center;
    font-size: 1.3em;
    font-weight: 500;
    color: #616161;
    margin: 2em auto;
  }

  @media (min-width: 750px) {
    .booking {
      font-size: 0.9rem;
    }

    .filter-container .filter-card {
      max-width: 750px;
    }

    .filter-container .filter-card {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 1.7em 3.2em;
      width: 90%;
    }

    .filter-container .filter-card .dt-input-start {
      width: 80%;
      justify-self: start;
    }

    .filter-container .filter-card .dt-input-end {
      width: 80%;
      justify-self: flex-end;
    }

    .filter-container .filter-card .btn-seatplans {
      grid-row: 2;
      grid-column: 2;
      width: 150px;
      justify-self: end;
    }

    .filter-container .filter-card .search-input {
      align-self: center;
    }

    .filter-container .filter-card .search-tags {
      grid-column: 1 / -1;
    }
  }

  @media (min-width: 1400px) {
    .booking {
      font-size: 1rem;
    }

    .filter-container .filter-card {
      max-width: 950px;
    }
  }
</style>
