<template>
  <Overlay>
    <!-- Header information -->
    <template v-slot:header>
      <div class="header-group">
        <div class="main-header">
          <!-- <Icon icon="akar-icons:arrow-left" class="icon" @click.stop="$emit('prev')" /> -->
          <h2 class="seat-name">{{ item.seat_full_name }}</h2>
          <!-- <Icon icon="akar-icons:arrow-right" class="icon" @click.stop="$emit('next')" /> -->
        </div>
        <p class="descr">{{ item.seat_description }}</p>
      </div>
    </template>

    <!-- Content with occupation info and seat preview -->
    <template v-slot:content>
      <div class="reservation-info">
        <h3 class="user-name">{{ item.user_name }}</h3>
        <p class="timeframe">{{ date_to_str(item.start) }} - {{ date_to_str(item.end) }}</p>
      </div>
      <div class="seat-preview-wrapper">
        <SeatPreview :seat_ids="[item.seat_pk]" @loaded="is_loading = false" />
      </div>
      <div class="spinner-wrapper" v-if="is_loading">
        <Spinner :is_loading="is_loading" />
      </div>
    </template>
  </Overlay>
</template>

<script>
  import Overlay from '@/components/general/Overlay.vue';
  import SeatPreview from '@/components/general/SeatPreview.vue';
  import Spinner from '@/components/general/Spinner.vue';

  import { date_to_str } from '@/util';
  import { ref } from '@vue/reactivity';

  /**
   * Overlay to display information about the occupation event, which is basically a reservation.
   * This ovelay shows the timeframe as well as the seat preview.
   *
   * @notimplemented
   * Emits:
   *   - next - the right arrow to show the next occupation list point
   *   - previous - the left arrow to show the previous occupation list point
   */
  export default {
    name: 'OccupationSeatPreviewOverlay',
    components: {
      Overlay,
      SeatPreview,
      Spinner,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    emits: ['next', 'prev'],
    setup() {
      const is_loading = ref(true); // flag to indicate whether the seat preview is loaded

      return { date_to_str, is_loading };
    },
  };
</script>

<style scoped>
  .header-group {
    text-align: center;
  }

  .header-group .main-header {
    display: flex;
    gap: 0.7em;
    align-items: center;
    justify-content: center;
  }

  .header-group .main-header .icon {
    color: #555;
    cursor: pointer;
  }

  .header-group .main-header .icon:hover {
    color: #858585;
  }

  .header-group .main-header .seat-name {
    font-size: 0.938em;
    font-weight: 500;
  }

  .header-group .descr {
    color: #9d9d9d;
    font-size: 0.625em;
  }

  .reservation-info {
    padding: 0.5em;
    border-bottom: 1px solid #eaeaea;
    text-align: center;
  }

  .reservation-info .user-name {
    font-size: 1em;
    font-weight: 500;
    color: #555;
  }

  .reservation-info .timeframe {
    font-size: 0.813em;
    color: #9d9d9d;
  }

  .seat-preview-wrapper {
    overflow: auto;
  }
</style>
