import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { DEBUG } from '@/config';

import axios from 'axios';
import dayjs from 'dayjs';

import { api_routes } from '@/config';
import { date_to_str, Logger, str_to_date } from '@/util';

/**
 * Composable function to setup toast functionality. This function must be used in combination with the Toast component.
 * is_toast_shown: ref that indicates if toast is shown
 * toast_state: ref that indicates toast state
 * toast_msg: ref that holds the toast message
 * show_toast: function that shows toast
 * @returns {{is_toast_shown: boolean, toast_type: 'success' | 'error', toast_msg: string, show_toast: (type: 'success' | 'error', msg: string) => void}} - Object with toast functionality.
 */
export function use_toast() {
  const is_toast_shown = ref(false);
  const toast_msg = ref('');
  const toast_type = ref('error');

  /**
   * Function to show the toast with given message for a duration of 3 seconds.
   * @param type - Type of toast
   * @param msg - Message of toast
   */
  const show_toast = (type, msg) => {
    toast_msg.value = msg;
    if (['success', 'error'].indexOf(type) === -1) {
      throw new Error('Invalid toast type');
    }
    toast_type.value = type;
    is_toast_shown.value = true;
    setTimeout(() => {
      is_toast_shown.value = false;
    }, 3000);
  };

  return { is_toast_shown, toast_type, toast_msg, show_toast };
}

/**
 * This composable function is used to logout the user and redirect to the login page.
 * @returns {{logout: () => void}} - Logout function
 */
export function use_logout() {
  const store = useStore();
  const router = useRouter();

  /**
   * Function to logout the user and redirect to the login page.
   */
  const logout = () => {
    store.dispatch('logout');
    router.push({ name: 'Login' });
  };

  return { logout };
}

/**
 * Composable to provide the functionality for getting the user data of the currently logged in user.
 * user_data - ref that holds the user data
 * fetch_user_data: function that fetches the user data from the backend
 * @returns {{user_data: {username: string, first_name: string, last_name: string}, fetch_user_data: () => Promise<void>}} - Object with user data and function to fetch it from the backend.
 */
export function use_user() {
  const user_data = ref({ user_name: '', first_name: '', last_name: '' });

  /**
   * Function to fetch the user data of the currently logged in user.
   * @param callback_on_error - Callback function to call if an error occurs.
   */
  const fetch_user_data = async (callback_on_error = () => {}) => {
    try {
      const res = await axios.get(api_routes.user_data);

      const data = await res.data;
      user_data.value = data;
    } catch (error) {
      callback_on_error(error);
    }
  };

  return { user_data, fetch_user_data };
}

/**
 * Composable to provide top level object string search functionality.
 * Also there will be an array of search tags will will be also applied in combination to the current search string.
 * @param search_keys - Array of initial search keys
 * @returns {{ current_search: Ref<string>, search_tags: Ref<string[]>, data: object[], filtered_data: object[], on_add_tag: () => void, on_remove_tag: (tag: string) => void}} - Refs and functions to handle search functionality.
 */
export function use_search(search_keys = []) {
  const current_search = ref('');
  const search_tags = ref([]);
  const data = ref([]);

  /**
   * Function to search for a given string in an object at the top level (non recursive). Update the computed data array with the results.
   * @returns - the filtered data object
   */
  const filtered_data = computed(() => {
    if (current_search.value === '' && search_tags.value.length === 0) {
      return data.value;
    }

    const query = current_search.value === '' ? search_tags.value : [current_search.value, ...search_tags.value];

    /**
     * Filter function to search for a given string in an object at the top level (non recursive).
     * @param filter_data - data to filter
     * @returns - filtered data
     */
    const filter_func = (filter_data) => {
      return filter_data.filter((item) => {
        return query.every((tag) => {
          return Object.keys(filter_data[0]).some((key) => {
            if (typeof item[key] !== 'string') {
              return false;
            }
            return item[key].toLowerCase().includes(tag.toLowerCase());
          });
        });
      });
    };

    if (search_keys.length === 0) {
      return filter_func(data.value);
    } else {
      let filtered_obj = {};
      for (let key of search_keys) {
        if (Object.keys(data.value).includes(key)) {
          filtered_obj[key] = filter_func(data.value[key]);
        }
      }
      return filtered_obj;
    }
  });

  /**
   * Function to add a tag to the search tags. The tag added is the current search string.
   */
  const on_add_tag = () => {
    if (current_search.value !== '') {
      search_tags.value.push(current_search.value);
      current_search.value = '';
    }
  };

  /**
   * Remove a tag from the search tags.
   * @param tag - Tag to remove
   */
  const on_remove_tag = (tag) => {
    search_tags.value.splice(search_tags.value.indexOf(tag), 1);
  };

  return {
    current_search,
    search_tags,
    data,
    filtered_data,
    on_add_tag,
    on_remove_tag,
  };
}

/**
 * Composable to get function to toggle a favorite item at the backend and provide a callback function to update the favorite status of the item.
 * @returns {{toggle_favorite: (item: object, callback: (item: object) => void) => void}} - Actual function to toggle a favorite item
 */
export function use_toggle_favorite() {
  /**
   * Function to toggle a favorite item at the backend and provide a callback function to update the favorite status of the item.
   * @param pk - Primary key of the item to toggle
   * @param on_success - Callback function to call if the toggle is successful
   * @param on_error - Callback function to call if the toggle fails
   */
  const toggle_favorite = async (pk, on_success = () => {}, on_error = () => {}) => {
    try {
      const res = await axios.post(api_routes.toggle_favorite, { pk: pk });
      const server_data = await res.data;

      if (server_data.status === 'error') throw new Error('toggle favorite request failed');

      on_success(server_data);
    } catch (error) {
      on_error(error);
    }
  };

  return { toggle_favorite };
}

/**
 * Composable to get reactive strings corresponding to datetimes to use in a component template. On these strings actions are performed on change to make sure that they are alway a allowed value.
 * Checks:
 *   - start cannot be before the current time
 *   - end cannot be before the start time
 *   - if start is after end, the start value is set appropriately
 *   - if end is before start, the end value is set appropriately
 *
 * @param {string} default_start_dt - Default start datetime to which will be reset on error
 * @param {string} default_end_dt - Default end datetime to which will be reset on error
 * @param {(start_str: string, end_str: string, default_start_str: string, default_end_str: string) => void } watch_start_callback - Callback function to call when the start datetime changes
 * @param {(start_str: string, end_str: string, default_start_str: string, default_end_str: string) => void } watch_end_callback - Callback function to call when the end datetime changes
 * @returns {{start_str: string, end_str: string, default_start_str: string, default_end_str: string}} - Refs to the current & default start and end datetime strings
 */
export function use_start_end_dtinputs(
  default_start_dt,
  default_end_dt,
  watch_start_callback = () => {},
  watch_end_callback = () => {}
) {
  const default_start_str = ref(date_to_str(default_start_dt));
  const default_end_str = ref(date_to_str(default_end_dt));
  const start_str = ref(default_start_str.value);
  const end_str = ref(default_end_str.value);

  /**
   * Watcher to perform actions on the start_str & end_str. If a callback was supplied the watch_start_callback function is called.
   * Following operations are performed:
   *   - if the start datetime is before the current time reset it to the default start time
   *   - if the end datetime is before the current datetime reset it to the default end time
   *   - if start is after end set end time either to the next day or 8:45 hours into the future
   * When all these operations were successfully applied the watch_start_callback function is called.
   */
  watch(start_str, () => {
    try {
      const start_dt = dayjs(str_to_date(start_str.value));
      const end_dt = dayjs(str_to_date(end_str.value));

      const now = dayjs(new Date());

      if (start_dt.isBefore(now)) {
        start_str.value = default_start_str.value;
      }

      if (end_dt.isBefore(now)) {
        start_str.value = default_start_str.value;
      }

      if (start_dt.isAfter(end_dt)) {
        if (Math.abs(end_dt.diff(start_dt, 'hours')) < 24) {
          end_str.value = date_to_str(end_dt.add(1, 'days').toDate());
        } else {
          end_str.value = date_to_str(start_dt.add(8, 'hours').add(45, 'minutes').toDate());
        }
      }

      watch_start_callback({ start_str, end_str, default_start_str, default_end_str });
    } catch (error) {
      Logger.log(error);
      return;
    }
  });

  /**
   * Watcher to perform actions on the start_str & end_str. If a callback was supplied the watch_end_callback function is called.
   * Following operations are performed:
   *   - if the start datetime is before the current time reset it to the default start time
   *   - if the end datetime is before the current datetime reset it to the default end time
   *   - if start is after end set start time either to the day before or 8:45 hours into the past
   * When all these operations were successfully applied the watch_end_callback function is called.
   */
  watch(end_str, () => {
    try {
      const start_dt = dayjs(str_to_date(start_str.value));
      const end_dt = dayjs(str_to_date(end_str.value));

      const now = dayjs(new Date());

      if (start_dt.isBefore(now)) {
        start_str.value = default_start_str.value;
      }

      if (end_dt.isBefore(now)) {
        start_str.value = default_start_str.value;
      }

      if (start_dt.isAfter(end_dt)) {
        if (Math.abs(end_dt.diff(start_dt, 'hours')) < 24) {
          start_str.value = date_to_str(start_dt.subtract(1, 'days').toDate());
        } else {
          start_str.value = date_to_str(end_dt.subtract(8, 'hours').subtract(45, 'minutes').toDate());
        }
      }

      watch_end_callback({ start_str, end_str, default_start_str, default_end_str });
    } catch (error) {
      Logger.log(error);
      return;
    }
  });

  return { start_str, end_str, default_start_str, default_end_str };
}

/**
 * Provide functionality to work with notifications. This is mostly a wrapper around the store functionality, which should be encapsulated with vuex.
 * Functionality:
 *   - noftifications getter from store
 *   - number of unread notifications getter from store
 *   - user reads notification
 *   - user deletes notification
 *
 * @returns {{notifications: Array<Notification>, num_unread: number, on_show_content: () => void, on_remove_notification: (notification: object) => void}} - Refs to the notifications array and the number of unread notifications, as well as callbacks to perform actions on the notifications array
 */
export function use_notifications() {
  const store = useStore();

  const in_remove_q = ref([]);

  /**
   * Get the users notifications from the store
   */
  const notifications = computed(() => {
    if (!store.getters.notifications) return [];

    return store.getters.notifications;
  });

  /**
   * Get the number of unread notifications from the store
   */
  const num_unread = computed(() => {
    return store.getters.num_unread_notifications;
  });

  /**
   * Update the notifications read flag in the store & backend
   */
  const on_show_content = () => {
    notifications.value.forEach((n) => {
      if (!n.is_read) {
        store.dispatch('read_notification', n);
      }
    });
  };

  /**
   * Remove the notification from the store & backend. After that refetch the notifications from the backend.
   * @param notification - The notification to remove from the store
   */
  const on_remove_notification = async (notification) => {
    // TODO: show toast
    if (in_remove_q.value.includes(notification.pk) === false) {
      in_remove_q.value.push(notification.pk);
      await store.dispatch('delete_notification', notification);
      await store.dispatch('fetch_notifications');
    }

    in_remove_q.value = in_remove_q.value.filter((n) => n !== notification.pk);
  };

  return {
    notifications,
    num_unread,
    on_show_content,
    on_remove_notification,
  };
}

/**
 * Provide functionality to fetch the seat plan picture url as well as the according seat data (location, etc.) from the backend.
 * This handles also how the seat plan picture is fetched from the backend. This is important during development, because then no auth token is needed to get access to the backend.
 *
 * @returns {{data: object, is_loading: boolean, fetch_seat_plan_data: async () => Promise<void>}} - Refs to the seat plan data and the loading state of the seat plan data. As well as the function to fetch the seat plan data from the backend.
 */
export function use_seat_plan_data() {
  const is_loading = ref(true);
  const data = ref(null);
  const store = useStore();

  /**
   * Fetch the seat plan data from the backend and update the data ref. The data is modified, so that every item contains the modified seat plan url.
   */
  const fetch_seat_plan_data = async () => {
    try {
      is_loading.value = true;
      const res = await axios.get(api_routes.seat_plan);

      const res_data = await res.data;
      data.value = res_data.map((item) => {
        let new_level_plan_url = item.level_plan_url;

        // this stems originally from the time docker was not used for this project. Because different ports were used from the django and the vue dev server the url needed to be ajusted. But this is still kept for now because of backwards compatibility.
        if (DEBUG && Object.keys(item).includes('level_plan_url')) {
          const base_url =
            new_level_plan_url[0] === '/'
              ? axios.defaults.baseURL.substring(0, axios.defaults.baseURL.length - 1)
              : axios.defaults.baseURL;
          new_level_plan_url = `${base_url}${new_level_plan_url}`;
        }

        if (new_level_plan_url !== null) new_level_plan_url = `${new_level_plan_url}?token=${store.getters.auth_token}`;

        return {
          ...item,
          level_plan_url: new_level_plan_url,
        };
      });
    } catch (error) {
      Logger.log(error);
    } finally {
      is_loading.value = false;
    }
  };

  return { data, is_loading, fetch_seat_plan_data };
}
