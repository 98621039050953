<template>
  <div class="seat-plan-page">
    <SeatPlan @to-booking="on_to_booking" />
  </div>
</template>

<script>
  import SeatPlan from '@/components/general/seat-plan/SeatPlan.vue';
  import { useRouter } from 'vue-router';

  /**
   * Wrapper for the seat plan component.
   * This will also provide the functionality to be used in combination with the booking page.
   */
  export default {
    name: 'SeatPlanPage',
    components: {
      SeatPlan,
    },
    setup() {
      const router = useRouter();

      /**
       * Navigate to the booking page.
       * @param {string} search - the seat name to be used as search string in booking
       * @param {string} start - the start isostring
       * @param {string} end - the end isostring
       */
      const on_to_booking = ({ search, start, end }) => {
        router.push({
          name: 'Booking',
          query: {
            search,
            start,
            end,
          },
        });
      };

      return {
        on_to_booking,
      };
    },
  };
</script>
