<template>
  <div class="login">
    <LoginHeader />

    <!-- Actual login logic communication with the backend -->
    <div class="login-section">
      <LoginCard />
    </div>
  </div>
</template>

<script>
  import LoginHeader from '@/components/view-members/auth/LoginHeader.vue';
  import LoginCard from '@/components/view-members/auth/LoginCard.vue';

  /**
   * Wrapper component for the login component.
   * The actual login logic is done in the LoginCard component.
   */
  export default {
    name: 'Login',
    components: {
      LoginHeader,
      LoginCard,
    },
  };
</script>

<style scoped>
  .login {
    background-image: url('~@/assets/bg-inp-building.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
  }

  .login-section {
    margin: auto;
    height: 80vh;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
