<template>
  <div class="reservation-container">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />
    <div class="content-wrapper" v-if="!loading">
      <div class="actions">
        <div class="toggle-btn">
          <!-- Button to toggle between the list and the calendar view -->
          <IconToggleButton first="bi:list-ul" second="akar-icons:calendar" @on-toggle="on_toggle" />
        </div>
        <Icon
          icon="akar-icons:edit"
          :class="`edit-icon ${edit_reservations ? 'edit-mode' : ''}`"
          v-if="!show_calendar"
          @click="edit_reservations = !edit_reservations"
        />
      </div>

      <!-- Calendar view - secondary view -->
      <div class="calendar-container" v-if="show_calendar">
        <Calendar :items="calendar_events" />
      </div>

      <!-- Reservations list view - default view -->
      <div class="list-wrapper" v-if="!show_calendar">
        <Button v-if="edit_reservations" class="btn-revert-reservations" @click="on_cancel_reservations"
          >Ausgewählte Stornieren</Button
        >
        <button class="select-all" @click="on_toggle_all_none" v-if="edit_reservations">
          {{ edit_selection.length === 0 ? 'Alle' : 'Keine' }}
        </button>

        <h3 class="no-content" v-if="reservations?.length === 0">Keine Reservierungen vorhanden...</h3>

        <ReservationCard
          v-for="(reservation, index) in reservations"
          :key="index"
          :edit_mode="edit_reservations"
          :edit_active="edit_selection.includes(reservation.pk)"
          :reservation="reservation"
          @reservation-canceled="on_canceled_overlay"
          @edit-toggle="on_edit_toggle"
          @toggle-favorite="on_toggle_favorite"
        />
      </div>
    </div>
    <div class="spinner-wrapper" v-else>
      <Spinner :is_loading="loading" />
    </div>
  </div>
</template>

<script>
  import { computed, ref } from 'vue';

  import { Icon } from '@iconify/vue';
  import { api_routes } from '@/config';
  import { Logger } from '@/util';

  import dayjs from 'dayjs';

  import Calendar from '@/components/general/calendar/Calendar.vue';
  import IconToggleButton from '@/components/general/IconToggleButton.vue';
  import ReservationCard from '@/components/view-members/reservation/ReservationCard.vue';
  import Button from '@/components/general/Button.vue';
  import Spinner from '@/components/general/Spinner.vue';
  import axios from 'axios';
  import Toast from '@/components/general/Toast.vue';
  import { use_toast } from '@/composables/composables';

  /**
   * Seat reservation view which displays all the active reservations for the current user.
   * This can be eiter done as a list or as a calendar view.
   * It is possible to edit and cancel reservations.
   */
  export default {
    name: 'SeatReservations',
    components: {
      Icon,
      Calendar,
      IconToggleButton,
      ReservationCard,
      Button,
      Spinner,
      Toast,
    },
    setup() {
      // mode handeler
      const show_calendar = ref(false);

      const loading = ref(false);
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      const on_toggle = (name) => {
        show_calendar.value = name === 'second';
      };

      // edit reservations handler
      const edit_reservations = ref(false);

      /*
        reservations format:
        [{
          pk,
          seat_name,
          seat_description,
          comment,
          start,
          end,
          reservation_items: [{
            pk, start, end }]
        }]
      */
      const reservations = ref([]);

      /**
       * Fetch reservations from backend and fill the reservations array.
       */
      const fetch_reservations = async () => {
        try {
          loading.value = true;
          const res = await axios.get(api_routes.reservations);

          reservations.value = await res.data;
        } catch (error) {
          Logger.log(error);
        } finally {
          loading.value = false;
        }
      };

      fetch_reservations();

      /**
       *  Handler for canceling a reservation. The request to the backend is handled in a sub-component, but the data in this parent still needs to be updated.
       * Also a toast message is shown to the user.
       * @param {*} reservation - the reaservation object to cancel
       */
      const on_canceled_overlay = (reservation) => {
        // remove deleted reservation items from local reservations
        const target = reservations.value.find((res) => res.pk === reservation.pk);
        target.reservation_items = target.reservation_items.filter(
          (item) => !reservation.reservation_items.includes(item.pk)
        );

        // delete entire local reservation if no reservation items are left
        if (target.reservation_items.length === 0) {
          reservations.value = reservations.value.filter((res) => res.pk !== reservation.pk);
        }

        show_toast('success', 'Termine erfolgreich storniert!');
      };

      const edit_selection = ref([]);

      /**
       * Toggle the selection of a reservation to perform an action on all selected reservations (eg. cancel).
       * @param {number} pk - the index in the array of reservations
       */
      const on_edit_toggle = (pk) => {
        if (edit_selection.value.includes(pk)) {
          edit_selection.value = edit_selection.value.filter((reservation_pk) => {
            return reservation_pk !== pk;
          });
        } else {
          edit_selection.value.push(pk);
        }
      };

      /**
       * Toggle the selection of all or none reservations depending on the current selection.
       */
      const on_toggle_all_none = () => {
        if (edit_selection.value.length !== 0) {
          edit_selection.value = [];
        } else {
          edit_selection.value = reservations.value.map((reservation) => {
            return reservation.pk;
          });
        }
      };

      /**
       * Cancel all reservations that are in edit selection.
       * First the backend is called to cancel the reservations and then the local data is updated.
       */
      const on_cancel_reservations = async () => {
        try {
          if (edit_selection.value.length === 0) {
            throw new Error('Keine Reservierungen ausgewählt');
          }

          // cancel on backend
          const res = await axios.post(api_routes.cancel_reservations, {
            reservation_ids: edit_selection.value,
          });

          const data = await res.data;
          if (data.status === 'success') {
            // check status and update local data for the UI, as well as clear the selection
            reservations.value = reservations.value.filter((reservation) => {
              return !edit_selection.value.includes(reservation.pk);
            });
            edit_selection.value = [];

            show_toast('success', 'Reservierungen erfolgreich storniert');
            Logger.log('Reservierungen erfolgreich storniert');
          } else if (data.status === 'error') {
            throw new Error(data.errors);
          } else {
            throw new Error('Unbekannter Fehler');
          }
        } catch (error) {
          show_toast('error', error.message);
          Logger.log(error);
        }
      };

      /**
       * Computed property for an easy to use format for the calendar component.
       */
      const calendar_events = computed(() => {
        const calendar_items = [];

        for (let reservation of reservations.value) {
          for (let reservation_item of reservation.reservation_items) {
            calendar_items.push({
              description: reservation.seat_name,
              start: dayjs(reservation_item.start),
              end: dayjs(reservation_item.end),
            });
          }
        }

        return calendar_items;
      });

      /**
       * Function to update the UI when a favorite is added or removed in sub-component.
       */
      const on_toggle_favorite = ({ seat_id, state }) => {
        reservations.value = reservations.value.map((reservation) => {
          if (reservation.seat_id === seat_id) {
            reservation.is_favorite = state;
          }
          return reservation;
        });
      };

      return {
        show_calendar,
        on_toggle,
        edit_reservations,
        reservations,
        on_canceled_overlay,
        edit_selection,
        on_edit_toggle,
        on_cancel_reservations,
        calendar_events,
        on_toggle_favorite,
        loading,
        is_toast_shown,
        toast_type,
        toast_msg,
        on_toggle_all_none,
      };
    },
  };
</script>

<style scoped>
  .reservation-container {
    width: 90%;
    margin: 0 auto;
    font-size: 1.1em;
    background-color: #ebebeb;
    padding: 1em;
    border-radius: 0.3em;
    min-height: 80vh;
  }

  .reservation-container .calendar-container {
    font-size: 0.8em;
  }

  .reservation-container .list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.7em;
    place-items: center;
    font-size: 1.1em;
  }
  .reservation-container .list-wrapper .select-all {
    cursor: pointer;
    margin-bottom: 0.7em;
    border: none;
    background-color: transparent;
    color: #ffa57d;
  }

  .reservation-container .list-wrapper .select-all:hover {
    color: #ff8c4c;
  }

  .reservation-container .actions {
    width: 100%;
    margin-bottom: 1.4em;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .reservation-container .actions .toggle-btn {
    font-size: 0.85em;
  }

  .reservation-container .actions .edit-icon {
    color: #555;
    font-size: 1.3em;
    cursor: pointer;
  }

  .reservation-container .actions .edit-icon:hover {
    color: #333;
  }

  .reservation-container .actions .edit-icon.edit-mode {
    color: #ffa57d;
  }

  .reservation-container .actions .edit-icon.edit-mode:hover {
    color: #ff8c4c;
  }

  .reservation-container .list-wrapper .btn-revert-reservations {
    /* transform: translateY(-1.5em); */
    font-size: 0.75em;
  }

  .no-content {
    text-align: center;
    font-size: 1.05em;
    font-weight: 500;
    color: #616161;
    margin: 2em auto;
  }
</style>
