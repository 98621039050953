<template>
  <div class="reservation-summary">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />
    <div class="comment-group" v-if="reservation.comment !== ''">
      <label class="comment-label">Kommentar</label>
      <p class="comment">
        {{ reservation.comment }}
      </p>
    </div>
    <Button class="btn-cancel-reservation-items" @click="cancel_reservation_items">Ausgewählte Stornieren</Button>

    <!-- Reservation sub-items (eg.: single days) table -->
    <table class="sub-reservations-table">
      <thead>
        <tr>
          <!-- All/None selector -->
          <th @click="toggle_all" class="select-all-none">{{ selected_items.length === 0 ? 'Alle' : 'Keine' }}</th>

          <th>Start</th>
          <th>Ende</th>
        </tr>
      </thead>
      <tbody>
        <!-- sub-item list -->
        <tr v-for="(item, index) in reservation_items" :key="index">
          <td>
            <Icon
              class="icon mark"
              :icon="`akar-icons:circle${selected_items.includes(item.pk) ? '-check' : ''}`"
              @click="toggle_item(item.pk)"
            />
          </td>
          <td>{{ date_to_str(item.start) }}</td>
          <td>{{ date_to_str(item.end) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { ref } from '@vue/reactivity';

  import axios from 'axios';
  import { api_routes } from '@/config';
  import { Logger, date_to_str } from '@/util';

  import { Icon } from '@iconify/vue';
  import Button from '@/components/general/Button.vue';
  import Toast from '@/components/general/Toast.vue';
  import { use_toast } from '@/composables/composables';

  /**
   * Component for displaying a single reservation and its sub-items.
   * Sub-items are ReservationItems (single days). These can be cancled without canceling the whole reservation.
   * This component is used in the ReservationOverly.
   *
   * Emits:
   *   - reservation-canceled: when at least one reservation item has been canceled
   */
  export default {
    name: 'ReservationSummary',
    components: {
      Icon,
      Button,
      Toast,
    },
    props: {
      reservation: {
        type: Object,
        required: true,
        /*
          reservations format:
          [{
            pk: number,
            seat_name: string,
            seat_description: string,
            comment: string,
            start: Date,
            end: Date,
            reservation_items: {
              pk: number,
              start: Date,
              end: Date, 
            }[]
          }]
        */
      },
    },
    emits: ['reservation-canceled'],
    setup(props, { emit }) {
      const reservation_items = ref(props.reservation.reservation_items); // all reservation items from the passed reservation (prop)
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      const selected_items = ref([]); // all selected reservation items in the table

      /**
       * Select/Deselect a reservation item.
       * @param {number} key - primary key of the reservation item
       */
      const toggle_item = (key) => {
        if (selected_items.value.includes(key)) {
          selected_items.value = selected_items.value.filter((i) => i !== key);
        } else {
          selected_items.value.push(key);
        }
      };

      /**
       * Select/Deselect all reservation items.
       */
      const toggle_all = () => {
        if (selected_items.value.length === 0) {
          selected_items.value = reservation_items.value.map((i) => i.pk);
        } else {
          selected_items.value = [];
        }
      };

      /**
       * Cancel all selected reservation items. Make a post request to the server for this action.
       * After that remove all canceled reservation items from the reservation_items array and clear the selected_items array.
       */
      const cancel_reservation_items = async () => {
        try {
          if (selected_items.value.length === 0) {
            throw new Error('Keine Reservierungen ausgewählt');
          }

          const res = await axios.post(api_routes.cancel_reservation_items, {
            reservation_item_ids: selected_items.value,
          });

          const data = await res.data;

          if (data.status === 'success') {
            show_toast('success', 'Reservierungen erfolgreich storniert');

            reservation_items.value = reservation_items.value.filter((i) => !selected_items.value.includes(i.pk));

            emit('reservation-canceled', {
              pk: props.reservation.pk,
              reservation_items: selected_items.value,
            });
            selected_items.value = [];
          } else if (data.status === 'error') {
            show_toast('error', data.message);
          } else {
            Logger.log('server response format error');
            show_toast('error', 'Server antwortet nicht korrekt');
          }
        } catch (error) {
          show_toast('error', error.message);
          Logger.log(error);
        }
      };

      return {
        date_to_str,
        reservation_items,
        selected_items,
        toggle_item,
        toggle_all,
        cancel_reservation_items,
        is_toast_shown,
        toast_type,
        toast_msg,
      };
    },
  };
</script>

<style scoped>
  .reservation-summary {
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 0.7em;
  }

  .reservation-summary .comment-group {
    padding: 0.5em;
    align-self: start;
  }

  .reservation-summary .comment-group .comment-label {
    color: #9d9d9d;
    font-size: 0.8em;
  }

  .reservation-summary .btn-cancel-reservation-items {
    margin-top: 1.5em;
    font-size: 0.9em;
  }

  .reservation-summary .sub-reservations-table {
    width: 100%;
    font-size: 1.1em;
    border-collapse: separate;
    border-spacing: 0 0.6em;
  }

  .reservation-summary .sub-reservations-table tbody {
    color: #b5b5b5;
  }

  .reservation-summary .sub-reservations-table thead tr th {
    color: #9d9d9d;
    font-weight: normal;
    font-size: 0.8em;
    text-align: start;
  }

  .reservation-summary .sub-reservations-table thead tr th.select-all-none {
    color: #ffa57d;
    cursor: pointer;
  }

  .reservation-summary .sub-reservations-table thead tr th.select-all-none:hover {
    color: #ff6f31;
  }

  .reservation-summary .sub-reservations-table td {
    text-align: start;
    padding: 0.5em 0.7em;
  }

  .reservation-summary .sub-reservations-table tbody tr {
    border-radius: 0.3em;
    font-size: 0.85em;
  }

  .reservation-summary .sub-reservations-table tbody tr td {
    background-color: #fff;
  }

  .reservation-summary .sub-reservations-table tbody tr td:first-child {
    padding-left: 0.5em;
    border-radius: 0.4em 0 0 0.4em;
    background-color: #fff;
  }

  .reservation-summary .sub-reservations-table tbody tr td:last-child {
    border-radius: 0 0.4em 0.4em 0;
    background-color: #fff;
  }

  .reservation-summary .sub-reservations-table tbody tr td .icon {
    position: relative;
    top: 0.2em;
  }

  .reservation-summary .sub-reservations-table tbody tr td .icon.mark {
    color: #ffa57d;
    cursor: pointer;
  }

  @media (min-width: 750px) {
    .reservation-summary {
      font-size: 0.9em;
      padding: 1em;
    }
  }
</style>
