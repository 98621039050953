<template>
  <button :style="{ width: width }" @click="$emit('btn-click', $event)">
    <slot></slot>
  </button>
</template>

<script>
  /**
   * Base Button component to be used by other child components.
   * Emits:
   *  - btn-click: when the button is clicked
   * Slots: default slot
   */
  export default {
    name: 'Button',
    props: {
      width: {
        type: String,
        required: false,
      },
    },
    emits: ['btn-click'],
  };
</script>

<style scoped>
  button {
    font-size: 0.65em;
    background-color: #ff8e5d;
    border: none;
    border-radius: 1em;
    padding: 0.1em 1em;
    color: #f6f6f6;
    cursor: pointer;
    display: flex;
    place-content: center;
    place-items: center;
  }

  button:hover {
    background-color: #ff783d;
  }
</style>
