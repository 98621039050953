<template>
  <Icon
    v-if="is_selected"
    icon="akar-icons:circle-check"
    class="favorite-icon-component sel"
    @click="$emit('toggle')"
  />
  <Icon v-else icon="akar-icons:circle" class="favorite-icon-component no-sel" @click="$emit('toggle')" />
</template>

<script>
  import { Icon } from '@iconify/vue';

  /**
   * Select icon with toggle functionality.
   * Circle with checkmark if selected.
   * Hollow circle if not selected.
   * Emits toggle event when clicked.
   */
  export default {
    name: 'SelectIcon',
    components: { Icon },
    props: {
      is_selected: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['toggle'],
  };
</script>

<style scoped>
  .favorite-icon-component {
    font-size: 1em;
    cursor: pointer;
  }

  .sel {
    color: #ff7f48;
  }

  .sel:hover {
    color: #ff5a14;
  }

  .no-sel {
    color: #9d9d9d;
  }

  .no-sel:hover {
    color: #5d5d5d;
  }
</style>
