import { DEBUG, api_routes } from '@/config';

import axios from 'axios';
import dayjs from 'dayjs';

/**
 * LOGGER CLASS
 * Logs to console if DEBUG is true
 */
export class Logger {
  /**
   * Log to console if DEBUG is set to true
   * @param  {...any} params - any printable objects
   */
  static log(...params) {
    if (DEBUG) {
      console.log('### LOGGER', dayjs().format('YYYY-MM-DD HH:mm:ss'), '###\n');
      params.forEach((param) => console.log(param));
    }
  }
}

/**
 * Convert datetime to string
 * @param {Date} dt - Datetime to be converted to string
 * @returns string corresponding to datetime
 */
export function date_to_str(dt) {
  return dayjs(dt).format('DD.MM.YYYY HH:mm');
}

/**
 * Convert datetime string to Date. Must be in format of DD.MM.YYYY HH:mm
 * @param {string} dt_str - string to be turned into date
 * @returns datetime corresponding to string
 */
export function str_to_date(dt_str) {
  const dt = dayjs(dt_str, 'DD.MM.YYYY HH:mm');

  if (!dt.isValid()) {
    throw new Error(`Invalid date: ${dt_str}`);
  } else {
    return dt.toDate();
  }
}

/**
 * Check if child is inside parent HTML element.
 * @param {HTMLElement} parent - Supposed HTML parent element
 * @param {HTMLElement} child  - Supposed HTML child element
 * @returns true if child is inside of parent
 */
export function is_inside(parent, child) {
  const prect = parent.getBoundingClientRect();
  const crect = child.getBoundingClientRect();
  return (
    crect.top >= prect.top && crect.bottom <= prect.bottom && crect.left >= prect.left && crect.right <= prect.right
  );
}

/**
 * Get German month names.
 * @returns array of German month names
 */
export function month_names() {
  return [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];
}

/**
 * Get German weekday shorthands.
 * @returns shorthands array of German weekday names
 */
export function weekday_names() {
  return ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
}

/**
 * Format a datetime range start to end in format "DD.MM.YYYY HH:mm - DD.MM.YYYY HH:mm"
 * @param {Date} start - start datetime
 * @param {Date} end - end datetime
 * @returns formated datetime string from start to end
 */
export function format_start_end(start, end) {
  return `${date_to_str(start).split(' ')[1]} - ${date_to_str(end).split(' ')[1]}`;
}

/**
 * Fetch occupation data from backend.
 * @param {number} seat_id - id of seat corresponding to backend
 * @returns {Promise<{pk: number; start: Date, end: Date, seat_pk: number, seat_full_name: string, seat_description: string, user_name: string, is_favorite: boolean}[]>} occupation data array from backend
 */
export async function fetch_occupation(seat_id) {
  try {
    const res = await axios.get(api_routes.occupation, {
      params: {
        seat_id: seat_id,
      },
    });

    const response = await res.data;
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Turn occupation data into an event array used by some calendar components.
 * @param {{pk: number; start: Date, end: Date, seat_pk: number, seat_full_name: string, seat_description: string, user_name: string, is_favorite: boolean}[]} occupation - occupation data array
 * @returns {{description: string, start: Dayjs, end: Dayjs}[]} array of events used by calendar components
 */
export function occupation_to_calendar_events(occupation) {
  const events = [];
  for (let reservation of occupation) {
    for (let item of reservation.reservation_items) {
      events.push({
        description: reservation.user_full_name,
        start: dayjs(item.start),
        end: dayjs(item.end),
      });
    }
  }

  return events;
}

/**
 * Check if some datetime ranges between start and end.
 * @param {Dayjs} start - start datetime
 * @param {Dayjs} end - end datetime
 * @param {{start: Dayjs, end: Dayjs}[]} start_end_arr - datetime ranges to check if they lay between start and end
 * @returns true if at least one element of start_end_arr is between start and end, false if not
 */
export function are_some_between(start /* dayjs */, end /* dayjs */, start_end_arr /* [start: dayjs, end: dayjs]) */) {
  return start_end_arr.length === 0
    ? false
    : start_end_arr.some(
        (item) => start.isBetween(item.start, item.end, null, '[]') || end.isBetween(item.start, item.end, null, '[]')
      );
}

/**
 * get dimensions of an html element for just the content without margin, padding and border
 * @param {HTMLElement} node
 */
export function inner_dimensions(node) {
  const computed_style = getComputedStyle(node);

  let width = node.clientWidth; // width with padding
  let height = node.clientHeight; // height with padding

  height -= parseFloat(computed_style.paddingTop) + parseFloat(computed_style.paddingBottom);
  width -= parseFloat(computed_style.paddingLeft) + parseFloat(computed_style.paddingRight);
  return { height, width };
}

/**
 * Returns the image dimensions of the seat plan according to the current screen size.
 * @param {number} image_src_dims: dimensions of the image in the database -> { width, height }
 * @param {HTMLElement} svg_container: the html element parenting the svg
 * @param {number} min_scale: the minimum scale factor for the image (default: 0.5 -> half size of the source dimensions)
 * @returns {object} { width, height, x_scale, y_scale }
 */
export function get_image_dimensions(image_src_dims, svg_container, min_scale = 0.5) {
  const min_width = Math.floor(image_src_dims.width * min_scale);
  const min_height = Math.floor(image_src_dims.height * min_scale);

  let new_width = 0;
  let new_height = 0;

  const { width: content_width, height: content_height } = inner_dimensions(svg_container);

  // case 1 - screen is bigger than the image
  if (content_width > image_src_dims.width && content_height > image_src_dims.height) {
    new_width = image_src_dims.width;
    new_height = image_src_dims.height;
  }
  // case 2 - screen is smaller than the image
  else if (content_width < image_src_dims.width || content_height < image_src_dims.height) {
    // check which dimension has a greater difference to the src dimension
    const diff_width = image_src_dims.width - content_width;
    const diff_height = image_src_dims.height - content_height;

    if (diff_width > diff_height) {
      new_width = content_width < min_width ? min_width : content_width;
      new_height = Math.floor(image_src_dims.height * (new_width / image_src_dims.width));
    } else {
      new_height = content_height < min_height ? min_height : content_height;
      new_width = Math.floor(image_src_dims.width * (new_height / image_src_dims.height));
    }
  }

  return {
    width: new_width,
    height: new_height,
    x_scale: new_width / image_src_dims.width,
    y_scale: new_height / image_src_dims.height,
  };
}

/**
 * This function returns modiefied scaled data for the seat preview.
 * For example it modiefies the x, y, width, height of the seat position to fit the svg image dimensions.
 * @param {object} seat_dims: the dimensions of the seat element -> {x, y, width, height}
 * @param {object} image_dims: the dimensions of the image -> {width, height}
 * @param {HTMLElement} svg_container: the html element parenting the svg
 * @returns {object} modified dimensions -> {x, y, width, height}
 */
export function get_new_seat_location(seat_dims, image_dims, svg_container) {
  const { x: el_x, y: el_y, width: el_width, height: el_height } = seat_dims;
  const { x_scale, y_scale } = get_image_dimensions(image_dims, svg_container);

  // find out delta x, y and scaled width and height
  const new_x = Math.floor(el_x * x_scale);
  const new_width = Math.floor(el_width * x_scale);

  const new_y = Math.floor(el_y * y_scale);
  const new_height = Math.floor(el_height * y_scale);

  return {
    x: new_x,
    y: new_y,
    width: new_width,
    height: new_height,
  };
}
