<template>
  <div class="root">
    <div class="booking-card">
      <!-- Single/Multiple booking icon -->
      <div class="header-group">
        <SelectIcon
          v-if="!is_single_booking"
          class="sel-icon"
          :is_selected="is_selected"
          @toggle="$emit('toggle-offer', offer.offer_id)"
        />
        <div v-else></div>

        <!-- Booking offer information -->
        <div class="seat-info">
          <h3>{{ offer.seat_name }}</h3>
          <p>{{ offer.description }}</p>
        </div>

        <FavoriteIcon
          :is_favorite="offer.is_favorite"
          class="fav-icon"
          @toggle="$emit('toggle-favorite', offer.seat_id)"
        />
      </div>

      <div class="offertimes" @click="show_info_overlay('Zeitraum')">
        <p>{{ date_to_str(offer.start).split(' ')[0] }}</p>
        <p>{{ date_to_str(offer.start).split(' ')[1] }}</p>
        <p>{{ date_to_str(offer.end).split(' ')[0] }}</p>
        <p>{{ date_to_str(offer.end).split(' ')[1] }}</p>
      </div>

      <!-- Action buttons -->
      <div :class="`actions-group ${is_single_booking ? 'single' : 'multiple'}`">
        <button class="show-info" @click="show_info_overlay('Belegung')">Belegung ></button>
        <button class="show-info" @click="show_info_overlay('Sitzplan')">Sitzplan ></button>
        <Button class="btn-booking" v-if="is_single_booking" @click="show_booking_form">Buchen</Button>
      </div>
    </div>

    <!-- Overlay with further booking information -->
    <div class="overlay">
      <BookingOverlay
        v-if="is_info_overlay_shown"
        :start_page="info_overlay_start"
        @close-overlay="close_overlays"
        @to-booking="show_booking_form"
        @timeframe-change="(params) => $emit('timeframe-change', params)"
        @next-offer="(params) => on_which_offer('next-offer', params)"
        @prev-offer="(params) => on_which_offer('prev-offer', params)"
        :offer="offer"
      />

      <!-- Form for booking a single seat -->
      <SingleBookingForm
        v-if="is_booking_form_shown"
        @close-overlay="close_overlays"
        :offer="offer"
        @booking-response="on_booking_response"
      />
    </div>
  </div>
</template>

<script>
  import { ref, toRef } from '@vue/reactivity';

  import { date_to_str } from '@/util';

  import Button from '@/components/general/Button.vue';
  import BookingOverlay from '@/components/view-members/booking/BookingOverlay.vue';
  import SingleBookingForm from '@/components/view-members/booking/SingleBookingForm.vue';
  import FavoriteIcon from '@/components/general/FavoriteIcon.vue';
  import SelectIcon from '@/components/general/SelectIcon.vue';
  import { watch } from '@vue/runtime-core';

  /**
   * Component for displaying a single booking offer.
   * This includes all information about the offer, as well as the action buttons.
   * Action buttons:
   *   - Change the timeframe of the offer & show occupation
   *   - Own occupation overlay page
   *   - Seat plan with the offer seat marked
   *   - Form for booking a single seat
   */
  export default {
    name: 'BookingCard',
    components: {
      FavoriteIcon,
      SelectIcon,
      Button,
      BookingOverlay,
      SingleBookingForm,
    },
    props: {
      is_single_booking: {
        // flag for single or multiple booking
        type: Boolean,
        default: true,
      },
      is_selected: {
        // flag if the offer is selected in multiple booking mode
        type: Boolean,
        default: false,
      },
      offer: {
        // the offer to be displayed
        type: Object,
        required: true,
        /*
          {
            offer_id: int,
            seat_id: int,
            seat_name: string,
            description: string,
            start: Date,
            end: Date,
            is_favorite: bool,
            is_owned: bool,
          }
        */
      },
      overlay_start: {
        // menu item to start with when opening the booking overlay
        // Menu-items: 'Belegung', 'Sitzplan', 'Zeitraum'
        type: String,
        default: '',
      },
    },
    emits: ['toggle-favorite', 'toggle-offer', 'booking-response', 'timeframe-change', 'prev-offer', 'next-offer'],
    setup(props, { emit }) {
      const soverlay_start = toRef(props, 'overlay_start'); // start page of the overlay
      const is_info_overlay_shown = ref(false); // flag if the info overlay is shown 
      const info_overlay_start = ref('Zeitram'); // start page of the info overlay
      const is_booking_form_shown = ref(false); // flag if the booking form is shown

      /**
       * Show the info overlay with specified start page.
       * @param {string} start_page - start page of the info overlay
       */
      const show_info_overlay = (start_page) => {
        info_overlay_start.value = start_page;
        is_info_overlay_shown.value = true;
        is_booking_form_shown.value = false;
      };

      /**
       * Show the booking form.
       * @param {string} start_page - start page of the info overlay. Unused variable which can be removed.
       */
      const show_booking_form = (start_page) => {
        info_overlay_start.value = start_page;
        is_info_overlay_shown.value = false;
        is_booking_form_shown.value = true;
      };

      if (soverlay_start.value && soverlay_start.value !== '') {
        show_info_overlay(soverlay_start.value);
      }

      /**
       * Watcher to show the info overlay. The reason for this watcher is that if the props change the ui is updated.
       */
      watch(soverlay_start, () => {
        if (soverlay_start.value !== null && soverlay_start.value !== undefined && soverlay_start.value !== '') {
          show_info_overlay(soverlay_start.value);
        }
      });

      /**
       * Close all overlays.
       */
      const close_overlays = () => {
        is_info_overlay_shown.value = false;
        is_booking_form_shown.value = false;
      };

      /**
       * 
       */
      const on_which_offer = (which, params) => {
        emit(which, params);
        close_overlays();
      };

      const on_booking_response = (response) => {
        emit('booking-response', response);
      };

      return {
        is_info_overlay_shown,
        is_booking_form_shown,
        info_overlay_start,
        show_info_overlay,
        show_booking_form,
        close_overlays,
        date_to_str,
        on_booking_response,
        on_which_offer,
      };
    },
  };
</script>

<style scoped>
  .root {
    font-size: 0.85em;
    height: 100%;
    max-height: 14.706em;
    width: clamp(21.324em, 100%, 25em);
  }

  .overlay {
    font-size: 1rem;
  }

  .booking-card {
    background-color: #fff;
    padding: 0.6em 0.6em 1.2em 0.6em;
    border-radius: 0.4em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);

    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 2.2em;
  }

  .booking-card .header-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .booking-card .header-group .fav-icon {
    font-size: 1.2em;
  }

  .booking-card .header-group .sel-icon {
    font-size: 1.2em;
  }

  /* .booking-card .header-group .icon {
    color: #9d9d9d;
    font-size: 1.1em;
    cursor: pointer;
  }

  .booking-card .header-group .icon:hover {
    color: #5d5d5d;
  } */

  .booking-card .header-group .seat-info {
    display: flex;
    flex-direction: column;
    place-items: center;
  }

  .booking-card .header-group .seat-info h3 {
    font-weight: 500;
  }

  .booking-card .header-group .seat-info p {
    font-size: 0.8em;
    color: #9d9d9d;
  }

  .booking-card .offertimes {
    font-size: 0.9em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;

    padding: 0.2em 0.4em;
    background-color: #aaa;
    border-radius: 0.3em;
    width: 55%;
    color: white;
    cursor: pointer;
  }

  .booking-card .offertimes:hover {
    background-color: #c5c5c5;
  }

  .booking-card .actions-group.single {
    display: flex;
    width: 90%;
    justify-content: space-between;
  }

  .booking-card .actions-group.multiple {
    display: flex;
    width: 90%;
    justify-content: space-around;
  }

  .booking-card .actions-group .show-info {
    font-size: 0.9em;
    color: #ff7f48;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .booking-card .actions-group .show-info:hover {
    color: #ff4d00;
  }

  .booking-card .actions-group .btn-booking {
    font-size: 0.9em;
  }
</style>
