<template>
  <div class="root">
    <div class="card-content">

      <h2 class="title">{{ title }}</h2>
      <p class="descr">
        Hier wird die Belegung für einen einzigen Tag angezeigt. Es werden nur Plätze ohne Besitzer oder freigegebene
        berücksichtigt.
      </p>

      <!-- Occupation data for the selected day -->
      <div class="content" v-if="!is_loading">
        <DoughnutChart :chartData="chart_data" :height="200" />
        <div class="data-group" v-if="data !== null && data !== undefined">
          <label class="lbl">Frei:</label>
          <p class="data">{{ data.free_seats }} / {{ data.total_seats }} Plätzen</p>
          <label class="lbl">Belegt:</label>
          <p class="data">{{ data.occupied_seats }} / {{ data.total_seats }} Plätzen</p>
          <label class="lbl">Belegt in %:</label>
          <p class="data">{{ Math.round(data.occupation_percentage) }} %</p>
        </div>
      </div>
      
    </div>

    <Spinner v-if="is_loading" :is_loading="is_loading" />
  </div>
</template>

<script>
  import { ref } from '@vue/reactivity';
  import { Chart, registerables } from 'chart.js';
  import { api_routes } from '@/config';
  import axios from 'axios';
  import { Logger } from '@/util';
  import Spinner from '@/components/general/Spinner.vue';
  import { onMounted } from '@vue/runtime-core';
  import { DoughnutChart } from 'vue-chart-3';

  Chart.register(...registerables); // Register the doughnut chart type with chart.js

  /**
   * Shows information about the occupation for the given date.
   * Also displays a pie chart with the occupation data.
   */
  export default {
    components: {
      Spinner,
      DoughnutChart,
    },
    props: {
      date: {
        type: Object, // dayjs object
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      const data = ref(null);
      const is_loading = ref(true);
      const chart_data = ref(null);

      /**
       * Loads the occupation data for the given date prop and sets the data ref to it.
       */
      const fetch_data = async (date) => {
        try {
          const res = await axios.get(api_routes.occupation_statistics, {
            params: {
              start: date.format('YYYY-MM-DD'),
              end: date.format('YYYY-MM-DD'),
            },
          });
          const res_data = await res.data;
          if (res_data.length === 0) {
            throw new Error('No data found');
          }
          if (res_data.length > 1) {
            throw new Error('More than one data entry found');
          }

          data.value = res_data[0];
        } catch (error) {
          // TODO: show toast
          Logger.log(error);
        }
      };

      /**
       * Setup chart data and color palette.
       */
      onMounted(async () => {
        try {
          is_loading.value = true;
          await fetch_data(props.date);

          // chart setup
          chart_data.value = {
            labels: ['Belegt', 'Frei'],
            datasets: [
              {
                data: [data.value.occupied_seats, data.value.free_seats],
                backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.2)'],
                borderColor: ['rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)'],
                borderWidth: 1,
              },
            ],
          };
        } catch (error) {
          // TODO: show toast
          Logger.log(error);
        } finally {
          is_loading.value = false;
        }
      });

      return {
        data,
        is_loading,
        chart_data,
      };
    },
  };
</script>

<style scoped>
  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-evenly;
  }

  .title {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .data-group {
    font-size: 1rem;
  }

  .descr {
    font-size: 0.8rem;
    color: #6b6b6b;
    margin-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .lbl {
    font-size: 0.7rem;
    color: #ff8d5c;
  }

  .data {
    font-size: 0.9rem;
    margin-bottom: 0.8rem;
    color: #464646;
  }
</style>
