<template>
  <Overlay>
    <!-- Header information -->
    <template v-slot:header>
      <div class="seat-list-header">
        <div class="main-header">
          <h2 class="seat-name">{{ seat.full_name }}</h2>
        </div>
        <p class="descr">{{ seat.description }}</p>
      </div>
    </template>

    <!-- Menu choices -->
    <template v-slot:menu>
      <SliderMenu
        :menu_items="['Belegung', 'Sitzplan']"
        class="overlay-menu"
        :active_start="active_menu_item"
        @menu-click="on_menu_click"
        v-if="!own_seat"
      />
    </template>

    <!-- Sub-pages -->
    <template v-slot:content>
      <SeatOccupation v-if="active_menu_item === 'Belegung'" :seat_id="seat.pk" />
      <div class="spinner-wrapper" v-if="active_menu_item === 'Sitzplan' && seat_preview_loading">
        <Spinner :is_loading="seat_preview_loading" />
      </div>
      <SeatPreview
        v-if="active_menu_item === 'Sitzplan'"
        :seat_ids="[seat.pk]"
        @loaded="seat_preview_loading = false"
      />
    </template>
  </Overlay>
</template>

<script>
  import { ref } from 'vue';

  import Overlay from '@/components/general/Overlay.vue';
  import SliderMenu from '@/components/general/SliderMenu.vue';
  import SeatOccupation from '@/components/view-members/booking/SeatOccupation.vue';
  import SeatPreview from '@/components/general/SeatPreview.vue';
  import Spinner from '@/components/general/Spinner.vue';

  /**
   * Overlay to display information about a seat.
   * This includes:
   *   - Belegung - occupation of the seat
   *   - Sitzplan - preview of the seat
   */
  export default {
    name: 'SeatListOverlay',
    components: {
      Overlay,
      SliderMenu,
      SeatOccupation,
      SeatPreview,
      Spinner,
    },
    props: {
      start_page: {
        // page which the overlay should be opened with
        type: String,
        default: 'Belegung',
        // TODO: add validator
      },
      seat: {
        // seat to display information for
        type: Object,
        required: true,
        /*
          {
            pk: number,
            full_name: string,
            description: string,
            is_favorite: boolean
          }
        */
      },
      own_seat: {
        // flag to indicate if the current user is the seats owner
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const active_menu_item = ref(props.start_page); // page which the overlay should be opened with
      const seat_preview_loading = ref(true); // loading flag for the seat preview component

      /**
       * Open a named sub-page of the overlay.
       * @param {string} item - menu point for sub-page of overlay
       */
      const on_menu_click = (item) => {
        active_menu_item.value = item;

        if (item === 'Sitzplan') {
          seat_preview_loading.value = true;
        }
      };

      return { active_menu_item, on_menu_click, seat_preview_loading };
    },
  };
</script>

<style scoped>
  .seat-list-header {
    text-align: center;
  }

  .seat-list-header .main-header {
    display: flex;
    gap: 0.7em;
    align-items: center;
    justify-content: center;
  }

  .seat-list-header .main-header .icon {
    color: #555;
    cursor: pointer;
  }

  .seat-list-header .main-header .icon:hover {
    color: #858585;
  }

  .seat-list-header .main-header .seat-name {
    font-size: 0.938em;
    font-weight: 500;
  }

  .seat-list-header .descr {
    color: #9d9d9d;
    font-size: 0.625em;
  }

  .overlay-menu {
    font-size: 0.9em;
    margin: 0 auto;
  }

  @media (min-width: 650px) {
    .seat-list-header {
      font-size: 1.2em;
    }

    .overlay-menu {
      font-size: 1.1em;
    }
  }
</style>
