import { createStore } from 'vuex';

import axios from 'axios';

import { api_routes, TOKEN_KEY } from '@/config';
import { Logger } from '@/util';

export default createStore({
  /**
   * Definition of the state object of the store
   *
   * @property {string} auth_token - The auth token of the user.
   * @property {ISerNotification[]} notifications - The notifications of the user.
   * @property {boolean} can_see_statistics - Whether the user has the permission to see the statistics page.
   */
  state: {
    // Authentication token from django rest framework. Stored in local storage.
    auth_token: localStorage.getItem(TOKEN_KEY) || '',

    // Notifications from the backend.
    notifications: [],

    // Whether the user has the permission to see the statistics page.
    can_see_statistics: false,
  },
  getters: {
    /**
     * Get the auth token of the user.
     * @param state The state of the store.
     * @returns {string} The auth token of the user.
     */
    auth_token: (state) => state.auth_token,

    /**
     * Get the login state of the user.
     * @param state The state of the store.
     * @returns {boolean} Wheter the user is logged in.
     */
    is_logged_in: (state) => state.auth_token !== '',

    /**
     * Get all notifications of the user.
     * @param state The state of the store.
     * @returns All notifications of the user.
     */
    notifications: (state) => state.notifications,

    /**
     * Get the number of unread notifications of the user.
     * @param state The state of the store.
     * @returns {number} The number of unread notifications.
     */
    num_unread_notifications: (state) => (state.notifications ? state.notifications.map((n) => !n.is_read).length : 0),

    /**
     * Get whether the user has the permission to see the statistics page.
     * @param state The state of the store.
     * @returns {boolean} Whether the user has the permission to see the statistics page.
     */
    can_see_statistics: (state) => state.can_see_statistics,
  },
  mutations: {
    /**
     * Set the authentialication token of the user in the store, as axios default header and in local storage.
     * @param state The state of the store.
     * @param token The auth token of the user.
     */
    set_auth_token(state, token) {
      localStorage.setItem(TOKEN_KEY, token);
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
      state.auth_token = token;
    },

    /**
     * Remove the auth token of the user from the store, as axios default header and from local storage.
     * @param state The state of the store.
     */
    remove_auth_token(state) {
      localStorage.removeItem(TOKEN_KEY);
      axios.defaults.headers.common['Authorization'] = '';
      state.auth_token = '';
    },

    /**
     * Set the notifications of the user in the store.
     * This action is called from an async fetch action in the store.
     * @param state The state of the store.
     * @param notifications The notifications of the user.
     */
    set_notifications(state, notifications) {
      state.notifications = notifications;
    },

    /**
     * Remove a notification from the store's notifications state.
     * @param state The state of the store.
     * @param notification The notification to remove from the user's notifications.
     */
    remove_notification(state, notification) {
      state.notifications = state.notifications.filter((n) => n.pk !== notification.pk);
    },

    /**
     * Mark a notification as read only in the store's notifications state.
     * @param state The state of the store.
     * @param notification The notification to mark as read.
     */
    read_notification(state, notification) {
      state.notifications.forEach((n) => {
        if (n.pk === notification.pk) n.is_read = true;
      });
    },

    /**
     * Set the permission to see the statistics page in the store.
     * @param state The state of the store.
     * @param can_see_statistics Whether the user has the permission to see the statistics page.
     */
    set_can_see_statistics(state, can_see_statistics) {
      state.can_see_statistics = can_see_statistics;
    },
  },
  actions: {
    /**
     * Authenticate the user to the backend using the given credentials.
     * @param commit The commit function of the store.
     * @param credentials The credentials of the user.
     */
    async authenticate({ commit }, credentials /* { username, password} */) {
      commit('remove_auth_token');

      // fetch token from django rest framework
      try {
        const res = await axios.post(api_routes.obtain_token, {
          username: credentials.username,
          password: credentials.password,
        });

        const data = await res.data;
        commit('set_auth_token', data.token);
      } catch (error) {
        Logger.log(error);
      }
    },

    /**
     * Logout the user.
     * @param commit The commit function of the store.
     */
    logout({ commit }) {
      commit('remove_auth_token');
    },

    /**
     * Verify the validity of the auth token with the backend.
     * @param commit The commit function of the store.
     */
    async verify_token({ commit }) {
      try {
        await axios.get(api_routes.verify_token);
      } catch (error) {
        Logger.log(error);
        commit('remove_auth_token');
      }
    },

    /**
     * Fetch the notifications of the user from the backend.
     * @param commit The commit function of the store.
     */
    async fetch_notifications({ commit }) {
      try {
        const res = await axios.get(api_routes.notifications);
        commit('set_notifications', await res.data);
      } catch (error) {
        Logger.log(error);
      }
    },

    /**
     * Delete a notification from the backend and remove it from the store.
     * @param commit The commit function of the store.
     * @param notification The notification to send to the backend to delete.
     */
    async delete_notification({ commit }, notification) {
      try {
        await axios.delete(api_routes.notifications, {
          data: {
            notification_id: notification.pk,
          },
        });
        commit('remove_notification', notification.pk);
      } catch (error) {
        Logger.log(error);
      }
    },

    /**
     * Mark a notification as read in the backend and in the store.
     * @param commit The commit function of the store.
     * @param notification The notification to send to the backend to mark as read.
     */
    async read_notification({ commit }, notification) {
      try {
        await axios.put(api_routes.notifications, {
          notification_id: notification.pk,
        });
        commit('read_notification', notification);
      } catch (error) {
        Logger.log(error);
      }
    },

    /**
     * Fetch the permission to see the statistics page from the backend.
     * If the user has the permission the stores can_see_statistics state is set to true.
     * @param commit The commit function of the store.
     */
    async fetch_can_see_statistics({ commit }) {
      try {
        if (!this.state.auth_token) return;

        const res = await axios.get(api_routes.can_see_statistics);
        commit('set_can_see_statistics', await res.data);
      } catch (error) {
        Logger.log(error);
      }
    },
  },
});
