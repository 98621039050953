<template>
  <div class="icon-input-box">
    <label :for="name">{{ name }}</label>

    <!-- Left arrow for subtracting one day -->
    <Icon icon="dashicons:arrow-left-alt2" class="icon" @click="swipe_date(-1)" />

    <!-- Datetime input - but default type still text -->
    <div class="input-box">
      <input
        :type="type"
        :name="name"
        :placeholder="`${name}...`"
        :value="modelValue"
        @input="this.$emit('update:modelValue', $event.target.value)"
        @blur="on_focus_change"
      />

      <!-- Datetime picker -->
      <div class="datepicker-container">
        <button class="btn-toggle-calendar" ref="toggle_btn" @click.stop="show_dt_picker = !show_dt_picker">
          <Icon icon="carbon:calendar-heat-map" class="icon" />
        </button>
        <div class="datepicker" ref="datepicker" v-click-away="hide_date_picker">
          <DatePicker mode="dateTime" v-model="date_picker_val" v-if="show_dt_picker" :is24hr="true" />
        </div>
      </div>
    </div>

    <!-- Right arrow for adding one day -->
    <Icon icon="dashicons:arrow-right-alt2" class="icon" @click="swipe_date(1)" />
  </div>
</template>

<script>
  import { Icon } from '@iconify/vue';
  import { DatePicker } from 'v-calendar';

  import { str_to_date, date_to_str } from '@/util';
  import dayjs from 'dayjs';
  import { computed, ref, toRef, onMounted } from 'vue';

  /**
   * DateTimeInput component with a datetime picker.
   */
  export default {
    name: 'DateTimeInput',
    components: {
      Icon,
      DatePicker,
    },
    props: {
      name: {
        // label of the input field
        type: String,
        required: true,
      },
      type: {
        // html5 input type
        type: String,
        required: false,
        default: 'text',
      },
      modelValue: {
        // v-model value
        type: String,
        required: true,
      },
      default_value: {
        // default value of the input field
        type: String,
        required: true,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const show_dt_picker = ref(false); // flag to show the date picker
      const datepicker = ref(null);
      const toggle_btn = ref(null);

      const model_val = toRef(props, 'modelValue'); // v-model value

      /**
       * Function to hide the datetime picker
       */
      const hide_date_picker = () => {
        show_dt_picker.value = false;
      };

      /**
       * Function to handle focus change on the input field.
       * If the value is not valid reset it to the default value.
       */
      const on_focus_change = () => {
        try {
          str_to_date(model_val.value);
        } catch {
          emit('update:modelValue', props.default_value);
        }
      };

      /**
       * Update the date value of the input field by the specified amount of days.
       * @param {number} days - number of days to add or subtract
       */
      const swipe_date = (days) => {
        let target_val = model_val.value;

        try {
          target_val = str_to_date(target_val);
        } catch (error) {
          target_val = str_to_date(props.default_value);
        }

        const new_date = date_to_str(dayjs(target_val).add(days, 'day').toDate());

        emit('update:modelValue', new_date);
      };

      /**
       * Computed property to handle the interface between the v-model value and the date picker.
       */
      const date_picker_val = computed({
        get: () => {
          let target_val = model_val.value;

          try {
            target_val = str_to_date(target_val);
          } catch (error) {
            target_val = str_to_date(props.default_value);
          }

          return target_val;
        },

        set: (val) => {
          if (val === null) {
            return;
          }
          emit('update:modelValue', date_to_str(val));
        },
      });

      onMounted(() => {
        // move the datepicker back inside the body width, to prevent the side scrolling (if possible)
        new ResizeObserver(() => {
          if (!show_dt_picker.value) return;

          const pbox = datepicker.value.getBoundingClientRect();

          const doc_width = document.body.clientWidth;

          if (pbox.right > doc_width) {
            const offset = pbox.right - doc_width + 5;

            if (pbox.left - offset < 0) return;

            // this is set to subtract the offset from the left position, because it is relative and no absolute pixel position can be set
            datepicker.value.style.left = `-${offset}px`;
          }
        }).observe(datepicker.value);
      });

      return { on_focus_change, swipe_date, show_dt_picker, date_picker_val, hide_date_picker, datepicker, toggle_btn };
    },
  };
</script>

<style scoped>
  .icon-input-box {
    --font-size: 0.8em;
    --color: #afafaf;

    font-size: 0.8em;

    width: 17em;

    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    column-gap: 0.7em;
  }

  .icon-input-box label {
    color: #727272;
    font-size: 0.8em;
    margin-bottom: 0.2em;
    grid-column: span 3;
  }

  .icon-input-box .input-box {
    display: flex;
    place-items: center;
    gap: 0.4em;

    border-bottom: 1px solid var(--color);
    padding-right: 0.7em;
  }

  .icon-input-box .input-box .datepicker-container {
    position: relative;
  }

  .datepicker {
    position: absolute;
    z-index: 1000;
  }

  .icon {
    color: #727272;
    cursor: pointer;
  }

  .icon:hover {
    color: #959595;
  }

  .icon-input-box .input-box input {
    border: none;
    width: 100%;
    font-size: 0.9em;
    color: #727272;
    background-color: transparent;
  }

  .icon-input-box .input-box input::placeholder {
    color: var(--color);
  }

  .icon-input-box .input-box input:focus {
    outline: none;
  }

  .btn-toggle-calendar {
    border: none;
    background-color: transparent;
  }
</style>
