export const DEBUG = false;

export const TOKEN_KEY = 'inp-sbs-token';

// ROUTES
export const api_routes = {
  obtain_token: 'api/api-token-auth/', // obtain token - used for login
  verify_token: 'api/verify-token/',
  seat_list: 'api/seat-list/',
  favorite_seats: 'api/favorite-seats/',
  toggle_favorite: 'api/toggle-favorite/',
  user_data: 'api/user-data/',
  change_password: 'api/change-password/',
  booking_list: 'api/booking-list/',
  user_list: 'api/user-list/',
  place_reservation: 'api/place-reservation/',
  occupation: 'api/occupation/',
  own_seat: 'api/own-seat/',
  has_own_seat: 'api/has-own-seat/',
  reservations: 'api/reservations/',
  cancel_reservations: 'api/cancel-reservations/',
  cancel_reservation_items: 'api/cancel-reservation-items/',
  release_seat: 'api/release-seat/',
  delete_release: 'api/delete-release/',
  seat_preview: 'api/seat-preview/',
  notifications: 'api/notifications/',
  seat_plan: 'api/seat-plan/',
  occupation_list: 'api/occupation-list/',
  password_reset: 'api/password-reset/',
  password_reset_confirm: 'api/password-reset/confirm/',
  can_see_statistics: 'api/can-see-statistics/',
  occupation_statistics: 'api/occupation-statistics/',
  overlay_statistics: 'api/overlay-statistics/',
};
