<template>
  <div class="seat-lists">
    <Navbar />
    <div class="splitter-image" />

    <!-- Menu for sub-page selection -->
    <div class="slider-menu-container">
      <SliderMenu
        :menu_items="['Favoriten', 'Plätze', 'Sitzplan', 'Belegung']"
        :active_start="menu_item"
        @menu-click="(item) => (menu_item = item)"
      />
    </div>

    <!-- Sub-pages -->
    <SeatList :is_favorite="true" v-if="menu_item === 'Favoriten'" />
    <SeatList :is_favorite="false" v-if="menu_item === 'Plätze'" />
    <SeatPlanPage v-if="menu_item === 'Sitzplan'" />
    <Occupation v-if="menu_item === 'Belegung'" />
  </div>
</template>

<script>
  import { ref } from '@vue/reactivity';
  import { useRoute } from 'vue-router';

  import Navbar from '@/components/general/Navbar.vue';
  import SliderMenu from '@/components/general/SliderMenu.vue';
  import SeatList from '@/views/seat-lists/SeatList.vue';
  import SeatPlanPage from '@/views/seat-lists/SeatPlanPage.vue';
  import Occupation from '@/views/seat-lists/Occupation.vue';

  /**
   * Main wrapper page for the seat lists.
   * Subpages:
   *   - SeatList for Favorites
   *   - SeatList for all seats
   *   - SeatPlanPage for selection and view of the seat plan
   *   - Occupation for the occupation of all seats
   */
  export default {
    name: 'SeatListPage',
    components: {
      Navbar,
      SliderMenu,
      SeatList,
      SeatPlanPage,
      Occupation,
    },
    setup() {
      const route = useRoute();

      // currently active menu item - either passed route parameter (booking) or default value (favorites)
      const menu_item = ref(route.query?.start_page ? route.query.start_page : 'Favoriten');

      return { menu_item };
    },
  };
</script>

<style scoped>
  .seat-lists .slider-menu-container {
    /* font-size: 0.85em; */
    font-size: 1rem;
    display: flex;
    justify-content: center;
    margin: 2.125em auto;
  }
</style>
