<template>
  <div class="calendar-root">
    <div class="info-bar">
      <!-- Navigation actions buttons -->
      <div class="menu">
        <button class="btn" @click="today_month">Heute</button>
        <button class="btn" @click="prev_month"><Icon icon="ep:arrow-left-bold" /></button>
        <button class="btn" @click="next_month"><Icon icon="ep:arrow-right-bold" /></button>
      </div>

      <!-- Current datetime labels -->
      <div class="month">
        <span>{{ month }}</span>
        <span>{{ year }}</span>
      </div>
    </div>

    <!-- Actual html element used by the tui calendar -->
    <div class="calendar" ref="tplref_cal"></div>
  </div>
</template>

<script>
  import { ref } from '@vue/reactivity';
  import { onMounted, onUnmounted } from '@vue/runtime-core';
  import { month_names } from '@/util';

  import 'tui-calendar/dist/tui-calendar.css';
  import { default as TuiCalendar } from 'tui-calendar';
  import dayjs from 'dayjs';

  import { Icon } from '@iconify/vue';

  /**
   * Custom theme and implementation of the TuiCalendar calendar.
   */
  export default {
    name: 'Calendar',
    components: {
      Icon,
    },
    props: {
      // FIXME
      // delete CalendarView & DayView
      // update references
      items: {
        type: Array, // [{ description, start, end }]
        default: () => [],
      },
      // end old code
    },
    setup(props) {
      const tplref_cal = ref(null);
      const calendar = ref(null);
      const month = ref('');
      const year = ref('');

      const setup_calendar = () => {
        /**
         * setup calendar interface and add events
         */
        function getTimeTemplate(schedule, isAllDay) {
          var html = [];

          if (!isAllDay) {
            html.push('<strong>' + dayjs(schedule.start.getTime()).format('HH:mm') + '</strong> ');
          }
          if (schedule.isPrivate) {
            html.push('<span class="calendar-font-icon ic-lock-b"></span>');
            html.push(' Private');
          } else {
            if (schedule.isReadOnly) {
              html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
            } else if (schedule.recurrenceRule) {
              html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
            } else if (schedule.attendees.length) {
              html.push('<span class="calendar-font-icon ic-user-b"></span>');
            } else if (schedule.location) {
              html.push('<span class="calendar-font-icon ic-location-b"></span>');
            }
            html.push(' ' + schedule.title);
          }

          return html.join('');
        }

        // register templates
        const templates = {
          allday: function (schedule) {
            return getTimeTemplate(schedule, true);
          },
          time: function (schedule) {
            return getTimeTemplate(schedule, false);
          },
          goingDuration: function (schedule) {
            return '<span class="calendar-icon ic-travel-time"></span>' + schedule.goingDuration + 'min.';
          },
          comingDuration: function (schedule) {
            return '<span class="calendar-icon ic-travel-time"></span>' + schedule.comingDuration + 'min.';
          },
          monthMoreTitleDate: function (date, dayname) {
            var day = date.split('.')[2];
            return (
              '<span class="tui-full-calendar-month-more-title-day">' +
              day +
              '</span> <span class="tui-full-calendar-month-more-title-day-label">' +
              dayname +
              '</span>'
            );
          },
          monthMoreClose: function () {
            return '<span class="tui-full-calendar-icon tui-full-calendar-ic-close"></span>';
          },
          monthGridHeader: function (dayModel) {
            var date = parseInt(dayModel.date.split('-')[2], 10);
            var classNames = ['tui-full-calendar-weekday-grid-date '];

            if (dayModel.isToday) {
              classNames.push('tui-full-calendar-weekday-grid-date-decorator');
            }

            return '<span class="' + classNames.join(' ') + '">' + date + '</span>';
          },
        };

        calendar.value = new TuiCalendar(tplref_cal.value, {
          usageStatistics: false,
          defaultView: 'month',
          useDetailPopup: true,
          taskView: false,
          scheduleView: ['time'],
          isReadOnly: true,
          month: {
            startDayOfWeek: 1,
            // visibleScheduleCount: 1,
          },
          theme: {
            'common.fontSize': '.7rem',
            'month.dayname.fontSize': '.7rem',
            'month.day.fontSize': '.7rem',
          },
          template: templates,
        });

        calendar.value.createSchedules(
          props.items.map((item) => {
            const start = dayjs(item.start);
            const end = dayjs(item.end);
            return {
              category: 'time',
              title: item.description,
              start: start.toDate(),
              end: end.toDate(),
            };
          })
        );
      };

      // resize event
      const on_resize = () => {
        if (window.innerWidth >= 720) {
          calendar.value.setTheme({
            'month.schedule.marginTop': '2px',
          });
        } else {
          calendar.value.setTheme({
            // auto hides event entries from tui calendar in month view
            'month.schedule.marginTop': 'auto',
          });
        }
      };

      // calendar actions
      const set_info = () => {
        const info = dayjs(calendar.value.getDate());
        month.value = month_names()[info.month()];
        year.value = info.year();
      };

      const prev_month = () => {
        calendar.value.prev();
        set_info();
      };
      const next_month = () => {
        calendar.value.next();
        set_info();
      };
      const today_month = () => {
        calendar.value.today();
        set_info();
      };

      // component hooks
      onMounted(() => {
        setup_calendar();
        set_info();

        // set calendar theme to account for screen size
        on_resize();
        window.addEventListener('resize', on_resize);
      });

      onUnmounted(() => {
        window.removeEventListener('resize', on_resize);
      });

      return { tplref_cal, calendar, month, year, prev_month, next_month, today_month };
    },
  };
</script>

<style>
  .calendar-root {
    position: relative;
    width: 100%;
    min-width: 250px;
  }

  .info-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .menu {
    display: flex;
    gap: 0.5rem;
  }

  .btn {
    border: 1px solid #dddddd;
    background-color: #fff;
    padding: 0.3em 0.6rem;
    border-radius: 1rem;
    display: flex;
    place-items: center;
    font-weight: 600;
    font-size: 0.7rem;
    cursor: pointer;
  }

  .btn:hover {
    background-color: #ddd;
    border-color: #5e5e5e;
  }

  .month {
    font-weight: 600;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-right: 0.5rem;
    font-size: 0.9rem;
  }
</style>
