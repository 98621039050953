<template>
  <div class="mode-toggle">
    <!-- First state -->
    <div :class="`icon-wrapper left ${list_active ? 'active' : 'inactive'}`" @click="on_toggle('first')">
      <Icon :icon="first" class="icon" />
    </div>

    <!-- Second state -->
    <div :class="`icon-wrapper right ${list_active ? 'inactive' : 'active'}`" @click="on_toggle('second')">
      <Icon :icon="second" class="icon" />
    </div>
  </div>
</template>

<script>
  import { ref } from '@vue/reactivity';
  import { Icon } from '@iconify/vue';

  /**
   * Button to toggle between two items on click.
   */
  export default {
    name: 'IconToggleButton',
    components: {
      Icon,
    },
    emits: ['on-toggle'],
    props: {
      first: {
        type: String,
        required: true,
      },
      second: {
        type: String,
        required: true,
      },
    },
    setup(props, { emit }) {
      const list_active = ref(true);

      /**
       * Toggle the icon to the other state (active/inactive).
       * Emit event to parent component.
       * @param {'first' | 'second'} name - Named state to toggle.
       * @emits on-toggle
       */
      const on_toggle = (name) => {
        list_active.value = name === 'first' ? true : false;
        emit('on-toggle', name);
      };

      return { list_active, on_toggle };
    },
  };
</script>

<style scoped>
  .mode-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    width: fit-content;
  }

  .mode-toggle .icon-wrapper {
    padding: 0.25em 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .mode-toggle .icon-wrapper.left {
    border-radius: 0.5em 0 0 0.5em;
  }

  .mode-toggle .icon-wrapper.right {
    border-radius: 0 0.5em 0.5em 0;
  }

  .mode-toggle .inactive {
    background-color: #dbdbdb;
    color: #555;
  }

  .mode-toggle .active {
    background-color: #555;
    color: #dbdbdb;
  }

  .mode-toggle .inactive:hover {
    background-color: #c7c7c7;
  }
</style>
