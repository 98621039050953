<template>
  <div class="search-input-wrapper">
    <!-- Input for the search element with v-model handler -->
    <div class="input-box">
      <input
        type="text"
        name="Suchen"
        placeholder="Suchen..."
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @keydown.enter="$emit('add-tag')"
        @focus="focused_input = true"
        @blur="focused_input = false"
        autocomplete="off"
      />
      <Icon icon="carbon:add-alt" class="icon" @click="$emit('add-tag')" />
    </div>

    <!-- Completion functionality on focus -->
    <div
      class="completion-values"
      v-if="completion.length > 0 && (focused_input || focused_completion)"
      @mouseenter="focused_completion = true"
      @mouseleave="focused_completion = false"
    >
      <p v-for="(item, index) in completion" :key="index" @click="select_option(item)">
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
  import { Icon } from '@iconify/vue';
  import { computed, ref } from '@vue/reactivity';

  /**
   * Search input for filtering data with custom autocompletion.
   */
  export default {
    name: 'IconInputBox',
    components: {
      Icon,
    },
    props: {
      modelValue: {
        // v-model value mapper
        type: String,
        required: false,
        default: '',
      },
      completion_items: {
        // List of completion items
        type: Array,
        required: false,
        default: () => [],
      },
    },
    emits: ['update:modelValue', 'add-tag'],
    setup(props, { emit }) {
      const focused_input = ref(false); // Whether the input is focused
      const focused_completion = ref(false); // Whether the completion is focused

      /**
       * Computed value to filter the completion items depending on the input value
       */
      const completion = computed(() => {
        const input = props.modelValue.toLowerCase();
        return props.completion_items.filter(
          (item) => item.toLowerCase() !== input && item.toLowerCase().includes(input)
        );
      });

      /**
       * Updates the model value and emits the add-tag event
       * @param {string} item - The selected completion item
       */
      const select_option = (item) => {
        emit('update:modelValue', item);
        emit('add-tag');
      };

      return { completion, focused_input, select_option, focused_completion };
    },
  };
</script>

<style scoped>
  .search-input-wrapper {
    --font-size: 0.8em;
    --color: #afafaf;

    position: relative;
  }

  .input-box {
    font-size: 0.8em;

    width: 17em;
  }

  .completion-values {
    position: absolute;
    min-width: 15em;
    font-size: 0.75em;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10000;
    overflow-y: auto;
    max-height: 400px;
    padding: 0.5em 0;
    margin-top: 1em;
  }

  .completion-values p {
    padding: 0.3em 0.5em;
    cursor: pointer;
  }

  .completion-values p:hover {
    background-color: #f6f6f6;
  }

  .completion-values .active {
    background-color: #f6f6f6;
  }

  .input-box {
    display: flex;
    place-items: center;
    gap: 0.4em;

    border-bottom: 1px solid var(--color);
  }

  .input-box .icon {
    color: #727272;
    cursor: pointer;
  }

  .input-box .icon:hover {
    color: #959595;
  }

  .input-box input {
    border: none;
    width: 100%;
    font-size: 0.9em;
    background-color: transparent;
  }

  .input-box input::placeholder {
    color: var(--color);
  }

  .input-box input:focus {
    outline: none;
  }
</style>
