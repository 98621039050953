<template>
  <div class="profile">
    <Navbar />
    <div class="splitter-image" />

    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />

    <div class="content">
      <!-- Information of the current user -->
      <header class="user-descriptor">
        <Icon icon="carbon:user-avatar" class="avatar-icon" />
        <h2 class="logged-in-as">
          Angemeldet als <span>{{ user_data.first_name }} {{ user_data.last_name }}</span>
        </h2>
        <button @click="logout" class="logout-link">
          <Icon icon="uiw:logout" />
          <span>Logout</span>
        </button>
      </header>

      <!-- Change password functionality implemented as an html form -->
      <form class="card change-password">
        <h3>Passwort ändern</h3>
        <p>
          Das Passwort muss mindestens 8 Zeichen lang sein und mindestens 1 Zahl, 1 Kleinbuchstaben und einen
          Großbuchstaben entalten.
        </p>

        <div class="input-group">
          <label>Altes Passwort</label>
          <input type="password" v-model="old_password" />
        </div>

        <div class="input-group">
          <label>Neues Passwort</label>
          <input type="password" v-model="new_password" />
        </div>

        <div class="input-group">
          <label>Neues Passwort bestätigen</label>
          <input type="password" v-model="new_password_confirm" />
        </div>

        <div
          class="problems"
          v-if="password_problems.length > 0 && (new_password !== '' || new_password_confirm !== '')"
        >
          <p class="problem" v-for="problem in password_problems" :key="problem">* {{ problem }}</p>
        </div>

        <Button class="submit-changed-password" @click="change_password">Passwort ändern</Button>
      </form>
    </div>
  </div>
</template>

<script>
  import { computed, ref } from '@vue/reactivity';

  import axios from 'axios';

  import { use_toast, use_logout, use_user } from '@/composables/composables';
  import { api_routes } from '@/config';
  import { Logger } from '@/util';

  import { Icon } from '@iconify/vue';

  import Navbar from '@/components/general/Navbar.vue';
  import Button from '@/components/general/Button.vue';
  import Toast from '@/components/general/Toast.vue';

  export default {
    name: 'Profile',
    components: {
      Icon,
      Navbar,
      Button,
      Toast,
    },
    setup() {
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      const { user_data, fetch_user_data } = use_user();

      /**
       * Error callback for use_user composable.
       */
      fetch_user_data((error) => {
        Logger.log(error);
        show_toast('error', 'Verbindungsfehler!');
        Logger.log(axios.defaults.headers.common['Authorization']);
      });

      const { logout } = use_logout();

      // change password logic
      const old_password = ref('');
      const new_password = ref('');
      const new_password_confirm = ref('');

      /**
       * Function to determine if the new password is valid.
       * This is only a frontend check for usability.
       */
      const password_problems = computed(() => {
        const problem_list = [];

        // quality checks
        if (new_password.value !== new_password_confirm.value) {
          problem_list.push('Die Passwörter stimmen nicht überein!');
        }

        if (old_password.value === new_password.value) {
          problem_list.push('Das neue Passwort ist identisch mit dem alten!');
        }

        if (new_password.value.length < 8) {
          problem_list.push('Das neue Passwort muss mindestens 8 Zeichen lang sein!');
        }

        if (!new_password.value.match(/[A-Z]/)) {
          problem_list.push('Das Passwort muss mindestens 1 Großbuchstaben enthalten');
        }

        if (!new_password.value.match(/[a-z]/)) {
          problem_list.push('Das Passwort muss mindestens 1 Kleinbuchstaben enthalten');
        }

        if (!new_password.value.match(/[0-9]/)) {
          problem_list.push('Das Passwort muss mindestens 1 Zahl enthalten');
        }

        return problem_list;
      });

      /**
       * Backend call to request password change.
       * @param {Event} event - The event that triggered the call. Only used to prevent default submit behaviour.
       */
      const change_password = async (event) => {
        event.preventDefault();

        const problems = password_problems.value;

        if (problems.length > 0) {
          show_toast('error', problems.join('<br>'));
          return;
        }

        // send change_password post request
        try {
          await axios.post(api_routes.change_password, {
            old_password: old_password.value,
            new_password: new_password.value,
          });

          old_password.value = '';
          new_password.value = '';
          new_password_confirm.value = '';

          show_toast('success', 'Passwort erfolgreich geändert!');
        } catch (error) {
          Logger.log(error);
          show_toast('error', 'Fehler beim Ändern des Passworts');
        }
      };

      return {
        is_toast_shown,
        toast_msg,
        user_data,
        logout,
        old_password,
        new_password,
        new_password_confirm,
        change_password,
        password_problems,
        toast_type,
      };
    },
  };
</script>

<style scoped>
  .profile .content {
    background-color: #ebebeb;
    width: 90%;
    margin: 3em auto 0 auto;
    min-height: 80vh;
    border-radius: 0.3em;
    padding: 0.5em;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }

  .profile .content .user-descriptor {
    display: flex;
    flex-direction: column;
    gap: 0.3em;

    align-items: center;
    margin: 0 auto;
  }

  .profile .content .user-descriptor .avatar-icon {
    font-size: 3em;
    color: #555;
    cursor: pointer;
  }

  .profile .content .user-descriptor .logged-in-as {
    font-size: 0.9em;
    font-weight: 400;
  }

  .profile .content .user-descriptor .logged-in-as span {
    font-weight: 500;
  }

  .profile .content .user-descriptor .logout-link {
    display: flex;
    align-items: center;
    gap: 0.5em;

    font-size: 0.8em;
    text-decoration: none;
    color: #757575;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .profile .content .user-descriptor .logout-link:hover {
    text-decoration: underline;
  }

  .card {
    font-size: 0.85em;
    background-color: #fff;
    border-radius: 0.4em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    width: 95%;

    padding: 1em;
    font-size: 1em;
  }

  .profile .content .change-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .profile .content .change-password h3 {
    font-weight: 500;
    font-size: 1em;
  }

  .profile .content .change-password p {
    font-size: 0.9em;
    color: #9d9d9d;
  }

  .profile .content .change-password .input-group {
    color: #555;
    font-size: 0.8em;
    align-self: start;
    display: flex;
    flex-direction: column;
  }

  .profile .content .change-password .input-group input {
    border: 1px solid #555;
    border-radius: 0.3em;
  }

  .profile .content .change-password .problems .problem {
    color: #ff0062;
    font-size: 0.7em;
  }

  .profile .content .change-password .submit-changed-password {
    margin-top: 1.5em;
    font-size: 0.8em;
  }

  @media (min-width: 515px) {
    .card {
      padding: 1em 2em;
    }
    .profile .content .change-password {
      align-items: start;
      gap: 1em;
    }
  }
</style>
