<template>
  <Overlay>

    <!-- Header information -->
    <template v-slot:header>
      <div class="seat-list-header">
        <div class="main-header">
          <h2 class="seat-name">{{ seat.full_name }}</h2>
        </div>
        <p class="descr">{{ seat.description }}</p>
      </div>
    </template>

    <!-- Menu points -->
    <template v-slot:menu>
      <SliderMenu
        :menu_items="['Freigabe', 'Belegung']"
        class="overlay-menu"
        :active_start="active_menu_item"
        @menu-click="on_menu_click"
      />
    </template>

    <!-- Sub-pages -->
    <template v-slot:content>
      <Release v-if="active_menu_item === 'Freigabe'" :seat="seat" @release="$emit('release')" />
      <SeatOccupation v-if="active_menu_item === 'Belegung'" :seat_id="seat.pk" />
    </template>
  </Overlay>
</template>

<script>
  import { ref } from 'vue';

  import Overlay from '@/components/general/Overlay.vue';
  import SliderMenu from '@/components/general/SliderMenu.vue';
  import SeatOccupation from '@/components/view-members/booking/SeatOccupation.vue';
  import Release from '@/components/view-members/reservation/Release.vue';

  /**
   * Wrapper overlay component regarding actions for information display and release functionality of the seat for the owner.
   * Emits:
   *   - release: Emitted when the seat is released for a certain timeframe.
   */
  export default {
    name: 'OwnSeatOverlay',
    components: {
      Overlay,
      SliderMenu,
      SeatOccupation,
      Release,
    },
    props: {
      start_page: {
        // Menu point in which to open the overlay
        type: String,
        default: 'Belegung',
      },
      seat: {
        type: Object,
        required: true,
        /*
          {
            pk: number,
            full_name: string,
            description: string,
            is_favorite: boolean,
            released_times: {
              pk: number,
              start: Date,
              end: Date,
            }[]
          }
        */
      },
    },
    emits: ['release'],
    setup(props) {
      const active_menu_item = ref(props.start_page); // Menu point in which to open the overlay

      /**
       * Open a sub-page of the overlay corresponding to the given menu point.
       * @param {string} item Menu point in which to open the overlay
       */
      const on_menu_click = (item) => {
        active_menu_item.value = item;
      };

      return { active_menu_item, on_menu_click };
    },
  };
</script>

<style scoped>
  .seat-list-header {
    text-align: center;
  }

  .seat-list-header .main-header {
    display: flex;
    gap: 0.7em;
    align-items: center;
    justify-content: center;
  }

  .seat-list-header .main-header .icon {
    color: #555;
    cursor: pointer;
  }

  .seat-list-header .main-header .icon:hover {
    color: #858585;
  }

  .seat-list-header .main-header .seat-name {
    font-size: 0.938em;
    font-weight: 500;
  }

  .seat-list-header .descr {
    color: #9d9d9d;
    font-size: 0.625em;
  }

  .overlay-menu {
    font-size: 0.9em;
    margin: 0 auto;
  }

  @media (min-width: 650px) {
    .seat-list-header {
      font-size: 1.2em;
    }

    .overlay-menu {
      font-size: 1.1em;
    }
  }
</style>
