<template>
  <div class="seat-occupation-root">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />
    <div class="content" v-if="!loading">
      <!-- Selector for list/calendar view -->
      <IconToggleButton
        first="bi:list-ul"
        second="akar-icons:calendar"
        @on-toggle="
          (name) => {
            list_active = name === 'first';
          }
        "
      />

      <!-- List view table for occupation -->
      <div class="table-wrapper" v-if="list_active">
        <table class="occupation-table" v-if="data?.length > 0">
          <thead>
            <tr>
              <th>Benutzer</th>
              <th>Start</th>
              <th>Ende</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index">
              <td>{{ item.user_full_name }}</td>
              <td>{{ date_to_str(item.start) }}</td>
              <td>{{ date_to_str(item.end) }}</td>
            </tr>
          </tbody>
        </table>
        <h2 class="no-data" v-if="data === undefined || data?.length === 0">Keine Belegung vorhanden...</h2>
      </div>

      <!-- Calendar view for occupation -->
      <div class="occupation-calendar" v-else>
        <Calendar :items="calendar_items" />
      </div>
    </div>

    <div class="spinner-wrapper" v-if="loading">
      <Spinner :is_loading="loading" />
    </div>
  </div>
</template>

<script>
  import { computed, ref } from '@vue/reactivity';

  import { Logger, date_to_str, fetch_occupation, occupation_to_calendar_events } from '@/util';
  import { use_toast } from '@/composables/composables';

  import Calendar from '@/components/general/calendar/Calendar.vue';
  import IconToggleButton from '@/components/general/IconToggleButton.vue';
  import Spinner from '@/components/general/Spinner.vue';
  import Toast from '@/components/general/Toast.vue';

  /**
   * Component used in overlays to display information about the seat occupation.
   */
  export default {
    name: 'SeatOccupation',
    components: {
      Calendar,
      IconToggleButton,
      Spinner,
      Toast,
    },
    props: {
      seat_id: {
        // the id of the seat to display the occupation for
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const list_active = ref(true); // flag to determine if the list view is active or calendar view
      const data = ref([]); // occupation data. { pk: number; start: Date, end: Date, seat_pk: number, seat_full_name: string, seat_description: string, user_name: string, is_favorite: boolean }[]
      const loading = ref(true); // flag to determine if the data is loading
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      // fetch the occupation data from the backend
      fetch_occupation(props.seat_id)
        .then((res_data) => {
          loading.value = false;
          data.value = res_data;
        })
        .catch((error) => {
          loading.value = false;
          show_toast('error', 'Belegung konnte nicht geladen werden!');
          Logger.log(error);
        });

      /**
       * Computed value to construct calendar events from the occupation data.
       */
      const calendar_items = computed(() => {
        if (data.value === undefined) {
          return [];
        }

        return occupation_to_calendar_events(data.value);
      });

      return { list_active, data, date_to_str, calendar_items, loading, is_toast_shown, toast_type, toast_msg };
    },
  };
</script>

<style scoped>
  .content {
    font-size: 0.75em;
    width: 95%;
    overflow: auto;
    margin: 0.2em auto;
  }

  .mode-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    width: fit-content;
    float: right;
  }

  .mode-toggle .icon-wrapper {
    padding: 0.25em 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .mode-toggle .icon-wrapper.left {
    border-radius: 0.5em 0 0 0.5em;
  }

  .mode-toggle .icon-wrapper.right {
    border-radius: 0 0.5em 0.5em 0;
  }

  .mode-toggle .inactive {
    background-color: #dbdbdb;
    color: #555;
  }

  .mode-toggle .active {
    background-color: #555;
    color: #dbdbdb;
  }

  .mode-toggle .inactive:hover {
    background-color: #c7c7c7;
  }

  .content .occupation-table {
    width: 100%;
    font-size: 0.9em;
    border-collapse: separate;
    border-spacing: 0 0.6em;
  }

  .content .occupation-table tbody {
    color: #b5b5b5;
  }

  .content .occupation-table thead tr th {
    color: #9d9d9d;
    font-weight: normal;
    font-size: 0.8em;
    text-align: start;
  }

  .content .occupation-table td {
    text-align: start;
    padding: 0.5em 0.7em;
  }

  .content .occupation-table tbody tr {
    border-radius: 0.3em;
  }

  .content .occupation-table tbody tr td {
    background-color: #fff;
  }

  .content .occupation-table tbody tr td:first-child {
    padding-left: 0.5em;
    border-radius: 0.4em 0 0 0.4em;
    background-color: #fff;
  }

  .content .occupation-table tbody tr td:last-child {
    border-radius: 0 0.4em 0.4em 0;
    background-color: #fff;
  }

  .occupation-calendar {
    font-size: 0.83em;
    margin-top: 4em;
  }

  .no-data {
    text-align: center;
    font-size: 1.3em;
    font-weight: 500;
    color: #616161;
    margin: 2em auto;
  }

  @media (min-width: 750px) {
    .content {
      font-size: 0.95em;
    }
  }
</style>
