<template>
  <div class="slider-menu">
    <h2 v-for="item in menu_items" :key="item" :class="item === active_item ? 'active' : ''" @click="menu_click(item)">
      {{ item }}
    </h2>
  </div>
</template>

<script>
  import { ref } from 'vue';

  /**
   * Slider menu component for showing a list of clickable items.
   */
  export default {
    name: 'SliderMenu',
    props: {
      menu_items: {
        type: Array,
        required: true,
      },
      active_start: {
        type: String,
        required: false,
      },
    },
    emits: ['menu-click'],
    setup(props, { emit }) {
      // Create ref for active item. If prop is not supplied set it to first item in menu_items.
      const active_item = ref(props.active_start || props.menu_items[0]);

      /**
       * Emit menu-click event and set the active menu item.
       */
      const menu_click = (item) => {
        active_item.value = item;
        emit('menu-click', item);
      };

      return { active_item, menu_click };
    },
  };
</script>

<style scoped>
  .slider-menu {
    --inactive-color: #585859;
    --active-color: #000;

    display: flex;
    gap: 0.8em;
    border-bottom: 1px solid var(--inactive-color);
    width: fit-content;
  }

  .slider-menu h2 {
    font-size: 0.8em;
    font-weight: normal;
    color: var(--inactive-color);
    padding: 0 0.2em;
    cursor: pointer;
  }

  .slider-menu h2:hover {
    color: #222;
  }

  .slider-menu h2.active {
    color: var(--active-color);
    border-bottom: 1px solid var(--active-color);
  }
</style>
