<template>
  <div class="toast" :style="{ backgroundColor: type === 'success' ? '#1ee297' : '#ff0062' }">
    {{ message }}
  </div>
</template>

<script>
  /**
   * Toast component for showing informational message.
   * Either success or error message.
   */
  export default {
    name: 'Toast',
    props: {
      message: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
        validator: function (value) {
          return ['success', 'error'].indexOf(value) !== -1;
        },
      },
    },
  };
</script>

<style>
  .toast {
    font-size: 0.9rem;
    padding: 1.1em;
    color: white;

    border-radius: 10px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    max-width: 400px;

    position: fixed;
    right: 50%;
    top: 20px;
    transform: translateX(50%);
  }
</style>
