<template>
  <div class="root">
    <!-- Information displaying the current selected month as well as actions to switch the month -->
    <div class="info-bar">
      <div class="menu">
        <button class="btn" @click="today_month">Heute</button>
        <button class="btn" @click="prev_month"><Icon icon="ep:arrow-left-bold" /></button>
        <button class="btn" @click="next_month"><Icon icon="ep:arrow-right-bold" /></button>
      </div>
      <div class="month">
        <span>{{ month_names()[cal_date.month()] }}</span>
        <span>{{ cal_date.year() }}</span>
      </div>
    </div>

    <Spinner :is_loading="is_loading" v-if="is_loading" />

    <!-- Custom calendar view to display the occupation data -->
    <div class="calendar" v-if="!is_loading">
      <div
        class="weekday"
        :key="dayname"
        v-for="dayname in ['Mon', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']"
        :style="{ color: ['Sa', 'So'].includes(dayname) ? '#f00' : '#000' }"
      >
        {{ dayname }}
      </div>
      <div
        class="cell"
        :key="itm.key"
        v-for="itm in calendar"
        :style="{
          backgroundColor: itm?.data?.occupation_percentage ? get_color(itm.data.occupation_percentage) : '#fff',
        }"
        @click="on_show_overlay(itm.date)"
      >
        <span
          :style="{ color: ['prev', 'next'].includes(itm.type) ? '#aaa' : '#000' }"
          :class="`${is_today(itm.date) ? 'today' : ''}`"
          >{{ itm.date.format('DD') }}</span
        >
        <p class="stats-data">{{ Math.round(itm?.data?.occupation_percentage) }} %</p>
      </div>
    </div>

    <StatsOverlay v-if="show_overlay" :date="current_date" @close-overlay="show_overlay = false" />
  </div>
</template>

<script>
  import dayjs from 'dayjs';
  import { ref } from '@vue/reactivity';
  import { Icon } from '@iconify/vue';
  import { month_names } from '@/util';
  import axios from 'axios';
  import { api_routes } from '@/config';
  import { Logger } from '@/util';
  import Spinner from '@/components/general/Spinner.vue';
  import chroma from 'chroma-js';
  import StatsOverlay from './StatsOverlay.vue';

  /**
   * Custom calendar view to display the occupation data from the backend.
   * This calendar is also colorcoded and interactive in the way to select a overlay to display more information about the selected date.
   */
  export default {
    components: {
      Icon,
      Spinner,
      StatsOverlay,
    },
    props: {
      date: {
        type: Object,
        default: () => dayjs(),
      },
    },
    setup(props) {
      const cal_date = ref(props.date); // calendar date to display current month
      const is_loading = ref(true); // loading flag

      const show_overlay = ref(false); // flag to show the overlay
      const current_date = ref(dayjs()); // current date to be used by the overlay

      /**
       * Check if the given date is the current date
       * @param {Dayjs} date - date to check
       */
      const is_today = (date) => date.isSame(dayjs(), 'day');

      /**
       * Show the overlay for the given date
       * @param {Dayjs} date - date to show the overlay for
       */
      const on_show_overlay = (date) => {
        show_overlay.value = true;
        current_date.value = date;
      };

      /**
       * Create an array of days to be displayed in the calendar portion of the UI.
       * This includes mostly the current month but also some days from the previous and next month.
       * @param {Dayjs} date - date for which month to create the calendar view
       * @returns {{date: Dayjs, type: 'prev' | 'current' | 'next'}[]} - array of days to be displayed in the calendar
       */
      const create_calendar = (date) => {
        // Get the start day as the first day of the week. Create previous array to fill the beginning month week with days from the previous month.
        let start_day = date.startOf('month').day();
        start_day = start_day === 0 ? 6 : start_day - 1;
        const prev =
          start_day === 0
            ? []
            : Array(start_day)
                .fill(null)
                .map((_, i) => {
                  return {
                    date: date.startOf('month').subtract(start_day - i, 'days'),
                    type: 'prev',
                  };
                });

        // create array of all days in the current month
        const current = Array(date.daysInMonth())
          .fill(null)
          .map((_, i) => {
            return {
              date: date.startOf('month').set('date', i + 1),
              type: 'current',
            };
          });

        // create array of next days to fill the end of the month week with days from the next month
        let end_day = date.endOf('month').day();
        end_day = 6 - (end_day === 0 ? 6 : end_day - 1);
        const next =
          end_day === 6
            ? []
            : Array(end_day)
                .fill(null)
                .map((_, i) => {
                  return {
                    date: date.endOf('month').add(i + 1, 'days'),
                    type: 'next',
                  };
                });

        return [...prev, ...current, ...next].map((itm, i) => {
          return {
            key: i,
            ...itm,
          };
        });
      };

      const calendar = ref(create_calendar(cal_date.value)); // calendar to be displayed in the UI

      /**
       * Load the occupation statistics data from the backend and update the calendar array with the data.
       * data: {date: Date, occupation_percentage: number, free_seats: number, occupied_seats: number, total_seats: number}
       * The calendar array will be updated with a "data" item.
       * @param {Date} start_date - start date of the period to load the data for
       * @param {Date} end_date - end date of the period to load the data for
       */
      const fetch_statistics_data = async (start_date, end_date) => {
        is_loading.value = true;
        try {
          const res = await axios.get(api_routes.occupation_statistics, {
            params: {
              start: start_date,
              end: end_date,
            },
          });
          const events = await res.data;
          events.forEach((itm, i) => {
            calendar.value[i] = {
              ...calendar.value[i],
              data: itm,
            };
          });
        } catch (error) {
          // TODO: show toast
          Logger.log(error);
        } finally {
          is_loading.value = false;
        }
      };

      /**
       * Navigate to the previous month in the calendar.
       * Set up new calendar and fetch the data for the new month.
       */
      const prev_month = () => {
        cal_date.value = cal_date.value.subtract(1, 'month');
        calendar.value = create_calendar(cal_date.value);
        fetch_statistics_data(
          calendar.value[0].date.format('YYYY-MM-DD'),
          calendar.value[calendar.value.length - 1].date.format('YYYY-MM-DD')
        );
      };

      /**
       * Navigate to the month corresponding to the current date in the calendar.
       * Set up new calendar and fetch the data for the new month.
       */
      const today_month = () => {
        if (cal_date.value.isSame(dayjs(), 'month')) {
          return;
        }
        cal_date.value = dayjs();
        calendar.value = create_calendar(cal_date.value);
        fetch_statistics_data(
          calendar.value[0].date.format('YYYY-MM-DD'),
          calendar.value[calendar.value.length - 1].date.format('YYYY-MM-DD')
        );
      };

      /**
       * Navigate to the next month in the calendar.
       * Set up new calendar and fetch the data for the new month.
       */
      const next_month = () => {
        cal_date.value = cal_date.value.add(1, 'month');
        calendar.value = create_calendar(cal_date.value);
        fetch_statistics_data(
          calendar.value[0].date.format('YYYY-MM-DD'),
          calendar.value[calendar.value.length - 1].date.format('YYYY-MM-DD')
        );
      };

      // fetch initial data for the calendar
      fetch_statistics_data(
        calendar.value[0].date.format('YYYY-MM-DD'),
        calendar.value[calendar.value.length - 1].date.format('YYYY-MM-DD')
      );

      /**
       * Create a color corresponding to the given percentage of occupation.
       */
      const get_color = (occupation_percentage) => {
        const colors = chroma.scale(['#0f0', '#00f', '#f00']).domain([0, 100]).colors(100);

        return chroma(colors[Math.round(occupation_percentage)]).brighten(1.6).hex();
      };

      return {
        calendar,
        prev_month,
        today_month,
        next_month,
        cal_date,
        month_names,
        is_loading,
        get_color,
        is_today,
        show_overlay,
        current_date,
        on_show_overlay,
      };
    },
  };
</script>

<style scoped>
  .calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    overflow-x: auto;
  }

  .cell {
    font-size: 0.7rem;
    border: 1px solid #ccc;
    padding: 0.5rem;
    background-color: #fff;
    /* border-radius: 15px; */
    padding: 0.5rem;
    min-height: 5rem;
    cursor: pointer;
  }

  .cell:hover {
    filter: brightness(85%);
  }

  .stats-data {
    text-align: center;
    margin-top: 0.5rem;
    font-size: 1em;
    font-weight: 500;
  }

  .info-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .menu {
    display: flex;
    gap: 0.5rem;
  }

  .btn {
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 0.3em 0.6rem;
    border-radius: 1rem;
    display: flex;
    place-items: center;
    font-weight: 600;
    font-size: 0.7rem;
    cursor: pointer;
  }

  .btn:hover {
    background-color: #ddd;
    border-color: #5e5e5e;
  }

  .month {
    font-weight: 600;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-right: 0.5rem;
    font-size: 0.8rem;
  }

  .weekday {
    font-weight: 600;
    font-size: 0.7rem;
    padding: 0.4rem 0.5rem;
  }

  .today {
    border-radius: 3rem;
    padding: 0.2rem 0.4rem;
    background-color: #e6e6e6;
  }

  @media (min-width: 540px) {
    .stats-data {
      font-size: 1.2em;
    }
  }
</style>
