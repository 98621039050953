<template>
  <Overlay @close-overlay="$emit('close-overlay')">
    <!-- Header information -->
    <template v-slot:header>
      <div class="booking-header">
        <h2 class="seat-name">{{ offer.seat_name }}</h2>
        <p class="descr">{{ offer.description }}</p>
      </div>
    </template>

    <template v-slot:content>
      <!-- Timeframe information -->
      <div class="content">
        <div class="timeframe-wrapper">
          <label for="start">Start</label>
          <p>{{ date_to_str(offer.start) }}</p>
          <label for="end">Ende</label>
          <p>{{ date_to_str(offer.end) }}</p>
        </div>

        <!-- Seat plan image with marked seat -->
        <div class="seat-plan">
          <div class="spinner-wrapper" v-if="seat_plan_loading">
            <Spinner :is_loading="seat_plan_loading" />
          </div>
          <SeatPreview :seat_ids="[offer.seat_id]" @loaded="seat_plan_loading = false" />
        </div>

        <!-- User assignment selector -->
        <div class="assign-user">
          <CompleteInput placeholder="Benutzer..." :completion_items="completion" v-model="user_input" />
        </div>

        <!-- Comment input -->
        <div class="comment-wrapper">
          <label for="comment">Kommentar</label>
          <textarea name="comment" rows="7" v-model="comment"></textarea>
        </div>

        <!-- Actions -->
        <div class="btn-group">
          <Button class="btn" @click="$emit('close-overlay')">Abbrechen</Button>
          <Button class="btn" @click="submit_reservation">Buchen</Button>
        </div>
      </div>
    </template>
  </Overlay>
</template>

<script>
  import { ref } from '@vue/reactivity';

  import axios from 'axios';

  import { api_routes } from '@/config';
  import { Logger, date_to_str } from '@/util';

  import Overlay from '@/components/general/Overlay.vue';
  import Button from '@/components/general/Button.vue';
  import CompleteInput from '@/components/general/CompleteInput.vue';
  import SeatPreview from '@/components/general/SeatPreview.vue';
  import Spinner from '@/components/general/Spinner.vue';

  /**
   * Overlay form to make a reservation for a seat.
   * A user and comment can be supplied.
   * Emits:
   *   - booking-response: emitted when the booking was either successful/failed
   *   - close-overlay: emitted when the overlay should be closed
   */
  export default {
    name: 'SingleBookingForm',
    components: {
      Overlay,
      Button,
      CompleteInput,
      SeatPreview,
      Spinner,
    },
    props: {
      offer: {
        // The offer to book passed from the main booking page
        type: Object,
        required: true,
        /*
          {
            offer_id: number,
            seat_name: string,
            description: string,
            is_favorite: boolean,
            start: Date,
            end: Date,
          }
        */
      },
    },
    emits: ['booking-response', 'close-overlay'],
    setup(props, { emit }) {
      const completion = ref([]); // List of users to complete the input field
      const user_data = ref([]); // User data for the selected user. { pk: number, username: string, first_name: string, last_name: string }
      const user_input = ref(''); // v-model for the input field
      const comment = ref(''); // v-model for the comment field
      const seat_plan_loading = ref(true); // flag to indicate if the seat plan is loading
      const booking_requested = ref(false); // flag to prevent spamming the server with multiple requests

      /**
       * Loads the user data for all users from the backend and updates the completion list.
       */
      const fetch_users = async () => {
        try {
          const res = await axios.get(api_routes.user_list);

          user_data.value = await res.data;
          completion.value = user_data.value.map((user) => `${user.first_name} ${user.last_name}`);
        } catch (error) {
          Logger.log(error);
        }
      };

      fetch_users();

      /**
       * Load the primary key for the logged in user from the backend.
       */
      const get_user_pk = async () => {
        const user_name = user_input.value;

        if (user_name === '') {
          try {
            const res = await axios.get(api_routes.user_data);

            const data = await res.data;
            return data.pk;
          } catch (error) {
            Logger.log(error);
            return null;
          }
        }

        const user = user_data.value.find((user) => `${user.first_name} ${user.last_name}` === user_name);

        return user ? user.pk : null;
      };

      /**
       * Make a booking request to the backend for the selected seat.
       */
      const submit_reservation = async () => {
        try {
          if (booking_requested.value) {
            return;
          }
          booking_requested.value = true;

          const reserved_for = await get_user_pk();
          if (reserved_for === null) {
            throw new Error('Bitte einen Benutzer auswählen.');
          }

          const res = await axios.post(api_routes.place_reservation, {
            seat_id: props.offer.seat_id,
            reserved_for: reserved_for,
            start: props.offer.start,
            end: props.offer.end,
            comment: comment.value,
          });

          const data = await res.data;

          if (data.status === 'success') {
            emit('booking-response', {
              status: 'success',
              offer_id: props.offer.offer_id,
              message: 'Reservation erfolgreich.',
            });
          } else {
            throw new Error(data.errors);
          }
        } catch (error) {
          Logger.log(error);
          emit('booking-response', {
            status: 'error',
            message: error.message,
          });
        }

        emit('close-overlay');
      };

      return { completion, user_input, comment, date_to_str, submit_reservation, seat_plan_loading };
    },
  };
</script>

<style scoped>
  .booking-header {
    text-align: center;
  }

  .booking-header .seat-name {
    font-size: 0.938em;
    font-weight: 500;
  }

  .booking-header .descr {
    color: #9d9d9d;
    font-size: 0.625em;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 4em);
    margin: 2em 0;
  }

  .content .timeframe-wrapper {
    font-size: 0.9em;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5em 1em;
    width: fit-content;
    margin: 0 auto;
  }

  .content .timeframe-wrapper label {
    color: #9d9d9d;
  }

  .content .timeframe-wrapper p {
    color: #727272;
  }

  .content .seat-plan {
    display: none;
    overflow: auto;
  }

  .content .assign-user {
    border-bottom: 1px solid #afafaf;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .content .assign-user input {
    font-size: 0.8em;
    border: none;
    background-color: transparent;
  }

  .content .assign-user input:focus {
    outline: none;
  }

  .content .assign-user .icon {
    color: #727272;
    cursor: pointer;
    font-size: 1.1em;
  }

  .content .comment-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }

  .content .comment-wrapper label {
    color: #727272;
    font-size: 0.8em;
  }

  .content .comment-wrapper textarea {
    border: 1px solid #555;
    border-radius: 0.3em;
    background-color: #fdfdfd;
    resize: none;
    font-size: 0.8em;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.1);
  }

  .content .btn-group {
    display: flex;
    justify-content: space-evenly;
    font-size: 1.1em;
  }

  @media (min-width: 650px) {
    .booking-header {
      font-size: 1.2em;
    }

    .content .timeframe-wrapper {
      grid-template-columns: auto 1fr 1fr 1fr;
      place-items: end;
    }
  }

  @media (min-width: 935px) {
    .content {
      font-size: 1.2em;
      display: grid;
      grid-template: repeat(4, auto) / 1fr 1fr;
      align-items: start;
      place-content: start;
      gap: 3em 0.5em;
      margin: 2em auto;
      height: calc(100% - 8em);
    }

    .content .timeframe-wrapper {
      grid-column: span 2;
    }

    .content .seat-plan {
      grid-row: span 3;
      display: block;
    }

    .content .btn-group {
      align-self: end;
      margin-bottom: 2em;
    }
  }
</style>
