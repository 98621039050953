<template>
  <Dropdown class="dropdown-root">
    <template v-slot:header>
      <Icon icon="radix-icons:avatar" class="icon" />
    </template>

    <template v-slot:content>
      <div class="box-arrow" />
      <div class="card">
        <!-- General user information -->
        <h2 class="signed-in-info">
          Angeldet {{ user_data.username !== '' ? 'als ' : ''
          }}<span class="username" v-if="user_data.username !== ''"
            >{{ user_data.first_name }} {{ user_data.last_name }}</span
          >
        </h2>

        <router-link :to="{ name: 'Profile' }" class="link">Profil</router-link>

        <!-- Logout link -->
        <button @click="logout" class="link logout">
          <Icon icon="uiw:logout" />
          <span>Logout</span>
        </button>
      </div>
    </template>
  </Dropdown>
</template>

<script>
  import { use_logout, use_user } from '@/composables/composables';

  import { Icon } from '@iconify/vue';
  import Dropdown from '@/components/general/Dropdown.vue';

  /**
   * Dropdown options for the user profile and logout.
   * This will only be shown in the desktop version.
   */
  export default {
    name: 'ProfileDropdown',
    components: {
      Icon,
      Dropdown,
    },
    setup() {
      const { logout } = use_logout();

      const { user_data, fetch_user_data } = use_user();

      fetch_user_data(); // TODO: think about error handeling

      return { logout, user_data };
    },
  };
</script>

<style scoped>
  .dropdown-root {
    --icon-font-size: 1.3em;
    --card-width: 18em;
  }

  .dropdown-root .icon {
    cursor: pointer;
    font-size: var(--icon-font-size);
    color: #555;
  }

  .dropdown-root .icon:hover {
    color: #858585;
  }

  .dropdown-root .card {
    width: var(--card-width);
    transform: translateX(calc(var(--icon-font-size) + calc(-1 * var(--card-width))));
    background-color: #f6f6f6;
    border-radius: 0.3em;
    padding: 0.5em 0.5em 1em 0.5em;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3em;
  }

  .dropdown-root .box-arrow {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: #f6f6f6;
    width: 2em;
    height: 1em;
    transform: translate(-0.8em, 0.2em);
  }

  .dropdown-root .card .signed-in-info {
    font-weight: 400;
    font-size: 0.9em;
    color: #555;
    border-bottom: 1px solid #b2b2b2;
    width: 100%;
    padding: 0.3em;
    margin-bottom: 0.4em;
  }

  .dropdown-root .card .signed-in-info .username {
    font-weight: 600;
  }

  .dropdown-root .card .link {
    text-decoration: none;
    color: #555;
    font-size: 0.9em;
    width: 100%;
    border-radius: 0.3em;
    padding: 0.15em 0.3em;
    cursor: pointer;
  }

  .dropdown-root .card .link:hover {
    color: #f6f6f6;
    background-color: #555;
  }

  .dropdown-root .card .link.logout {
    display: flex;
    gap: 0.7em;
    align-items: center;
    border: none;
  }

  @media (min-width: 650px) {
    /* .dropdown-root {
      --card-width: 36em;
    } */
  }
</style>
