<template>
  <div class="release">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />

    <!-- Release times datetime inputs -->
    <DateTimeInput name="Start" class="dt-input-release" v-model="start_str" :default_value="default_start_str" />
    <DateTimeInput name="Ende" class="dt-input-release" v-model="end_str" :default_value="default_end_str" />

    <!-- Release action button -->
    <Button class="btn-release" @click="on_release">Freigeben</Button>

    <!-- Calendar view for all release times events -->
    <div class="calendar-wrapper">
      <Calendar :items="cal_events" />
    </div>
    
  </div>
</template>

<script>
  import Button from '@/components/general/Button.vue';
  import DateTimeInput from '@/components/general/DateTimeInput.vue';
  import Calendar from '@/components/general/calendar/Calendar.vue';
  import { computed, ref, toRef } from '@vue/reactivity';
  import { watch } from '@vue/runtime-core';
  import dayjs from 'dayjs';
  import { Logger, str_to_date, are_some_between } from '@/util';
  import { use_start_end_dtinputs, use_toast } from '@/composables/composables';
  import { api_routes } from '@/config';
  import axios from 'axios';
  import Toast from '@/components/general/Toast.vue';

  /**
   * Component used in overlay to give the user the functionality to release his/her own seat for a specific timeframe.
   * Emits:
   *   - release: Emitted when the seat is released for a certain timeframe.
   */
  export default {
    name: 'Release',
    components: {
      Button,
      DateTimeInput,
      Calendar,
      Toast,
    },
    props: {
      seat: {
        // data of the seat to release
        type: Object,
        required: true,
        /*
          {
            pk: number,
            full_name: string,
            description: string,
            is_favorite: boolean,
            released_times: {
              pk: number,
              start: Date,
              end: Date,
            }[]
          }
        */
      },
    },
    emits: ['release'],
    setup(props, { emit }) {
      const sseat = toRef(props, 'seat'); // seat data passed as prop
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      // TODO: add halbtags/ganztags

      /**
       * Construct calendar events from the already existing release times.
       */
      const construct_cal_events = () => {
        return sseat.value?.released_times
          ? sseat.value.released_times.map((item) => ({
              description: 'Freigabe',
              start: dayjs(item.start),
              end: dayjs(item.end),
            }))
          : [];
      };

      const cal_events = ref(construct_cal_events());

      const start_dt = dayjs()
        .add(1, 'day')
        .set('hour', 7)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);

      const { start_str, end_str, default_start_str, default_end_str } = use_start_end_dtinputs(
        start_dt.toDate(),
        start_dt
          .add(8, 'hour')
          .add(45, 'minute')
          .toDate()
      );

      /**
       * Boolean indicating whether the seat is already released for the given timeframe.
       */
      const is_occupied_timeframe = computed(() => {
        const start_dt = dayjs(str_to_date(start_str.value));
        const end_dt = dayjs(str_to_date(end_str.value));

        return are_some_between(
          start_dt,
          end_dt,
          cal_events.value.map((evnt) => ({ start: evnt.start, end: evnt.end }))
        );
      });

      /**
       * Make a post request to the backend to release the seat for the given timeframe.
       */
      const on_release = async () => {
        try {
          // check if there is aready a release time falling under that
          if (is_occupied_timeframe.value === true) {
            const proceed = confirm(
              'Es fallen Reservierungen in den angegebenen Zeitraum. Diese werden eventuell storiert. Möchten Sie fortfahren?'
            );

            if (!proceed) return;
          }

          const res = await axios.post(api_routes.release_seat, {
            start: str_to_date(start_str.value),
            end: str_to_date(end_str.value),
          });

          const data = await res.data;

          if (data.status === 'success') {
            emit('release');
          }
        } catch (error) {
          show_toast('error', 'Fehler beim Freigeben');
          Logger.log(error);
        }
      };

      /**
       * Update the calendar events if the seat data changes. Specifically the release times.
       */
      watch(sseat, () => {
        // TODO: update refs
        // TODO: split up multiple day releases for calendar
        cal_events.value = construct_cal_events();
      });

      return {
        cal_events,
        start_str,
        end_str,
        default_start_str,
        default_end_str,
        is_occupied_timeframe,
        on_release,
        is_toast_shown,
        toast_type,
        toast_msg,
      };
    },
  };
</script>

<style scoped>
  .release {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: 1em 0.5em;
  }

  .release .dt-input-release {
    font-size: 0.85em;
  }

  .release .btn-release {
    font-size: 0.8em;
    margin-top: 1em;
    margin-bottom: 1.5em;
  }

  .calendar-wrapper {
    font-size: 0.8em;
    width: 85%;
  }
</style>
