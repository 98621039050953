<template>
  <nav>
    <Icon icon="icon-park-outline:hamburger-button" class="icon hamburger" @click="show_navlinks = !show_navlinks" />

    <!-- Logo -->
    <router-link :to="{ name: 'Booking' }" class="logo">
      <img src="@/assets/inp-logo.svg" alt="INP" />
    </router-link>

    <!-- Dropdowns for Notifications and Profile view -->
    <div class="user-group">
      <NotificationDropdown />
      <ProfileDropdown class="avatar" />
    </div>

    <div class="nav-links" :style="{ display: show_navlinks ? 'flex' : 'none' }">

    <!-- Normal links -->
      <router-link :to="{ name: 'Booking' }" :class="`link ${$route.name === 'Booking' ? 'active' : ''}`"
        >Buchen</router-link
      >
      <router-link :to="{ name: 'Reservations' }" :class="`link ${$route.name === 'Reservations' ? 'active' : ''}`"
        >Reservierungen</router-link
      >
      <router-link :to="{ name: 'SeatLists' }" :class="`link ${$route.name === 'SeatLists' ? 'active' : ''}`"
        >Platzlisten</router-link
      >
      <router-link
        v-if="can_see_statistics"
        :to="{ name: 'Statistics' }"
        :class="`link left-splitter ${$route.name === 'Statistics' ? 'active' : ''}`"
        >Statistiken</router-link
      >
      
      <!-- Links only shown on mobile -->
      <router-link :to="{ name: 'Profile' }" :class="`link hide-desktop ${$route.name === 'Profile' ? 'active' : ''}`">
        <Icon icon="radix-icons:avatar" />
        <span>Profil</span>
      </router-link>

      <button @click="logout" :class="`link hide-desktop logout ${$route.name === 'Profile' ? 'active' : ''}`">
        <Icon icon="uiw:logout" />
        <span>Logout</span>
      </button>
    </div>
  </nav>
</template>

<script>
  import { Icon } from '@iconify/vue';
  import { ref } from '@vue/reactivity';

  import { use_logout } from '@/composables/composables';

  import NotificationDropdown from '@/components/notifications/NotificationDropdown.vue';
  import ProfileDropdown from '@/components/general/ProfileDropdown.vue';
  import { useStore } from 'vuex';

  /**
   * Navigation bar component.
   */
  export default {
    name: 'Navbar',
    components: {
      Icon,
      NotificationDropdown,
      ProfileDropdown,
    },
    setup() {
      const show_navlinks = ref(false);
      const store = useStore();
      const can_see_statistics = store.getters.can_see_statistics;

      const { logout } = use_logout();

      return { show_navlinks, logout, can_see_statistics };
    },
  };
</script>

<style scoped>
  nav {
    font-size: 1rem;
    font-family: Quicksand, Poppins, sans-serif;
    display: grid;
    grid-template: 1fr auto / auto 1fr auto;
    align-items: center;
    background-color: #fff;
    justify-items: center;
    padding: 0.4em 0.8em;
    border-radius: 0 0 0.5em 0.5em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  }

  .logo img {
    position: relative;
    top: 0.4em;
  }

  .nav-links {
    grid-column: -1 / 1;
    justify-self: start;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1em 0;
    font-size: 0.9em;
  }

  .icon {
    cursor: pointer;
    font-size: 1.3em;
    color: #555;
  }

  .user-group {
    position: relative;
    top: 0.25em;
  }

  .user-group .icon:hover {
    color: #858585;
  }

  .user-group .avatar {
    display: none;
  }

  .link {
    cursor: pointer;
  }

  .left-splitter {
    margin-left: 0;
  }

  .nav-links .link {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.4em;
    color: #555;
    border-bottom: 1px solid #b2b2b2;
    padding: 0.4em 0;
  }

  .nav-links .link:hover {
    color: #858585;
  }

  .nav-links .link.active {
    color: #303030;
    font-weight: 500;
  }

  .nav-links .link.logout {
    border: none;
    cursor: pointer;
    background-color: transparent;
  }

  @media (min-width: 650px) {
    nav {
      /* font-size: 0.85rem; */
      font-size: 1rem;
      grid-template: 1fr / auto 1fr auto;
      gap: 4em;
      padding: 0.6em 0.8em;
    }

    .icon.hamburger {
      display: none;
    }

    .user-group {
      display: flex;
      top: 0.05em;
      gap: 0.5em;
    }

    .user-group .avatar {
      display: inline-block;
    }

    .left-splitter {
      margin-left: 1.5em;
    }

    .nav-links {
      grid-column: 2;
      grid-row: 1;
      display: flex !important;
      flex-direction: row;
      gap: 1.4em;
      padding: 0;
    }

    .nav-links .link {
      border: none;
      padding: 0;
      font-size: 0.95em;
    }

    .nav-links .link.hide-desktop {
      display: none;
    }

    .nav-links .link.active {
      border-bottom: 1px solid #303030;
      font-weight: normal;
    }
  }

  @media (min-width: 1400px) {
    nav {
      font-size: 1rem;
    }
  }
</style>
