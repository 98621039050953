<template>
  <Overlay @close-overlay="$emit('close-overlay')">
    <!-- Title -->
    <template v-slot:header>
      <h2 class="title">Mehrfachbuchung</h2>
    </template>

    <!-- Content section -->
    <template v-slot:content>
      <div class="content">
        <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />

        <!-- Offer card -->
        <div class="offer-cards-list">
          <div class="offer-card" v-for="(offer, index) in offers" :key="index">
            <div class="header-group">
              <Icon
                class="icon mark"
                icon="akar-icons:circle-minus"
                @click.stop="$emit('remove-offer', offer.offer_id)"
              />
              <div class="seat-info">
                <h3>{{ offer.seat_name }}</h3>
                <p class="descr">{{ offer.description }}</p>
              </div>
              <div />
            </div>
            <div class="offertimes">
              <p>{{ date_to_str(offer.start) }}</p>
              <p>{{ date_to_str(offer.end) }}</p>
            </div>
            <CompleteInput
              class="user-complete-input"
              placeholder="Benutzer..."
              :completion_items="completion"
              v-model="user_inputs[index]"
            />
          </div>
        </div>

        <div class="actions-group">
          <div class="comment-group">
            <label>Kommentar</label>
            <input type="text" name="comment" v-model="comment" />
          </div>

          <!-- Actions -->
          <div class="btn-group">
            <Button class="btn" @click="$emit('close-overlay')">Abbrechen</Button>
            <Button class="btn" @click="submit_reservations">Buchen</Button>
          </div>
        </div>
      </div>
    </template>
  </Overlay>
</template>

<script>
  import { Icon } from '@iconify/vue';
  import Overlay from '@/components/general/Overlay.vue';
  import Button from '@/components/general/Button.vue';
  import CompleteInput from '@/components/general/CompleteInput.vue';
  import { ref, toRef } from '@vue/reactivity';
  import { watch } from '@vue/runtime-core';
  import { date_to_str, Logger } from '@/util';
  import { api_routes } from '@/config';
  import axios from 'axios';
  import Toast from '@/components/general/Toast.vue';
  import { use_toast } from '@/composables/composables';

  /**
   * Component for multiple booking form.
   * Lets the user book multiple offers at once and assign users and comment to the booking.
   * The communication with the server is also done here.
   * Emits:
   *   - remove-offer: Emitted when the user wants to remove a single offer from the booking.
   *   - booking-response: Emitted when the server responds to the booking request.
   *   - close-overlay: Emitted when the user wants to close the overlay.
   */
  export default {
    name: 'MultipleBookingForm',
    components: {
      Icon,
      Overlay,
      Button,
      CompleteInput,
      Toast,
    },
    props: {
      offers: {
        // the selected offers in multiple booking
        type: Array,
        required: true,
        /*
          {
            offer_id: number,
            seat_id: number,
            seat_name: string,
            description: string,
            is_favorite: boolean,
            start: Date,
            end: Date
          }[]
        */
      },
    },
    emits: ['remove-offer', 'booking-response', 'close-overlay'],
    setup(props, { emit }) {
      const soffers = toRef(props, 'offers'); // The selected offers in multiple booking
      const completion = ref([]); // The completion items for the complete input
      const user_data = ref([]); // Array of all users: { pk: number, username: string, first_name: string, last_name: string }[]
      const user_inputs = ref(Array(soffers.value.length).fill(''));
      const comment = ref(''); // The comment for the booking
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();
      const booking_requested = ref(false); // flag to prevent button spamming

      /**
       * Load the user data for the complete input
       */
      const fetch_users = async () => {
        try {
          const res = await axios.get(api_routes.user_list);

          user_data.value = await res.data;
          completion.value = user_data.value.map((user) => `${user.first_name} ${user.last_name}`);
        } catch (error) {
          show_toast('error', 'Fehler beim Laden der Benutzerdaten');
          Logger.log(error);
        }
      };
      fetch_users();

      /**
       * Load the primary key of the logged in user
       * @returns {number} The primary key of the logged in user
       */
      const get_user_pk = async () => {
        try {
          const res = await axios.get(api_routes.user_data);

          const data = await res.data;
          return data.pk;
        } catch (error) {
          show_toast('error', 'Fehler beim Laden der Benutzerdaten');
          Logger.log(error);
          return null;
        }
      };

      /**
       * Request the booking for the selected offers
       * Emits the 'booking-response' event with the response data to let the parent component handle the response and close the overlay
       */
      const submit_reservations = async () => {
        let data = [];
        try {
          if (soffers.value.length === 0) {
            show_toast('error', 'Es sind keine Angebote ausgewählt');
            Logger.log('Es sind keine Angebote ausgewählt');
            return;
          }

          // prevent button spamming
          if (booking_requested.value) {
            return;
          }

          booking_requested.value = true;

          const current_user_pk = await get_user_pk();
          if (current_user_pk === null) {
            throw new Error('Sie konnten nicht als Benutzer gefunden werden');
          }

          // format offers to be sent to the server
          const post_data = soffers.value.map((item, i) => ({
            seat_id: item.seat_id,
            reserved_for:
              user_inputs.value[i] === ''
                ? current_user_pk
                : user_data.value.find((user) => `${user.first_name} ${user.last_name}` === user_inputs.value[i])?.pk,
            start: item.start,
            end: item.end,
            comment: comment.value,
          }));

          // send the booking requests to the server
          const reses = await axios.all(post_data.map((item) => axios.post(api_routes.place_reservation, item)));

          data = (await reses).map((res) => res.data);
          Logger.log(data);
          emit('booking-response', {
            status: 'success',
            message: 'Plätze wurden reserviert',
          });
        } catch (error) {
          Logger.log(error);
          emit('booking-response', {
            status: 'error',
            message: error.message,
          });
        }
      };

      /**
       * Update props when the selected offers change
       */
      watch(soffers, () => {
        user_inputs.value = Array(soffers.value.length).fill('');
        comment.value = '';
      });

      return {
        completion,
        user_data,
        user_inputs,
        comment,
        date_to_str,
        submit_reservations,
        is_toast_shown,
        toast_type,
        toast_msg,
      };
    },
  };
</script>

<style scoped>
  .title {
    text-align: center;
    font-weight: 400;
    font-size: 1.1em;
    padding: 0.25em;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 2em;
  }

  .content .actions-group {
    background-color: #e4e4e4;
    width: 100%;
    padding: 0.5em 0 1em 0;
    border-radius: 0.3em 0.3em 0 0;
  }

  .content .actions-group .comment-group {
    display: flex;
    flex-direction: column;
    max-width: 20em;
    width: 80%;
    margin: 0 auto 1em auto;
  }

  .content .actions-group .comment-group label {
    font-size: 0.8em;
    color: #9d9d9d;
  }

  .content .actions-group .comment-group input {
    border-radius: 0.3em;
    border: 1px solid #9d9d9d;
  }

  .content .actions-group .btn-group {
    display: flex;
    gap: 1em;
    font-size: 1.2em;
    justify-content: center;
  }

  .offer-card {
    background-color: #fff;
    padding: 0.6em 0.6em 1.2em 0.6em;
    border-radius: 0.4em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);

    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 2.2em;
    font-size: 0.9em;
    height: 100%;
    max-height: 14.706em;
    width: clamp(23em, 100%, 25em);
  }

  .offer-card .header-group {
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.2fr;
    justify-content: space-between;
    width: 100%;
  }

  .offer-card .seat-info {
    display: flex;
    flex-direction: column;
    place-items: center;
  }

  .offer-card .seat-info h3 {
    font-weight: 500;
  }

  .offer-card .seat-info p {
    font-size: 0.8em;
    color: #9d9d9d;
  }

  .offer-card .offertimes {
    color: #686868;
    font-size: 0.9em;
  }

  .offer-card .header-group .icon {
    color: #ffa57d;
    cursor: pointer;
  }

  .offer-card .header-group .icon:hover {
    color: #ff7940;
  }

  .offer-card .user-complete-input {
    font-size: 1.15em;
  }

  .offer-cards-list {
    margin: 1em auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  @media (min-width: 650px) {
  }

  @media (min-width: 935px) {
    .content .actions-group {
      align-self: end;
      margin-bottom: 2em;
    }
  }
</style>
