<template>
  <Icon v-if="is_favorite" icon="bx:bxs-star" class="favorite-icon-component fav" @click="$emit('toggle')" />
  <Icon v-else icon="akar-icons:star" class="favorite-icon-component no-fav" @click="$emit('toggle')" />
</template>

<script>
  import { Icon } from '@iconify/vue';

  /**
   * Favorite icon.
   * This is a toggle state icon with eiter a star or a star outline.
   * Emits a toggle event when clicked.
   */
  export default {
      name: 'FavoriteIcon',
    components: { Icon },
    props: {
      is_favorite: {
        // flag to indicate if the icon is a favorite or not
        type: Boolean,
        required: true,
      },
    },
    emits: ['toggle'],
  };
</script>

<style scoped>
  .favorite-icon-component {
    font-size: 1em;
    cursor: pointer;
  }

  .fav {
    color: #ff7f48;
  }

  .fav:hover {
    color: #ff5a14;
  }

  .no-fav {
    color: #9d9d9d;
  }

  .no-fav:hover {
    color: #5d5d5d;
  }
</style>
