<template>
  <router-view />
</template>

<script>
  import { onMounted } from '@vue/runtime-core';
  import { useStore } from 'vuex';
  export default {
    name: 'App',
    setup() {
      onMounted(async () => {
        const store = useStore();

        store.dispatch('fetch_notifications');
        // periodically fetch notifications
        setInterval(() => {
          if (store.getters.is_logged_in) {
            store.dispatch('fetch_notifications');
          }
        }, 20000);

        // can see statistics
        await store.dispatch('fetch_can_see_statistics');
      });
    },
  };
</script>

<style>
  /* Load Poppins font family */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  * {
    font-family: Poppins, sans-serif, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .splitter-image {
    background: url('~@/assets/bg-inp-building.png');
    background-size: cover;
    background-position: 20%;
    background-repeat: no-repeat;
    height: 10rem;

    clip-path: polygon(0 0, 100% 0%, 100% 97%, 0% 100%);
  }

  .spinner-wrapper {
    font-size: 1.5rem;
    padding-top: 2rem;
  }

  .content-container {
    width: 95%;
    margin: 0 auto;
    font-size: 1.1em;
    background-color: #ebebeb;
    padding: 1em;
    border-radius: 0.3em;
    min-height: 80vh;
  }
</style>
