import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';

import { DEBUG, TOKEN_KEY } from './config';

import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css'; // styles need to be imported manually. This could also be done with CSS Modules.

import VueClickAway from 'vue3-click-away';

// axios default config
axios.defaults.baseURL = DEBUG ? 'http://localhost:8000/' : '';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

// FIXME: verify token before logging user in
const token = localStorage.getItem(TOKEN_KEY) || '';
axios.defaults.headers.common['Authorization'] = token !== '' ? `Token ${token}` : '';

// dayjs plugins
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

// create vue application
createApp(App).use(store).use(router, axios).use(VueClickAway).use(VCalendar, {}).mount('#app');
