<template>
  <div class="mail-reset-card">
    <Toast v-if="is_toast_shown" :type="toast_type" :message="toast_msg" />
    <h2 class="mail-reset-heading">Passwort vergessen</h2>

    <router-link :to="{ name: 'Login' }" class="back-button">
      <IconButton icon_id="bx:bx-arrow-back" />
    </router-link>

    <p class="description">
      Geben Sie ihre E-Mail Adresse ein, um Ihr Passwort zurückzusetzen. Sie bekommen dann in kürze eine Mail geschickt
      in welcher Sie den Reset Link finden können.
    </p>

    <IconInputBox name="E-Mail" icon_id="feather:mail" v-model="email" />
    <Button width="21em" @click="send_reset_mail">E-Mail versenden</Button>
  </div>
</template>

<script>
  import { ref } from '@vue/reactivity';
  import { use_toast } from '@/composables/composables';
  import { Logger } from '@/util';
  import { api_routes } from '@/config';

  import axios from 'axios';

  import IconInputBox from '@/components/general/IconInputBox.vue';
  import Button from '@/components/general/Button.vue';
  import IconButton from '@/components/general/IconButton.vue';
  import Toast from '@/components/general/Toast.vue';

  /**
   * Component for requesting an email for the reset of the password.
   */
  export default {
    name: 'MailResetCard',
    components: {
      IconInputBox,
      Button,
      IconButton,
      Toast,
    },
    setup() {
      const email = ref(''); // v-model value for email input
      const { is_toast_shown, toast_type, toast_msg, show_toast } = use_toast();

      /**
       * Request the backend to send an email with a reset link.
       */
      const send_reset_mail = async () => {
        try {
          const res = await axios.post(api_routes.password_reset, { email: email.value });

          const data = await res.data;

          if (data?.status !== 'OK') throw new Error(data);

          email.value = '';
          show_toast('success', 'E-Mail wurde versendet');
        } catch (error) {
          Logger.log(error);
          show_toast('error', 'Fehler beim Versenden der E-Mail. Haben Sie die E-Mail Adresse richtig eingegeben?');
        } finally {
          email.value = '';
        }
      };

      return { email, is_toast_shown, toast_type, toast_msg, send_reset_mail };
    },
  };
</script>

<style scoped>
  .mail-reset-card {
    position: relative;
    font-size: 1.1rem;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.8rem;
    padding: 1rem;
    width: 95%;

    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 2.5rem;
    max-width: 600px;
  }

  .mail-reset-card .mail-reset-heading {
    font-size: 1.4rem;
    font-weight: 400;
    color: #ea4c08;
  }

  .description {
    color: #5b5b5b;
    font-size: 0.65em;
    width: 21em;
  }

  .back-button {
    position: absolute;
    left: 3%;
  }

  @media (min-width: 1400px) {
    .mail-reset-card {
      font-size: 1.3rem;
      gap: 3rem;
    }
  }
</style>
