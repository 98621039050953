<template>
  <div class="login-header">
    <img src="@/assets/inp-logo.svg" alt="INP Logo" />
    <h1>Platzbuchungssystem</h1>
  </div>
</template>

<script>
  /**
   * Top bar on the login page. Similar to a navbar, but it is just for the visuals.
   */
  export default {
    name: 'LoginHeader',
  };
</script>

<style scoped>
  .login-header {
    --border-radius: 0.8rem;

    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
    background-color: #fff;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    padding: 0.8rem 1.5rem 0.5rem 1.5rem;

    display: flex;
    gap: 2rem;
  }

  .login-header h1 {
    font-weight: 500;
    font-size: 1rem;
    color: #ff7f48;
  }

  @media (min-width: 600px) {
    .login-header {
      gap: 0;
    }

    .login-header h1 {
      margin: 0 auto;
    }
  }
</style>
