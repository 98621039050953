<template>
  <div class="notifications">
    <Navbar />
    <div class="splitter-image" />
    <div class="content">
      <header class="header">
        <div />
        <h2>Mitteilungen</h2>
        <p>{{ num_unread }}</p>
      </header>

      <!-- List of notifications -->
      <div class="card-list">
        <NotificationCard
          v-for="(notification, index) in notifications"
          :key="index"
          :notification="notification"
          @remove-notification="on_remove_notification"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Navbar from '@/components/general/Navbar.vue';
  import NotificationCard from '@/components/notifications/NotificationCard.vue';
  import { use_notifications } from '@/composables/composables';

  /**
   * Notifications view
   * It contains information and the list of notifications
   */
  export default {
    name: 'Notifications',
    components: {
      Navbar,
      NotificationCard,
    },
    setup() {
      const { notifications, num_unread, on_show_content, on_remove_notification } = use_notifications();
      on_show_content();

      return {
        notifications,
        num_unread,
        on_show_content,
        on_remove_notification,
      };
    },
  };
</script>

<style scoped>
  .notifications .content {
    background-color: #ebebeb;
    width: 90%;
    margin: 3em auto 0 auto;
    min-height: 80vh;
    border-radius: 0.3em;
    padding: 0.5em;
  }

  .notifications .content .header {
    width: 60%;
    margin: 1em auto;

    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    justify-items: center;
    align-items: center;
    gap: 1em;
    border-bottom: 1px solid #cecece;
  }

  .notifications .content .header h2 {
    color: #ff8a58;
    font-weight: 400;
    font-size: 1em;
  }

  .notifications .content .header p {
    font-size: 0.9em;
    justify-self: end;
    padding-right: 1em;
    color: #939393;
  }

  .notifications .content .card-list {
    width: 90%;
    margin: 2.5em auto;

    display: flex;
    flex-direction: column;
    gap: 0.45em;
    max-width: 55em;
  }
</style>
