<template>
  <div class="dropdown">
    <!-- Header of the dropdown menu -->
    <div
      class="header"
      @click="
        () => {
          show_content = !show_content;
          header_click = true;
          if (show_content) {
            $emit('show-content');
          }
        }
      "
    >
      <!-- Header slot -->
      <slot name="header"></slot>
    </div>

    <!-- Content slot -->
    <div class="content" :style="{ display: show_content ? 'inline-block' : 'none' }" v-click-away="on_click_away">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';

  /**
   * Base Dropdown component to be used by other child components.
   * Slots:
   *   - header: header of the dropdown menu
   *   - content: content of the dropdown menu
   * Emits:
   *   - show-content: when the content is shown
   */
  export default {
    name: 'Dropdown',
    emits: ['show-content'],
    setup() {
      const show_content = ref(false); // flag to show or hide the content of the dropdown
      const header_click = ref(false); // flag to indicate if the header was clicked or not

      /**
       * Event handler to hide the content of the dropdown on click away.
       */
      const on_click_away = () => {
        if (!header_click.value) {
          show_content.value = false;
        }
        header_click.value = false;
      };

      return { show_content, header_click, on_click_away };
    },
  };
</script>

<style scoped>
  .dropdown .header {
    cursor: pointer;
    position: relative;
  }

  .dropdown .content {
    display: none;
    position: absolute;
    z-index: 1000;
  }
</style>
