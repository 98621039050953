<template>
  <div class="spinner-wrapper" :style="{ fontSize: size }" v-if="is_loading">
    <div class="spinner"></div>
  </div>
</template>

<script>
  /**
   * Spinner component for showing loading state.
   */
  export default {
    name: 'Spinner',
    props: {
      size: {
        type: String,
        default: '1em',
      },
      is_loading: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>

<style scoped>
  .spinner {
    width: 2em;
    height: 2em;
    margin: 0 auto;
    border-radius: 50%;
    border: 0.15em solid transparent;
    border-top-color: #494949;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>
